// src/components/LoginHeader.tsx

import React from 'react';

const LoginHeader = () => {
  return (
    <header className="w-full bg-white border-b shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
        <div className="flex items-center">
          {/* <img
            src="/logo.png"
            alt="KC Online Logo"
            className="h-12 w-auto"
          /> */}
          <span className="text-3xl font-bold text-gray-800 ml-4">
            LINGOCRAFT
          </span>
        </div>
      </div>
    </header>
  );
};

export default LoginHeader;