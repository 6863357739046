import React from 'react';
import { Check } from 'lucide-react';

interface Step {
  title: string;
  description?: string;
}

interface StepsProps {
  steps: Step[];
  currentStep: number;
  completedSteps: Record<string, string>;
  onStepClick?: (index: number) => void;
}

const Steps: React.FC<StepsProps> = ({ 
  steps, 
  currentStep,
  completedSteps,
  onStepClick 
}) => {
  return (
    <div className="mb-8">
      <div className="relative">
        <div className="absolute left-0 top-5 w-full h-0.5 bg-gray-200">
          <div 
            className="absolute left-0 top-0 h-full bg-blue-500 transition-all duration-500"
            style={{ width: `${(currentStep / (steps.length - 1)) * 100}%` }}
          />
        </div>
        
        <div className="relative flex justify-between">
          {steps.map((step, index) => {
            const isCompleted = completedSteps[step.title];
            const isCurrent = index === currentStep;
            const value = completedSteps[step.title];

            return (
              <div 
                key={index} 
                className={`
                  flex flex-col items-center 
                  ${index <= currentStep ? 'cursor-pointer' : 'cursor-default'}
                `}
                onClick={() => {
                  if (index <= currentStep && onStepClick) {
                    onStepClick(index);
                  }
                }}
              >
                <div className={`
                  relative flex items-center justify-center w-10 h-10 rounded-full 
                  ${isCompleted || isCurrent ? 'bg-blue-500' : 'bg-gray-200'}
                  transition-colors duration-300
                `}>
                  {isCompleted && <Check className="w-6 h-6 text-white" />}
                  {isCurrent && <div className="w-3 h-3 bg-white rounded-full" />}
                  {!isCompleted && !isCurrent && <div className="w-3 h-3 bg-gray-400 rounded-full" />}
                </div>
                <div className="mt-2 text-center">
                  <div className={`text-sm font-medium ${isCurrent ? 'text-blue-500' : ''}`}>
                    {step.title}
                  </div>
                  {value && (
                    <div className="text-xs text-gray-500 mt-1">
                      {value}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Steps;