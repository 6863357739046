import React from 'react';
import { Button } from '../ui/button';
import { Card, CardContent } from '../ui/card';
import SelectTestType from './SelectTestType';
import SelectSourceType from './SelectSourceType';
import SelectExamType from '../test-creation/SelectExamType';
import SelectYear from '../test-creation/SelectYear';


interface SelectRoundProps {
  availableRounds: number[];
  onSelect: (round: number) => void;
  onBack: () => void;
}

const SelectRound: React.FC<SelectRoundProps> = ({ availableRounds, onSelect, onBack }) => {
  return (
    <div className="space-y-6">
      <div className="grid grid-cols-5 gap-4">
        {availableRounds.map((round) => (
          <Button
            key={round}
            variant="outline"
            className="h-12"
            onClick={() => onSelect(round)}
          >
            第 {round} 卷
          </Button>
        ))}
      </div>
      <div className="flex justify-start">
        <Button variant="outline" onClick={onBack}>
          返回
        </Button>
      </div>
    </div>
  );
};

export { SelectTestType, SelectSourceType, SelectExamType, SelectYear, SelectRound };
export default SelectRound;