import React from 'react';
import { Button } from '../ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';

interface SelectYearProps {
  availableYears: number[];
  onSelect: (year: number) => void;
  onBack: () => void;
}

const SelectYear: React.FC<SelectYearProps> = ({ availableYears, onSelect, onBack }) => {
  return (
    <div className="space-y-6">
      <div className="grid grid-cols-5 gap-4">
        {availableYears.map((year) => (
          <Button
            key={year}
            variant="outline"
            className="h-12"
            onClick={() => onSelect(year)}
          >
            {year}
          </Button>
        ))}
      </div>
      <div className="flex justify-start">
        <Button variant="outline" onClick={onBack}>
          返回
        </Button>
      </div>
    </div>
  );
};

export default SelectYear;