import React from 'react';
import { Card, CardContent } from '../ui/card';
import { Button } from '../ui/button';

interface SelectExamTypeProps {
  onSelect: (type: string) => void;
  onBack: () => void;
}

const SelectExamType: React.FC<SelectExamTypeProps> = ({ onSelect, onBack }) => {
  const examTypes = [
    {
      id: 'gsat',
      title: '學測',
      description: '大學入學學科能力測驗'
    },
    {
      id: 'ast',
      title: '指考',
      description: '大學入學指定科目考試'
    },
    {
      id: 'mock',
      title: '模考',
      description: '各區模擬考試'
    }
  ];

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-3 gap-4">
        {examTypes.map((type) => (
          <Card
            key={type.id}
            className="cursor-pointer hover:shadow-lg transition-all"
            onClick={() => onSelect(type.id)}
          >
            <CardContent className="pt-6">
              <h3 className="text-lg font-semibold mb-2">{type.title}</h3>
              <p className="text-sm text-gray-600">{type.description}</p>
            </CardContent>
          </Card>
        ))}
      </div>
      <div className="flex justify-start">
        <Button variant="outline" onClick={onBack}>
          返回
        </Button>
      </div>
    </div>
  );
};

export default SelectExamType;