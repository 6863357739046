import React from 'react';
import { VariantProps, cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';
import { clsx } from 'clsx';


const checkboxVariants = cva(
  'h-4 w-4 rounded border border-gray-300 text-blue-600 focus:ring-blue-500',
  {
    variants: {
      variant: {
        default: 'bg-white',
        primary: 'bg-blue-100',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
);

interface CheckboxProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof checkboxVariants> {
  label?: string;
}

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ className, variant, label, ...props }, ref) => {
    return (
      <label className="inline-flex items-center">
        <input
          type="checkbox"
          ref={ref}
          className={twMerge(clsx(checkboxVariants({ variant }), className))}
          {...props}
        />
        {label && <span className="ml-2 text-sm">{label}</span>}
      </label>
    );
  }
);

Checkbox.displayName = 'Checkbox';

export { Checkbox, checkboxVariants };