import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent } from '../components/ui/card';
import { Input } from '../components/ui/input';
import { Button } from '../components/ui/button';
import { Textarea } from '../components/ui/textarea';
import { Label } from '../components/ui/label';
import { Camera, Loader2, ChevronLeft, ChevronRight } from 'lucide-react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../components/ui/select';
import { Alert, AlertDescription } from '../components/ui/alert';
import Header from '../components/Header';
import { useToast } from '../components/ui/use-toast';

interface SelfPracticePageProps {
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  username: string;
}

interface FormData {
  title: string;
  description: string;
  content: string;
  lang: string;
  titleImages: Array<{
    file: File;
    preview: string;
    ocrText: string;
  }>;
  descriptionImages: Array<{
    file: File;
    preview: string;
    ocrText: string;
  }>;
  contentImages: Array<{
    file: File;
    preview: string;
    ocrText: string;
  }>;
  [key: string]: any;
}

const SelfPracticePage: React.FC<SelfPracticePageProps> = ({
  setIsAuthenticated,
  username,
}) => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [formData, setFormData] = useState<FormData>({
    title: '',
    description: '',
    content: '',
    lang: '',
    titleImages: [],
    descriptionImages: [],
    contentImages: []
  });

  const handleImageUpload = async (
    e: React.ChangeEvent<HTMLInputElement>,
    field: 'title' | 'description' | 'content'
  ) => {
    if (!e.target.files?.length) return;

    const file = e.target.files[0];
    const preview = URL.createObjectURL(file);

    try {
      setIsLoading(true);

      const base64 = await new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64 = reader.result as string;
          resolve(base64.split(',')[1]);
        };
        reader.onerror = error => reject(error);
      });

      const response = await fetch('/api/ocr', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          image: base64
        })
      });

      if (!response.ok) {
        throw new Error('OCR 處理失敗');
      }

      const data = await response.json();

      setFormData(prev => ({
        ...prev,
        [field]: prev[field] + (prev[field] ? '\n' : '') + data.text,
        [`${field}Images`]: [...prev[`${field}Images`], {
          file,
          preview,
          ocrText: data.text
        }]
      }));

      toast({
        title: "成功",
        description: "圖片上傳並識別成功",
      });
    } catch (error) {
      toast({
        variant: "destructive",
        title: "錯誤",
        description: error instanceof Error ? error.message : 'OCR 處理失敗'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handlePrevImage = () => {
    setCurrentImageIndex(prev => Math.max(0, prev - 1));
  };

  const handleNextImage = () => {
    setCurrentImageIndex(prev => Math.min(formData.titleImages.length - 1, prev + 1));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!formData.title.trim() || !formData.description.trim() || 
        !formData.content.trim() || !formData.lang) {
      setError('請填寫所有必填欄位');
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      const response = await fetch('/api/student/practice/create-self', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          ...formData,
          type: 'self-practice'
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || '提交失敗');
      }

      const result = await response.json();
      if (result.success) {
        toast({
          title: "成功",
          description: "練習已創建",
        });
        navigate('/student-dashboard');
      }
    } catch (err) {
      toast({
        variant: "destructive",
        title: "錯誤",
        description: err instanceof Error ? err.message : '提交失敗'
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <Header setIsAuthenticated={setIsAuthenticated} username={username} />
      <div className="flex-1 p-6">
        <div className="max-w-4xl mx-auto">
          <Card>
            <CardContent className="pt-6">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-2xl font-bold">自行練習</h2>
                <Button variant="outline" onClick={() => navigate('/student-dashboard')}>
                  <ChevronLeft className="mr-2 h-4 w-4" />
                  返回
                </Button>
              </div>

              <form onSubmit={handleSubmit} className="space-y-6">
                {error && (
                  <Alert variant="destructive">
                    <AlertDescription>{error}</AlertDescription>
                  </Alert>
                )}

                <div className="space-y-2">
                  <div className="flex justify-between items-center">
                    <Label htmlFor="title">作文標題 *</Label>
                    <div className="relative">
                      <Button
                        type="button"
                        variant="outline"
                        size="icon"
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <Loader2 className="h-4 w-4 animate-spin" />
                        ) : (
                          <Camera className="h-4 w-4" />
                        )}
                      </Button>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleImageUpload(e, 'title')}
                        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                      />
                    </div>
                  </div>
                  <Input
                    id="title"
                    value={formData.title}
                    onChange={(e) =>
                      setFormData((prev) => ({ ...prev, title: e.target.value }))
                    }
                    placeholder="請輸入作文標題"
                  />
                </div>

                <div className="space-y-2">
                  <div className="flex justify-between items-center">
                    <Label htmlFor="description">題目描述 *</Label>
                    <div className="relative">
                      <Button
                        type="button"
                        variant="outline"
                        size="icon"
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <Loader2 className="h-4 w-4 animate-spin" />
                        ) : (
                          <Camera className="h-4 w-4" />
                        )}
                      </Button>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleImageUpload(e, 'description')}
                        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                      />
                    </div>
                  </div>
                  <div className={formData.descriptionImages.length > 0 ? "grid grid-cols-2 gap-4" : ""}>
                    <div className={formData.descriptionImages.length > 0 ? "" : "w-full"}>
                      <Textarea
                        id="description"
                        value={formData.description}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            description: e.target.value,
                          }))
                        }
                        placeholder="請輸入題目描述"
                        className={`w-full ${
                          formData.descriptionImages.length > 0 
                            ? "h-[calc(100vh-24rem)]"
                            : "min-h-[200px]"
                        }`}
                      />
                    </div>

                    {formData.descriptionImages.length > 0 && (
                      <div className="relative">
                        <div className="relative overflow-hidden rounded-lg border">
                          {formData.descriptionImages.map((image, index) => (
                            <div
                              key={index}
                              className={`transition-opacity duration-300 ${
                                currentImageIndex === index ? "opacity-100" : "opacity-0 hidden"
                              }`}
                            >
                              <Card>
                                <CardContent className="p-4">
                                  <img
                                    src={image.preview}
                                    alt={`上傳的圖片 ${index + 1}`}
                                    className="w-full h-auto"
                                  />
                                  <p className="text-sm text-gray-500 mt-2">
                                    第 {index + 1} 頁，共 {formData.descriptionImages.length} 頁
                                  </p>
                                </CardContent>
                              </Card>
                            </div>
                          ))}

                          {formData.descriptionImages.length > 1 && (
                            <>
                              <Button
                                variant="outline"
                                size="icon"
                                className="absolute left-0 top-1/2 transform -translate-y-1/2 -translate-x-4"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handlePrevImage();
                                }}
                                disabled={currentImageIndex === 0}
                              >
                                <ChevronLeft className="h-4 w-4" />
                              </Button>
                              <Button
                                variant="outline"
                                size="icon"
                                className="absolute right-0 top-1/2 transform -translate-y-1/2 translate-x-4"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleNextImage();
                                }}
                                disabled={currentImageIndex === formData.descriptionImages.length - 1}
                              >
                                <ChevronRight className="h-4 w-4" />
                              </Button>
                            </>
                          )}
                        </div>

                        <div className="flex justify-center space-x-2 mt-4">
                          {formData.descriptionImages.map((_, index) => (
                            <Button
                              key={index}
                              variant="outline"
                              size="sm"
                              onClick={(e) => {
                                e.preventDefault();
                                setCurrentImageIndex(index);
                              }}
                              className={`w-8 h-8 ${
                                currentImageIndex === index ? "bg-blue-100" : ""
                              }`}
                            >
                              {index + 1}
                            </Button>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="space-y-2">
                  <div className="flex justify-between items-center">
                    <Label htmlFor="content">作文內容 *</Label>
                    <div className="relative">
                      <Button
                        type="button"
                        variant="outline"
                        size="icon"
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <Loader2 className="h-4 w-4 animate-spin" />
                        ) : (
                          <Camera className="h-4 w-4" />
                        )}
                      </Button>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleImageUpload(e, 'content')}
                        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                      />
                    </div>
                  </div>
                  <div className={formData.contentImages.length > 0 ? "grid grid-cols-2 gap-4" : ""}>
                    <div className={formData.contentImages.length > 0 ? "" : "w-full"}>
                      <Textarea
                        id="content"
                        value={formData.content}
                        onChange={(e) =>
                          setFormData((prev) => ({ ...prev, content: e.target.value }))
                        }
                        placeholder="請輸入作文內容"
                        className={`w-full ${
                          formData.contentImages.length > 0 
                            ? "h-[calc(100vh-24rem)]"
                            : "min-h-[400px]"
                        }`}
                      />
                    </div>

                    {formData.contentImages.length > 0 && (
                      <div className="relative">
                        <div className="relative overflow-hidden rounded-lg border">
                          {formData.contentImages.map((image, index) => (
                            <div
                              key={index}
                              className={`transition-opacity duration-300 ${
                                currentImageIndex === index ? "opacity-100" : "opacity-0 hidden"
                              }`}
                            >
                              <Card>
                                <CardContent className="p-4">
                                  <img
                                    src={image.preview}
                                    alt={`上傳的圖片 ${index + 1}`}
                                    className="w-full h-auto"
                                  />
                                  <p className="text-sm text-gray-500 mt-2">
                                    第 {index + 1} 頁，共 {formData.contentImages.length} 頁
                                  </p>
                                </CardContent>
                              </Card>
                            </div>
                          ))}

                          {formData.contentImages.length > 1 && (
                            <>
                              <Button
                                variant="outline"
                                size="icon"
                                className="absolute left-0 top-1/2 transform -translate-y-1/2 -translate-x-4"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handlePrevImage();
                                }}
                                disabled={currentImageIndex === 0}
                              >
                                <ChevronLeft className="h-4 w-4" />
                              </Button>
                              <Button
                                variant="outline"
                                size="icon"
                                className="absolute right-0 top-1/2 transform -translate-y-1/2 translate-x-4"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleNextImage();
                                }}
                                disabled={currentImageIndex === formData.contentImages.length - 1}
                              >
                                <ChevronRight className="h-4 w-4" />
                              </Button>
                            </>
                          )}
                        </div>

                        <div className="flex justify-center space-x-2 mt-4">
                          {formData.contentImages.map((_, index) => (
                            <Button
                              key={index}
                              variant="outline"
                              size="sm"
                              onClick={(e) => {
                                e.preventDefault();
                                setCurrentImageIndex(index);
                              }}
                              className={`w-8 h-8 ${
                                currentImageIndex === index ? "bg-blue-100" : ""
                              }`}
                            >
                              {index + 1}
                            </Button>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="space-y-2">
                  <Label>批改說明語言 *</Label>
                  <Select
                    value={formData.lang}
                    onValueChange={(value) =>
                      setFormData((prev) => ({ ...prev, lang: value }))
                    }
                  >
                    <SelectTrigger className="w-[200px]">
                      <SelectValue placeholder="請選擇語言" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="繁體中文">繁體中文</SelectItem>
                      <SelectItem value="日本語">日本語</SelectItem>
                      <SelectItem value="한국어">한국어</SelectItem>
                      <SelectItem value="Tiếng Việt">Tiếng Việt</SelectItem>
                      <SelectItem value="简体中文">简体中文</SelectItem>
                    </SelectContent>
                  </Select>
                </div>

                <div className="flex justify-end">
                  <Button type="submit" disabled={isLoading}>
                    {isLoading ? (
                      <div className="flex items-center">
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        處理中...
                      </div>
                    ) : '提交練習'}
                  </Button>
                </div>
              </form>
            </CardContent>
          </Card>

          <Card className="mt-6">
            <CardContent className="pt-6">
              <h3 className="text-lg font-semibold mb-4">使用說明</h3>
              <div className="space-y-2 text-sm text-gray-600">
                <p>1. 所有標註 * 的欄位為必填項目</p>
                <p>2. 您可以使用相機圖示上傳圖片，系統會自動識別文字</p>
                <p>3. 批改過程可能需要 2-3 分鐘，請耐心等待</p>
                <p>4. 每篇作文會消耗一次額度，請合理使用</p>
                <p>5. 如遇到問題，請聯繫系統管理員協助</p>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default SelfPracticePage; 