// src/pages/CorrectionHistory.tsx

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '../components/ui/tabs';
import { History, BarChart2, GitCompare } from 'lucide-react';
import Header from '../components/Header';
import HistoryList from '../components/correction/HistoryList';
import ComparisonView from '../components/correction/ComparisonView';
import AnalyticsView from '../components/correction/AnalyticsView';

interface CorrectionHistoryProps {
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  username: string;
}

const CorrectionHistory: React.FC<CorrectionHistoryProps> = ({
  setIsAuthenticated,
  username
}) => {
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <Header setIsAuthenticated={setIsAuthenticated} username={username} />
      
      <div className="flex-1 p-6">
        <div className="max-w-7xl mx-auto">
          <div className="flex items-center space-x-4 mb-6">
            <History className="h-8 w-8 text-blue-600" />
            <h1 className="text-2xl font-bold">批改紀錄</h1>
          </div>

          <Tabs defaultValue="history" className="space-y-6">
            <TabsList className="bg-white p-1">
              <TabsTrigger value="history" className="flex items-center space-x-2">
                <History className="h-4 w-4" />
                <span>批改歷史</span>
              </TabsTrigger>
              <TabsTrigger value="comparison" className="flex items-center space-x-2">
                <GitCompare className="h-4 w-4" />
                <span>結果比較</span>
              </TabsTrigger>
              <TabsTrigger value="analytics" className="flex items-center space-x-2">
                <BarChart2 className="h-4 w-4" />
                <span>統計分析</span>
              </TabsTrigger>
            </TabsList>

            <TabsContent value="history">
              <Card>
                <CardHeader>
                  <CardTitle>批改歷史記錄</CardTitle>
                </CardHeader>
                <CardContent>
                  <HistoryList />
                </CardContent>
              </Card>
            </TabsContent>

            <TabsContent value="comparison">
              <Card>
                <CardHeader>
                  <CardTitle>批改結果比較</CardTitle>
                </CardHeader>
                <CardContent>
                  <ComparisonView />
                </CardContent>
              </Card>
            </TabsContent>

            <TabsContent value="analytics">
              <Card>
                <CardHeader>
                  <CardTitle>批改統計分析</CardTitle>
                </CardHeader>
                <CardContent>
                  <AnalyticsView />
                </CardContent>
              </Card>
            </TabsContent>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default CorrectionHistory;