import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '../ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../ui/table';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '../ui/tabs';

// 註冊 Chart.js 組件
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// 定義介面
interface Essay {
  _id: string;
  userId: string;
  title: string;
  createdAt: string;
  type: 'writing' | 'english-writing' | 'translation-writing';
  writing_token: number;
  writing_debug_url: string | null;
  translation_tokens: {
    token: number;
    debug_url: string | null;
    index: number;
  }[];
  score: number;
  writing_cost: number;
  translation_cost: number;
  total_cost: number;
  lang: string | null;
  total_tokens: number;
}

interface DailyStats {
  tokens: number;
  count: number;
}

interface MonthlyStats extends DailyStats {}

interface StatsData {
  date?: string;
  month?: string;
  tokens: number;
  count: number;
}

// 添加 ChartStat 介面
interface ChartStat {
  date?: string;
  month?: string;
  tokens: number;
  count: number;
  averageScore: number;
}

interface Analytics {
  writing: {
    count: number;
    totalTokens: number;
    totalCost: number;
    averageTokens: number;
    averageCost: number;
  };
  translation: {
    count: number;
    totalTokens: number;
    totalCost: number;
    averageTokens: number;
    averageCost: number;
  };
  total: {
    count: number;
    validCount: number;
    totalTokens: number;
    totalCost: number;
    averageTokens: number;
    averageCost: number;
  };
  dailyStats: Array<{
    date: string;
    tokens: number;
    count: number;
  }>;
  monthlyStats: Array<{
    month: string;
    tokens: number;
    count: number;
  }>;
}

const EssayAnalytics: React.FC = () => {
  const [essays, setEssays] = useState<Essay[]>([]);
  const [timeRange, setTimeRange] = useState<'week' | 'month' | 'year'>('week');
  const [analytics, setAnalytics] = useState<Analytics>({
    writing: {
      count: 0,
      totalTokens: 0,
      totalCost: 0,
      averageTokens: 0,
      averageCost: 0
    },
    translation: {
      count: 0,
      totalTokens: 0,
      totalCost: 0,
      averageTokens: 0,
      averageCost: 0
    },
    total: {
      count: 0,
      validCount: 0,
      totalTokens: 0,
      totalCost: 0,
      averageTokens: 0,
      averageCost: 0
    },
    dailyStats: [],
    monthlyStats: []
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchEssayData();
  }, []);

  useEffect(() => {
    if (Array.isArray(essays) && essays.length > 0) {
      calculateAnalytics();
    }
  }, [essays, timeRange]);

  const fetchEssayData = async () => {
    try {
      setLoading(true);
      const response = await fetch('/api/admin/essays/analytics', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.json();
      
      if (data.success) {
        setEssays(data.essays);
        setAnalytics(data.stats);
        setError(null);
      } else {
        setError(data.message || '獲取數據失敗');
      }
    } catch (error) {
      console.error('Error fetching essay data:', error);
      setError('獲取數據失敗');
    } finally {
      setLoading(false);
    }
  };

  const calculateAnalytics = () => {
    if (!Array.isArray(essays)) return;

    // 初始化統計數據
    const stats = {
      writing: {
        count: 0,
        totalTokens: 0,
        totalCost: 0
      },
      translation: {
        count: 0,
        totalTokens: 0,
        totalCost: 0
      },
      total: {
        count: 0,
        validCount: 0,
        totalTokens: 0,
        totalCost: 0
      }
    };

    // 添加日期統計計算
    const dailyStats: Record<string, DailyStats> = {};
    const monthlyStats: Record<string, MonthlyStats> = {};

    essays.forEach(essay => {
      const date = new Date(essay.createdAt);
      const dayKey = date.toISOString().split('T')[0];
      const monthKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
      
      // 初始化每日統計
      if (!dailyStats[dayKey]) {
        dailyStats[dayKey] = { tokens: 0, count: 0 };
      }
      dailyStats[dayKey].tokens += essay.total_tokens;
      dailyStats[dayKey].count += 1;

      // 初始化月度統計
      if (!monthlyStats[monthKey]) {
        monthlyStats[monthKey] = { tokens: 0, count: 0 };
      }
      monthlyStats[monthKey].tokens += essay.total_tokens;
      monthlyStats[monthKey].count += 1;

      // 計算作文部分
      if (essay.writing_token > 0) {
        stats.writing.count++;
        stats.writing.totalTokens += essay.writing_token;
        stats.writing.totalCost += essay.writing_cost || 0;
      }

      // 計算翻譯部分
      if (essay.translation_tokens && essay.translation_tokens.length > 0) {
        essay.translation_tokens.forEach(trans => {
          stats.translation.count++;
          stats.translation.totalTokens += trans.token || 0;
        });
        
        stats.translation.totalCost += essay.translation_cost || 0;
      }

      // 計算總計
      if (essay.total_tokens > 0) {
        stats.total.validCount++;
      }
      stats.total.count++;
      stats.total.totalTokens += essay.total_tokens || 0;
      stats.total.totalCost += essay.total_cost || 0;
    });

    // 轉換為數組
    const dailyStatsArray = Object.entries(dailyStats)
      .sort(([a], [b]) => a.localeCompare(b))
      .map(([date, stats]) => ({
        date,
        tokens: stats.tokens,
        count: stats.count
      }));

    const monthlyStatsArray = Object.entries(monthlyStats)
      .sort(([a], [b]) => a.localeCompare(b))
      .map(([month, stats]) => ({
        month,
        tokens: stats.tokens,
        count: stats.count
      }));

    // 計算平均值
    setAnalytics({
      writing: {
        count: stats.writing.count,
        totalTokens: stats.writing.totalTokens,
        totalCost: stats.writing.totalCost,
        averageTokens: stats.writing.count ? Math.round(stats.writing.totalTokens / stats.writing.count) : 0,
        averageCost: stats.writing.count ? Number((stats.writing.totalCost / stats.writing.count).toFixed(3)) : 0
      },
      translation: {
        count: stats.translation.count,
        totalTokens: stats.translation.totalTokens,
        totalCost: stats.translation.totalCost,
        averageTokens: stats.translation.count ? Math.round(stats.translation.totalTokens / stats.translation.count) : 0,
        averageCost: stats.translation.count ? Number((stats.translation.totalCost / stats.translation.count).toFixed(3)) : 0
      },
      total: {
        count: stats.total.count,
        validCount: stats.total.validCount,
        totalTokens: stats.total.totalTokens,
        totalCost: stats.total.totalCost,
        averageTokens: stats.total.validCount ? Math.round(stats.total.totalTokens / stats.total.validCount) : 0,
        averageCost: stats.total.validCount ? Number((stats.total.totalCost / stats.total.validCount).toFixed(3)) : 0
      },
      dailyStats: dailyStatsArray,
      monthlyStats: monthlyStatsArray
    });
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Token使用趨勢'
      }
    },
    scales: {
      y: {
        beginAtZero: true
      }
    }
  };

  const renderTokenInfo = (essay: Essay) => {
    return (
      <div className="space-y-1">
        <div>
          寫作: {essay.writing_token}
          {essay.writing_debug_url && (
            <a href={essay.writing_debug_url} target="_blank" rel="noopener noreferrer" className="ml-2 text-blue-500 hover:underline">
              Debug
            </a>
          )}
        </div>
        {essay.type === 'translation-writing' && essay.translation_tokens.map((trans, idx) => (
          <div key={idx}>
            翻譯 {trans.index}: {trans.token}
            {trans.debug_url && (
              <a href={trans.debug_url} target="_blank" rel="noopener noreferrer" className="ml-2 text-blue-500 hover:underline">
                Debug
              </a>
            )}
          </div>
        ))}
        <div className="font-semibold">
          總計: {essay.total_tokens}
        </div>
      </div>
    );
  };

  // 渲染圖表數據時添加檢查
  const renderChart = (type: 'token' | 'count') => {
    const stats = timeRange === 'month' ? analytics.monthlyStats : analytics.dailyStats;
    if (!Array.isArray(stats)) return null;

    return (
      <Line 
        data={{
          labels: stats.map((stat: StatsData) => timeRange === 'month' ? stat.month : stat.date),
          datasets: [{
            label: type === 'token' ? 'Token數' : '文章數',
            data: stats.map((stat: StatsData) => type === 'token' ? stat.tokens : stat.count),
            borderColor: type === 'token' ? 'rgb(75, 192, 192)' : 'rgb(255, 99, 132)',
            tension: 0.1
          }]
        }} 
        options={chartOptions}
      />
    );
  };

  if (loading) {
    return (
      <Card>
        <CardContent className="p-6">
          <div>載入中...</div>
        </CardContent>
      </Card>
    );
  }

  if (error) {
    return (
      <Card>
        <CardContent className="p-6">
          <div className="text-red-500">{error}</div>
        </CardContent>
      </Card>
    );
  }

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader>
          <CardTitle>文章批改分析</CardTitle>
        </CardHeader>
        <CardContent>
          {/* 作文統計 */}
          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-4">作文統計</h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <Card>
                <CardHeader>
                  <CardTitle className="text-sm font-medium">作文數量</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="text-2xl font-semibold">
                    {analytics.writing.count}
                  </div>
                </CardContent>
              </Card>
              <Card>
                <CardHeader>
                  <CardTitle className="text-sm font-medium">平均Token</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="text-2xl font-semibold">
                    {analytics.writing.averageTokens.toLocaleString()}
                  </div>
                </CardContent>
              </Card>
              <Card>
                <CardHeader>
                  <CardTitle className="text-sm font-medium">平均成本(NTD)</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="text-2xl font-semibold">
                    ${analytics.writing.averageCost.toFixed(3)}
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>

          {/* 翻譯統計 */}
          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-4">翻譯統計</h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <Card>
                <CardHeader>
                  <CardTitle className="text-sm font-medium">翻譯題目數</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="text-2xl font-semibold">
                    {analytics.translation.count}
                  </div>
                </CardContent>
              </Card>
              <Card>
                <CardHeader>
                  <CardTitle className="text-sm font-medium">平均Token</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="text-2xl font-semibold">
                    {analytics.translation.count > 0 
                      ? Math.round(analytics.translation.totalTokens / analytics.translation.count).toLocaleString()
                      : 0}
                  </div>
                </CardContent>
              </Card>
              <Card>
                <CardHeader>
                  <CardTitle className="text-sm font-medium">平均成本(NTD)</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="text-2xl font-semibold">
                    ${analytics.translation.count > 0
                      ? (analytics.translation.totalCost / analytics.translation.count).toFixed(3)
                      : '0.000'}
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>

          {/* 總計統計 */}
          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-4">總計統計</h3>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
              <Card>
                <CardHeader>
                  <CardTitle className="text-sm font-medium">總提交數</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="text-2xl font-semibold">
                    {analytics.total.count}
                  </div>
                </CardContent>
              </Card>
              <Card>
                <CardHeader>
                  <CardTitle className="text-sm font-medium">總Token數</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="text-2xl font-semibold">
                    {analytics.total.totalTokens.toLocaleString()}
                  </div>
                </CardContent>
              </Card>
              <Card>
                <CardHeader>
                  <CardTitle className="text-sm font-medium">總成本(NTD)</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="text-2xl font-semibold">
                    ${analytics.total.totalCost.toFixed(2)}
                  </div>
                </CardContent>
              </Card>
              <Card>
                <CardHeader>
                  <CardTitle className="text-sm font-medium">有效提交數</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="text-2xl font-semibold">
                    {analytics.total.validCount}
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>

          <div className="space-y-4">
            <div className="flex justify-end">
              <Select 
                value={timeRange} 
                onValueChange={(value: 'week' | 'month' | 'year') => setTimeRange(value)}
              >
                <SelectTrigger className="w-32">
                  <SelectValue placeholder="選擇時間範圍" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="week">週</SelectItem>
                  <SelectItem value="month">月</SelectItem>
                  <SelectItem value="year">年</SelectItem>
                </SelectContent>
              </Select>
            </div>

            <Card>
              <CardContent className="pt-6">
                <Tabs defaultValue="token">
                  <TabsList className="grid w-full grid-cols-2">
                    <TabsTrigger value="token">Token 使用趨勢</TabsTrigger>
                    <TabsTrigger value="count">文章數量趨勢</TabsTrigger>
                  </TabsList>

                  <TabsContent value="token">
                    <div className="h-96">
                      {renderChart('token')}
                    </div>
                  </TabsContent>

                  <TabsContent value="count">
                    <div className="h-96">
                      {renderChart('count')}
                    </div>
                  </TabsContent>
                </Tabs>
              </CardContent>
            </Card>
          </div>

          <div className="space-y-4">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>標題</TableHead>
                  <TableHead>用戶</TableHead>
                  <TableHead>類型</TableHead>
                  <TableHead>Token</TableHead>
                  <TableHead>成本</TableHead>
                  <TableHead>日期</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {Array.isArray(essays) && essays.map((essay) => (
                  <TableRow key={essay._id}>
                    <TableCell>{essay.title}</TableCell>
                    <TableCell>{essay.userId}</TableCell>
                    <TableCell>{essay.type}</TableCell>
                    <TableCell>{renderTokenInfo(essay)}</TableCell>
                    <TableCell>${(essay.total_cost || 0).toFixed(3)}</TableCell>
                    <TableCell>
                      {new Date(essay.createdAt).toLocaleDateString()}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default EssayAnalytics;