import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { Calendar, CheckCircle, Clock, TrendingUp, BookOpen, AlertCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import { fetchEssayHistories } from '../services/api';
import { initiateReadingSystem } from '../services/readingApi';
import { useToast } from "../components/ui/use-toast" 

interface StudentDashboardProps {
  setIsAuthenticated: Dispatch<SetStateAction<boolean>>;
  username: string;
}

interface Assignment {
  id: string;
  title: string;
  description: string;
  dueDate: string;
  status: 'pending' | 'completed';
  teacher: string;
}

interface DashboardData {
  username: string;
  assignments: Assignment[];
  stats: {
    remainingEssays: number;
    monthlyEssays: number;
    totalEssays: number;
    averageScore: number;
    remainingTranslations: number;
    remainingReadings: number;
  };
}

interface EssayRecord {
  id: string;
  title: string;
  createdAt: string;
  result: {
    writing: {
      result: {
        Suggestion_lang1: { 'Total Score': string };
      };
    } | null;
    translation: {
      results: Array<TranslationResult>;
    } | null;
  };
}


interface TranslationResult {
  result: {
    output?: {
      Expressiveness?: {
        Score: string;
      };
      Faithfulness?: {
        Score: string;
      };
    };
  };
}

interface ScoreAccumulator {
  totalScore: number;
  count: number;
}

const StudentDashboard: React.FC<StudentDashboardProps> = ({ setIsAuthenticated, username }) => {
  const [dashboardData, setDashboardData] = useState<DashboardData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { toast } = useToast();
  
  const navigate = useNavigate();

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const calculateStats = async () => {
    try {
      const essayHistories: EssayRecord[] = await fetchEssayHistories();
      console.log('Essay histories:', essayHistories);
  
      if (!essayHistories) {
        throw new Error('無法獲取作文歷史');
      }
  
      // 計算總提交數
      const totalEssays = essayHistories.length;
  
      // 計算本月提交數
      const currentMonth = new Date().getMonth();
      const monthlyEssays = essayHistories.filter((essay: EssayRecord) => {
        const essayMonth = new Date(essay.createdAt).getMonth();
        return essayMonth === currentMonth;
      }).length;
  
      // 計算平均分數
      const scoreCounts = essayHistories.reduce((acc: ScoreAccumulator, essay: EssayRecord) => {
        if (essay.result.writing) {
          acc.totalScore += parseFloat(essay.result.writing.result.Suggestion_lang1['Total Score']) / 10;
          acc.count += 1;
        }
        if (essay.result.translation?.results) {
          essay.result.translation.results.forEach((result: TranslationResult) => {
            if (result.result.output?.Expressiveness?.Score && 
                result.result.output?.Faithfulness?.Score) {
              const expressiveness = parseFloat(result.result.output.Expressiveness.Score);
              const faithfulness = parseFloat(result.result.output.Faithfulness.Score);
              acc.totalScore += (expressiveness + faithfulness) / 2;
              acc.count += 1;
            }
          });
        }
        return acc;
      }, { totalScore: 0, count: 0 });
  
      const averageScore = scoreCounts.count > 0 
        ? (scoreCounts.totalScore / scoreCounts.count).toFixed(1) 
        : '0.0';
  
      return {
        totalEssays,
        monthlyEssays,
        averageScore: parseFloat(averageScore)
      };
    } catch (error) {
      console.error('計算統計數據失敗:', error);
      throw error;
    }
  };
  
  const fetchDashboardData = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      console.log('Fetching dashboard data with token:', token ? 'Present' : 'Missing');

      // 獲取基本儀表板數據
      const response = await fetch('/api/student/dashboard', {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to fetch dashboard data');
      }

      const data = await response.json();
      console.log('Dashboard data:', data);

      // 計算統計數據
      const stats = await calculateStats();

      // 確保日期格式正確
      const formattedAssignments = data.data.assignments.map((assignment: Assignment) => ({
        ...assignment,
        dueDate: new Date(assignment.dueDate).toISOString() // 確保日期格式一致
      }));

      // 合併數據
      if (data.success) {
        setDashboardData({
          ...data.data,
          assignments: formattedAssignments, // 使用格式化後的作業數據
          stats: {
            ...data.data.stats,
            ...stats
          }
        });
      } else {
        throw new Error(data.message || 'Failed to fetch dashboard data');
      }
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      setError(error instanceof Error ? error.message : 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  const getPriorityColor = (dueDate: string) => {
    try {
      const due = new Date(dueDate);
      const now = new Date();
      const diffDays = Math.ceil((due.getTime() - now.getTime()) / (1000 * 60 * 60 * 24));
      
      if (diffDays < 0) return 'text-red-500';
      if (diffDays <= 3) return 'text-yellow-500';
      return 'text-gray-500';
    } catch (e) {
      console.error('Invalid date in getPriorityColor:', dueDate);
      return 'text-gray-500';
    }
  };

  const handleReadingClick = async () => {
    try {
      const redirectUrl = await initiateReadingSystem();
      window.open(redirectUrl, '_blank');
    } catch (error) {
      console.error('跳轉到閱讀系統失敗:', error);
      toast({
        variant: "destructive",
        title: "錯誤",
        description: "跳轉到閱讀系統失敗，請稍後再試",
      });
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <p className="text-red-500 text-lg mb-4">{error}</p>
          <Button 
            onClick={() => {
              localStorage.removeItem('token');
              setIsAuthenticated(false);
              navigate('/');
            }}
          >
            返回登入
          </Button>
        </div>
      </div>
    );
  }

  if (!dashboardData) {
    return null;
  }

  const { assignments, stats } = dashboardData;
  const dueTodayTasks = assignments.filter(task => {
    try {
      const dueDate = new Date(task.dueDate);
      const today = new Date();
      return (
        dueDate.getFullYear() === today.getFullYear() &&
        dueDate.getMonth() === today.getMonth() &&
        dueDate.getDate() === today.getDate() &&
        task.status === 'pending'
      );
    } catch (e) {
      console.error('Invalid date:', task.dueDate);
      return false;
    }
  });

  return (
    <div className="flex flex-col min-h-screen">
      <Header setIsAuthenticated={setIsAuthenticated} username={username} />
      
      <main className="flex-grow bg-gray-50">
        <div className="max-w-7xl mx-auto p-6">
          <div className="flex justify-between items-center mb-8">
            <div>
              <h1 className="text-3xl font-bold text-gray-900">
                歡迎回來, {dashboardData?.username}!
              </h1>
              <p className="mt-2 text-gray-600">這是本週的作業清單</p>
            </div>
            <div className="flex gap-4">
              <Card className="w-[200px]">
                <CardContent className="pt-6">
                  <div className="flex items-center space-x-2">
                    <BookOpen className="h-5 w-5 text-emerald-500" />
                    <div>
                      <p className="text-sm text-gray-500">英文閱讀</p>
                      <Button 
                        variant="ghost" 
                        className="p-0 h-auto hover:bg-transparent"
                        onClick={handleReadingClick}
                      >
                        <p className="text-2xl font-bold text-emerald-600">開始閱讀</p>
                      </Button>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Card className="w-[200px]">
                <CardContent className="pt-6">
                  <div className="flex items-center space-x-2">
                    <Clock className="h-5 w-5 text-blue-500" />
                    <div>
                      <p className="text-sm text-gray-500">待完成</p>
                      <p className="text-2xl font-bold">
                        {assignments.filter(a => a.status === 'pending').length}
                      </p>
                    </div>
                  </div>
                </CardContent>
              </Card>
              
              <Card className="w-[200px]">
                <CardContent className="pt-6">
                  <div className="flex items-center space-x-2">
                    <CheckCircle className="h-5 w-5 text-green-500" />
                    <div>
                      <p className="text-sm text-gray-500">已完成</p>
                      <p className="text-2xl font-bold">
                        {assignments.filter(a => a.status === 'completed').length}
                      </p>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Card className="w-[200px]">
                <CardContent className="pt-6">
                  <div className="flex items-center space-x-2">
                    <AlertCircle className="h-5 w-5 text-red-500" />
                    <div>
                      <p className="text-sm text-gray-500">今日截止</p>
                      <p className="text-2xl font-bold">{dueTodayTasks.length}</p>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>

          <div className="grid grid-cols-3 gap-4 mb-8">
            <Card>
              <CardContent className="pt-6">
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <BookOpen className="h-5 w-5 text-purple-500" />
                    <div>
                      <p className="text-sm text-gray-500">剩餘作文額度</p>
                      <p className="text-2xl font-bold">{stats.remainingEssays}</p>
                    </div>
                  </div>
                  <TrendingUp className="h-5 w-5 text-purple-200" />
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardContent className="pt-6">
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <BookOpen className="h-5 w-5 text-blue-500" />
                    <div>
                      <p className="text-sm text-gray-500">剩餘翻譯額度</p>
                      <p className="text-2xl font-bold">{stats.remainingTranslations}</p>
                    </div>
                  </div>
                  <TrendingUp className="h-5 w-5 text-blue-200" />
                </div>
              </CardContent>
            </Card>
            
            <Card>
              <CardContent className="pt-6">
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <BookOpen className="h-5 w-5 text-orange-500" />
                    <div>
                      <p className="text-sm text-gray-500">剩餘閱讀額度</p>
                      <p className="text-2xl font-bold">{stats.remainingReadings}</p>
                    </div>
                  </div>
                  <TrendingUp className="h-5 w-5 text-orange-200" />
                </div>
              </CardContent>
            </Card>
            </div>
            <div className="grid grid-cols-3 gap-4 mb-8">
            <Card>
              <CardContent className="pt-6">
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <Calendar className="h-5 w-5 text-yellow-500" />
                    <div>
                      <p className="text-sm text-gray-500">本月提交</p>
                      <p className="text-2xl font-bold">{stats.monthlyEssays}</p>
                    </div>
                  </div>
                  <TrendingUp className="h-5 w-5 text-yellow-200" />
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardContent className="pt-6">
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <TrendingUp className="h-5 w-5 text-indigo-500" />
                    <div>
                      <p className="text-sm text-gray-500">總提交數</p>
                      <p className="text-2xl font-bold">{stats.totalEssays}</p>
                    </div>
                  </div>
                  <TrendingUp className="h-5 w-5 text-indigo-200" />
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardContent className="pt-6">
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <TrendingUp className="h-5 w-5 text-emerald-500" />
                    <div>
                      <p className="text-sm text-gray-500">平均分數</p>
                      <p className="text-2xl font-bold">{stats.averageScore}</p>
                    </div>
                  </div>
                  <TrendingUp className="h-5 w-5 text-emerald-200" />
                </div>
              </CardContent>
            </Card>
          </div>

          <div className="grid grid-cols-3 gap-6">
            {assignments.length === 0 ? (
              <Card className="text-center py-12">
                <CardContent>
                  <div className="flex flex-col items-center space-y-4">
                    <h3 className="text-lg font-semibold">目前沒有待完成的作業</h3>
                    <p className="text-gray-600">您可以前往英文閱讀/新聞英文練習</p>
                  </div>
                </CardContent>
              </Card>
            ) : (
              assignments.map((assignment) => (
                <Card key={assignment.id} className="hover:shadow-lg transition-shadow duration-200">
                  <CardHeader>
                    <div className="flex justify-between items-start">
                      <div>
                        <CardTitle className="text-lg font-semibold">{assignment.title}</CardTitle>
                        <p className="text-sm text-gray-500">指導老師: {assignment.teacher}</p>
                      </div>
                      <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                        assignment.status === 'completed' 
                          ? 'bg-green-100 text-green-800' 
                          : 'bg-yellow-100 text-yellow-800'
                      }`}>
                        {assignment.status === 'completed' ? '已完成' : '待完成'}
                      </span>
                    </div>
                  </CardHeader>
                  <CardContent>
                    <div className="space-y-4">
                      <p className="text-sm text-gray-600">{assignment.description}</p>
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                          <Calendar className="h-4 w-4 text-gray-400" />
                          <span className={`text-sm ${getPriorityColor(assignment.dueDate)}`}>
                            截止日期: {new Date(assignment.dueDate).toLocaleDateString('zh-TW')}
                          </span>
                        </div>
                        <Button 
                          onClick={() => navigate(`/assignment/${assignment.id}`)}
                          disabled={assignment.status === 'completed'}
                          variant={assignment.status === 'completed' ? 'outline' : 'default'}
                        >
                          {assignment.status === 'completed' ? '查看作業' : '開始作答'}
                        </Button>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              ))
            )}
          </div>
        </div>
        </main>
      </div>
    );
};

export default StudentDashboard;