import React from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../ui/tabs';
import { Card, CardContent } from '../../ui/card';
import VocabularyTab from './VocabularyTab';
import { translationResults, getDefaultCorrectionData, CorrectionData } from '../../types/correction';
import OCRComparisonTab from './OCRComparisonTab';

interface TranslationResultTabsProps {
  data: translationResults;
}

const TranslationResultTabs: React.FC<TranslationResultTabsProps> = ({ data }) => {
  console.log('Translation data received:', data);

  if (!Array.isArray(data)) {
    console.error('Expected array in translation data, got:', data);
    return (
      <div className="p-4 text-center text-gray-500">
        無法顯示翻譯結果：數據格式錯誤
      </div>
    );
  }

  // 合併所有翻譯的詞彙數據
  const vocabularyData: CorrectionData = {
    ...getDefaultCorrectionData(),
    // 使用 flatMap 來合併所有翻譯的 basic_voc
    Basic_voc: data.flatMap(item => item.result?.basic_voc || [])
      // 去除重複的單詞
      .filter((vocab, index, self) => 
        index === self.findIndex((v) => v.word === vocab.word)
      ),
    article_cerf_lang1: data[0]?.result?.lang1 || {
      CEFRLevel: "",
      Conclusion: "",
      ContentTheme: "",
      IntentandExpression: "",
      SyntaxStructure: "",
      VocabularyRange: ""
    },
    Vocabulary_Upgrade_lang1: {
      IncorrectVocabulary: [],
      ImproveVocabulary: [],
      BetterVocabulary: [],
      AdvancedPhrases: []
    }
  };

  const getIntScore = (score: string | undefined): number => {
    return score ? parseInt(score, 10) : 0;
  };

  return (
    <div className="space-y-6">
      <Card className="bg-white">
        <CardContent className="p-6">
          <h3 className="text-lg font-semibold mb-4">學測翻譯評分標準</h3>
          <div className="bg-yellow-50 p-4 rounded-lg mb-4">
            <ul className="text-sm text-gray-700 space-y-2">
              <li>1. 本大題總分8分，每小題滿分4分。</li>
              <li>2. 每個錯誤扣0.5分，各部分獨立，扣完為止。</li>
              <li>3. 相同之拼字錯誤或文法錯誤，只扣一次。</li>
              <li>4. 句首未大寫或標點符號不妥，各扣0.5分，只扣一次。</li>
            </ul>
          </div>
          
          <p className="text-gray-700 mb-4">翻譯的原則</p>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div className="bg-blue-50 p-4 rounded-lg">
              <div className="space-y-4">
                <div>
                  <h4 className="font-medium text-blue-700 mb-2">信（Faithfulness）</h4>
                  <ul className="text-sm text-gray-600 space-y-2">
                    <li>• 忠實呈現原文內容和意圖，確保準確無誤。</li>
                    <li>• 適合法律、技術等需高精確度的文本。</li>
                  </ul>
                </div>
                <div>
                  <h4 className="font-medium text-blue-700 mb-2">達（Expressiveness）</h4>
                  <ul className="text-sm text-gray-600 space-y-2">
                    <li>• 譯文通順流暢，符合目標語表達習慣，便於讀者理解。</li>
                    <li>• 常見於日常應用或實用文本。</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="bg-purple-50 p-4 rounded-lg">
              <h4 className="font-medium text-purple-700 mb-2">雅（Elegance）- 進階翻譯方法</h4>
              <ul className="text-sm text-gray-600 space-y-2">
                <li>• 確保譯文具備文學美感和藝術性，重現原作風格與情感。</li>
                <li>• 文學作品翻譯特別注重此項。</li>
              </ul>
            </div>
          </div>
        </CardContent>
      </Card>

      <Tabs defaultValue="result" className="space-y-6">
        <TabsList>
          <TabsTrigger value="result">翻譯結果</TabsTrigger>
          <TabsTrigger value="vocabulary">詞彙分析</TabsTrigger>
          <TabsTrigger value="ocr">OCR 比對</TabsTrigger>
        </TabsList>

        <TabsContent value="result">
          {data.map((result, index) => (
            <Card key={index} className="overflow-hidden">
              <CardContent className="p-6">
                <h3 className="text-lg font-semibold mb-4">翻譯 {index + 1}</h3>
                
                <div className="space-y-6">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="space-y-4">
                      <div className="bg-gray-50 p-4 rounded-lg">
                        <h4 className="font-medium text-gray-900 mb-2">題目</h4>
                        <p className="text-gray-700">{result.originalQuestion}</p>
                      </div>
                      <div className="bg-gray-50 p-4 rounded-lg">
                        <h4 className="font-medium text-gray-900 mb-2">您的翻譯</h4>
                        <p className="text-gray-700">{result.studentAnswer}</p>
                      </div>
                    </div>
                    
                    <div className="space-y-4">
                      <div className="bg-gray-50 p-4 rounded-lg">
                        <h4 className="font-medium text-gray-900 mb-2">參考答案</h4>
                        <p className="text-gray-700">{result.standardAnswer}</p>
                      </div>
                    </div>
                  </div>

                  {result.result?.output && (
                    <div className="space-y-4">
                      <div className="flex items-center justify-between">
                        <h4 className="font-medium text-lg">評分詳情</h4>
                        <span className="text-xl font-semibold text-blue-600">
                          總分: {((getIntScore(result.result.output.Faithfulness?.Score) + 
                                  getIntScore(result.result.output.Expressiveness?.Score)) / 2).toFixed(1)}/4
                        </span>
                      </div>

                      {/* 信度 */}
                      {result.result.output.Faithfulness && (
                        <div className="bg-blue-50 p-4 rounded-lg">
                          <div className="flex items-center justify-between mb-2">
                            <h5 className="font-medium">信度</h5>
                            <span className="text-blue-600 font-semibold">
                              {result.result.output.Faithfulness.Score}/4
                            </span>
                          </div>
                          <p className="text-sm text-gray-600 mb-2">
                            {result.result.output.Faithfulness.Feedback}
                            <br />  
                            {result.result.output.Faithfulness.FeedbackTranslation }
                          </p>
                          <ul className="list-disc list-inside text-sm text-gray-600">
                            {result.result.output.Faithfulness.Examples.map((example: { ExampleText: string, Translation: string }, i: number) => (
                              <li key={i}>
                                {example.ExampleText}
                                <br />
                                <span className="ml-4 text-gray-500">{example.Translation}</span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}

                      {/* 效度 */}
                      {result.result.output.Expressiveness && (
                        <div className="bg-green-50 p-4 rounded-lg">
                          <div className="flex items-center justify-between mb-2">
                            <h5 className="font-medium">效度</h5>
                            <span className="text-green-600 font-semibold">
                              {result.result.output.Expressiveness.Score}/4
                            </span>
                          </div>
                          <p className="text-sm text-gray-600 mb-2">
                            {result.result.output.Expressiveness.Feedback}
                            <br />
                            {result.result.output.Expressiveness.FeedbackTranslation}
                          </p>
                          <ul className="list-disc list-inside text-sm text-gray-600">
                            {result.result.output.Expressiveness.Examples.map((example: { ExampleText: string, Translation: string }, i: number) => (
                              <li key={i}>
                                {example.ExampleText}
                                <br />
                                <span className="ml-4 text-gray-500">{example.Translation}</span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}

                      {/* 優雅度 */}
                      {result.result.output.Elegance && (
                        <div className="bg-purple-50 p-4 rounded-lg">
                          <h5 className="font-medium mb-2">優雅度</h5>
                          <p className="text-sm text-gray-600 mb-2">
                            {result.result.output.Elegance.Feedback}
                            <br />
                            {result.result.output.Elegance.FeedbackTranslation}
                          </p>
                          <ul className="list-disc list-inside text-sm text-gray-600">
                            {result.result.output.Elegance.Examples.map((example: { ExampleText: string, Translation: string }, i: number) => (
                              <li key={i}>
                                {example.ExampleText}
                                <br /><br />
                                <span className="ml-4 text-gray-500">{example.Translation}</span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}

                      {/* 整體評價 */}
                      {result.result.output.OverallEvaluation && (
                        <div className="bg-yellow-50 p-4 rounded-lg">
                          <div className="flex items-center gap-2 mb-2">
                            <h5 className="font-medium">整體評價</h5>
                            <span className="text-yellow-600 font-semibold">
                              {result.result.output.OverallEvaluation.Rating}
                            </span>
                          </div>
                          <p className="text-sm text-gray-600 mb-2">
                            {result.result.output.OverallEvaluation.Explanation}
                            <br />
                            {result.result.output.OverallEvaluation.ExplanTranslation}
                          </p>
                          <div className="mt-2">
                            <p className="font-medium text-sm mb-1">改進建議：</p>
                            <ul className="list-disc list-inside text-sm text-gray-600">
                              {result.result.output.OverallEvaluation.Suggestions.map((suggestion: { Suggestions: string, SuggestTranslation: string }, i: number) => (
                                <li key={i}>
                                  {suggestion.Suggestions}
                                  <br />
                                  <span className="ml-4 text-gray-500">{suggestion.SuggestTranslation}</span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  {result.result?.lang1 && result.result?.lang2 && (
                    <div className="space-y-4">
                      <div className="bg-blue-50 p-4 rounded-lg">
                        <h4 className="font-medium mb-2">CEFR 評估</h4>
                        <p className="text-sm text-gray-600">等級：{result.result.lang1.CEFRLevel}</p>
                        <p className="text-sm text-gray-600 mt-2">{result.result.lang1.Conclusion}</p>
                        <p className="text-sm text-gray-600 mt-2">{result.result.lang2.Conclusion}</p>
                        <div className="mt-4 space-y-2">
                          <p className="text-sm"><strong>Subject： </strong> {result.result.lang1.ContentTheme}</p>
                          <p className="text-sm"><strong>主題內容： </strong> {result.result.lang2.ContentTheme}</p>
                          <p className="text-sm"><strong>Expression of intent： </strong> {result.result.lang1.IntentandExpression}</p>
                          <p className="text-sm"><strong>表達意圖：</strong> {result.result.lang2.IntentandExpression}</p>
                          <p className="text-sm"><strong>Syntactic structure： </strong> {result.result.lang1.SyntaxStructure}</p>
                          <p className="text-sm"><strong>句法結構：</strong> {result.result.lang2.SyntaxStructure}</p>
                          <p className="text-sm"><strong>Vocabulary range： </strong> {result.result.lang1.VocabularyRange}</p>
                          <p className="text-sm"><strong>詞彙範圍： </strong> {result.result.lang2.VocabularyRange}</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </CardContent>
            </Card>
          ))}
        </TabsContent>

        <TabsContent value="vocabulary">
          <VocabularyTab data={vocabularyData} />
        </TabsContent>

        <TabsContent value="ocr">
          <OCRComparisonTab translationData={data} />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default TranslationResultTabs;