// src/components/common/ErrorDisplay.tsx

import React from 'react';
import { Alert, AlertDescription } from '../ui/alert';

interface ErrorDisplayProps {
  message: string;
}

const ErrorDisplay: React.FC<ErrorDisplayProps> = ({ message }) => (
  <Alert variant="destructive">
    <AlertDescription>{message}</AlertDescription>
  </Alert>
);

export default ErrorDisplay;