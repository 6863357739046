import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { BookOpen, History, TrendingUp, FileText, PenTool, Loader2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '../components/ui/dialog';
import { Steps } from '../components/ui/steps';
import { useToast } from '../components/ui/use-toast';
import { cn } from '../lib/utils';

interface StudentInterfaceProps {
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  username: string;
}

interface UserStats {
  remainingEssays: number;
  remainingTranslations: number;
  remainingReadings: number;
  totalSubmissions: number;
  pendingSubmissions: number;
  completedSubmissions: number;
  averageScore: number;
}

interface Practice {
  id: string;
  testType: string;
  examType: string;
  year: number | null;
  status: string;
  createdAt: string;
  title?: string;
  description?: string;
}

interface PracticeResponse {
  success: boolean;
  practices: Practice[];
}

interface ExamType {
  id: number;
  title: string;
  icon: React.ReactNode;
  description: string;
  path: string;
  examType: 'gsat' | 'ast' | 'custom' | 'past-exam' | 'mock';
}

interface PracticeFormData {
  testType: string;
  year: number | null;
  writing_id: string | null;
  translations_id: string[];
}

const StudentInterface: React.FC<StudentInterfaceProps> = ({ setIsAuthenticated, username }) => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [stats, setStats] = useState<UserStats | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [pendingPractices, setPendingPractices] = useState<Practice[]>([]);
  const [selectedExamType, setSelectedExamType] = useState<ExamType | null>(null);
  const [expandedType, setExpandedType] = useState<string | null>(null);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [formData, setFormData] = useState<PracticeFormData>({
    testType: '',
    year: null,
    writing_id: null,
    translations_id: []
  });
  const [availableYears, setAvailableYears] = useState<number[]>([]);
  const [selectedTestType, setSelectedTestType] = useState<string | null>(null);
  const [selectedYear, setSelectedYear] = useState<number | null>(null);
  const [availableRounds, setAvailableRounds] = useState<number[]>([]);

  const fetchPendingPractices = async () => {
    try {
      const response = await fetch('/api/student/practice/list', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (!response.ok) {
        throw new Error('獲取練習列表失敗');
      }

      const data = await response.json() as PracticeResponse;
      if (data.success) {
        const pending = data.practices.filter((p: Practice) => p.status === 'pending');
        setPendingPractices(pending);
      }
    } catch (error) {
      console.error('Error fetching pending practices:', error);
    }
  };

  useEffect(() => {
    const fetchUserStats = async () => {
      try {
        const response = await fetch('/api/student/practice/stats', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        if (!response.ok) {
          throw new Error('獲取統計資料失敗');
        }

        const data = await response.json();
        console.log('Received stats:', data);
        if (data.success) {
          setStats({
            remainingEssays: data.data.remainingEssays || 0,
            remainingTranslations: data.data.remainingTranslations || 0,
            remainingReadings: data.data.remainingReadings || 0,
            totalSubmissions: data.data.totalSubmissions || 0,
            pendingSubmissions: data.data.pendingPractices || 0,
            completedSubmissions: data.data.completedPractices || 0,
            averageScore: parseFloat(data.data.writingStats?.average) || 0
          });
        }
      } catch (error) {
        console.error('Error fetching stats:', error);
        setError(error instanceof Error ? error.message : '發生錯誤');
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserStats();
    fetchPendingPractices();
  }, []);

  const examTypes: ExamType[] = [
    { 
      id: 1, 
      title: '考古題練習', 
      icon: <BookOpen className="h-6 w-6" />,
      description: '歷年各類考試題目練習',
      path: '/student/exam/past',
      examType: 'past-exam'
    },
    { 
      id: 2, 
      title: '自行練習', 
      icon: <PenTool className="h-6 w-6" />,
      description: '自行創建練習題目',
      path: '/student/exam/custom',
      examType: 'custom'
    }
  ];

  const steps = [
    { title: '選擇類型', description: '選擇練習類型' },
    { title: '選擇年份', description: '選擇考試年份' },
    { title: '選擇卷數', description: '選擇考試卷數' },
  ];

  const handleStartPractice = (examType: ExamType & { examType: 'gsat' | 'ast' | 'mock' | 'custom' }) => {
    if (expandedType === examType.examType) {
      setExpandedType(null);
      setCurrentStep(0);
    } else {
      setExpandedType(examType.examType);
      setSelectedExamType(examType);
      setCurrentStep(0);
      setFormData({
        testType: '',
        year: null,
        writing_id: null,
        translations_id: []
      });
    }
  };

  const fetchAvailableYears = async () => {
    if (!selectedExamType) return;
    
    try {
      const response = await fetch(`/api/student/practice/years/${selectedExamType.examType}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (!response.ok) throw new Error('獲取年份失敗');

      const data = await response.json();
      if (data.success) {
        setAvailableYears(data.years);
      }
    } catch (error) {
      console.error('Error fetching years:', error);
      toast({
        variant: "destructive",
        title: "錯誤",
        description: "獲取年份失敗"
      });
    }
  };

  const handleTestTypeSelect = async (type: 'writing' | 'translation-writing') => {
    setFormData(prev => ({ ...prev, testType: type }));
    setCurrentStep(1);
    await fetchAvailableYears();
  };

  const handleYearSelect = async (year: number) => {
    if (!selectedExamType) return;

    try {
      const response = await fetch(`/api/student/practice/fetch-exam/${selectedExamType.examType}/${year}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (!response.ok) throw new Error('獲取題目失敗');

      const data = await response.json();
      if (data.success) {
        setAvailableRounds(data.rounds);
        setCurrentStep(2);
        setSelectedYear(year);
      }
    } catch (error) {
      console.error('Error:', error);
      toast({
        variant: "destructive",
        title: "錯誤",
        description: error instanceof Error ? error.message : '獲取題目失敗'
      });
    }
  };

  const handleRoundSelect = async (round: number) => {
    if (!selectedExamType || !selectedYear) return;

    try {
      const response = await fetch(`/api/student/practice/fetch-exam/${selectedExamType.examType}/${selectedYear}/${round}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (!response.ok) throw new Error('獲取題目失敗');

      const data = await response.json();
      if (data.success) {
        const createResponse = await fetch('/api/student/practice/create', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          body: JSON.stringify({
            testType: formData.testType,
            sourceType: 'past-exam',
            examType: selectedExamType.examType,
            year: selectedYear,
            round,
            writing_id: data.writing_id,
            translations_id: data.translations_id || []
          })
        });

        if (!createResponse.ok) throw new Error('創建練習失敗');

        const createData = await createResponse.json();
        if (createData.success) {
          toast({
            title: "成功",
            description: "練習創建成功",
          });
          setExpandedType(null);
          await fetchPendingPractices();
        }
      }
    } catch (error) {
      console.error('Error:', error);
      toast({
        variant: "destructive",
        title: "錯誤",
        description: error instanceof Error ? error.message : '創建練習失敗'
      });
    }
  };

  if (isLoading) {
    return (
      <div className="flex flex-col min-h-screen">
        <Header setIsAuthenticated={setIsAuthenticated} username={username} />
        <main className="flex-grow bg-gray-50 flex items-center justify-center">
          <Loader2 className="h-8 w-8 animate-spin" />
        </main>
        <Footer />
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen">
      <Header setIsAuthenticated={setIsAuthenticated} username={username} />
      <main className="flex-grow bg-gray-50">
        <div className="max-w-7xl mx-auto p-6">
          <Card className="mb-12">
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <TrendingUp className="h-5 w-5" />
                練習統計
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                <div className="p-4 bg-blue-50 rounded-lg">
                  <p className="text-sm text-gray-600">剩餘作文額度</p>
                  <p className="text-2xl font-bold text-blue-600">
                    {stats?.remainingEssays || 0}
                  </p>
                </div>
                <div className="p-4 bg-green-50 rounded-lg">
                  <p className="text-sm text-gray-600">剩餘翻譯額度</p>
                  <p className="text-2xl font-bold text-green-600">
                    {stats?.remainingTranslations || 0}
                  </p>
                </div>
                <div className="p-4 bg-yellow-50 rounded-lg">
                  <p className="text-sm text-gray-600">待完成練習</p>
                  <p className="text-2xl font-bold text-yellow-600">
                    {stats?.pendingSubmissions || 0}
                  </p>
                </div>
                <div className="p-4 bg-purple-50 rounded-lg">
                  <p className="text-sm text-gray-600">已完成練習</p>
                  <p className="text-2xl font-bold text-purple-600">
                    {stats?.completedSubmissions || 0}
                  </p>
                </div>
              </div>
            </CardContent>
          </Card>
          <Card className="mb-12">
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <BookOpen className="h-5 w-5" />
                練習類型
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {examTypes.map(type => (
                  <div key={type.id} className="space-y-4">
                    <Card className="relative">
                      <CardContent className="pt-6">
                        <div className="flex flex-col space-y-4">
                          <div className="flex items-center gap-4">
                            <div className="p-2 bg-blue-100 rounded">
                              {type.icon}
                            </div>
                            <div>
                              <h3 className="text-lg font-semibold">{type.title}</h3>
                              <p className="text-sm text-gray-600">{type.description}</p>
                            </div>
                          </div>
                          {type.examType === 'past-exam' ? (
                            <div className="grid grid-cols-3 gap-2">
                              <Button
                                onClick={() => handleStartPractice({ ...type, examType: 'gsat' as const })}
                                className={cn(
                                  "bg-blue-600 hover:bg-blue-700",
                                  expandedType === 'gsat' && "bg-blue-800"
                                )}
                              >
                                學測
                              </Button>
                              <Button
                                onClick={() => handleStartPractice({ ...type, examType: 'ast' as const })}
                                className={cn(
                                  "bg-blue-600 hover:bg-blue-700",
                                  expandedType === 'ast' && "bg-blue-800"
                                )}
                              >
                                指考
                              </Button>
                              <Button
                                onClick={() => handleStartPractice({ ...type, examType: 'mock' as const })}
                                className={cn(
                                  "bg-blue-600 hover:bg-blue-700",
                                  expandedType === 'mock' && "bg-blue-800"
                                )}
                              >
                                模考
                              </Button>
                            </div>
                          ) : (
                            <Button
                              onClick={() => navigate('/self-practice')}
                              className="w-full bg-blue-600 hover:bg-blue-700"
                            >
                              開始練習
                            </Button>
                          )}
                        </div>
                      </CardContent>
                    </Card>

                    {expandedType && ['gsat', 'ast', 'mock'].includes(expandedType) && type.examType === 'past-exam' && (
                      <Card className="w-full border-t-0">
                        <CardContent className="pt-6">
                          <div className="space-y-6">
                            <Steps
                              steps={steps}
                              currentStep={currentStep}
                              completedSteps={{}}
                            />
                            
                            {currentStep === 0 && (
                              <div className="grid grid-cols-2 gap-4 w-full">
                                <Button 
                                  variant="outline"
                                  className="h-24 w-full"
                                  onClick={() => {
                                    setFormData(prev => ({ ...prev, testType: 'writing' }));
                                    setCurrentStep(1);
                                    fetchAvailableYears();
                                  }}
                                >
                                  <div className="text-center">
                                    <h3 className="text-lg font-semibold">純作文</h3>
                                    <p className="text-sm text-gray-600">僅包含作文題目</p>
                                  </div>
                                </Button>
                                <Button 
                                  variant="outline"
                                  className="h-24 w-full"
                                  onClick={() => {
                                    setFormData(prev => ({ ...prev, testType: 'translation-writing' }));
                                    setCurrentStep(1);
                                    fetchAvailableYears();
                                  }}
                                >
                                  <div className="text-center">
                                    <h3 className="text-lg font-semibold">翻譯+作文</h3>
                                    <p className="text-sm text-gray-600">包含翻譯和作文題目</p>
                                  </div>
                                </Button>
                              </div>
                            )}

                            {currentStep === 1 && (
                              <div className="grid grid-cols-4 gap-4 w-full">
                                {availableYears.map(year => (
                                  <Button
                                    key={year}
                                    variant="outline"
                                    onClick={() => handleYearSelect(year)}
                                    className="w-full"
                                  >
                                    {year} 年
                                  </Button>
                                ))}
                              </div>
                            )}

                            {currentStep === 2 && (
                              <div className="grid grid-cols-4 gap-4 w-full">
                                {availableRounds.map(round => (
                                  <Button
                                    key={round}
                                    variant="outline"
                                    onClick={() => handleRoundSelect(round)}
                                    className="w-full"
                                  >
                                    第 {round} 卷
                                  </Button>
                                ))}
                              </div>
                            )}

                            <div className="flex justify-between w-full">
                              <Button 
                                variant="outline" 
                                onClick={() => {
                                  if (currentStep === 0) {
                                    setExpandedType(null);
                                  } else {
                                    setCurrentStep(prev => prev - 1);
                                  }
                                }}
                              >
                                {currentStep === 0 ? '取消' : '上一步'}
                              </Button>
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    )}
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>

          {pendingPractices.length > 0 && (
            <Card className="mb-6">
              <CardHeader>
                <CardTitle className="flex items-center gap-2">
                  <History className="h-5 w-5" />
                  未完成的練習
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  {pendingPractices.map((practice) => (
                    <div
                      key={practice.id}
                      className="flex items-center justify-between p-4 bg-white rounded-lg border hover:shadow-md transition-shadow"
                    >
                      <div className="flex items-center space-x-4">
                        <div className="p-2 bg-yellow-100 rounded">
                          <PenTool className="h-5 w-5 text-yellow-600" />
                        </div>
                        <div>
                          <h4 className="font-medium">
                            {practice.examType === 'gsat' ? '學測' : 
                             practice.examType === 'ast' ? '指考' : 
                             '自主練習'}
                            {practice.year && ` ${practice.year}年`}
                          </h4>
                          <p className="text-sm text-gray-500">
                            {practice.testType === 'writing' ? '純作文' : '翻譯+作文'}
                          </p>
                          <p className="text-sm text-gray-500">
                            創建時間：{new Date(practice.createdAt).toLocaleDateString()}
                          </p>
                        </div>
                      </div>
                      <Button
                        variant="outline"
                        onClick={() => navigate(`/practice/${practice.id}`)}
                      >
                        繼續作答
                      </Button>
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>
          )}
        </div>
      </main>
    </div>
  );
};

export default StudentInterface; 