// src/components/ui/dropdown-menu.tsx

import React, { useState } from "react";
import { cn } from "../../lib/utils";

interface DropdownMenuRootProps {
  children: React.ReactNode;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

interface DropdownMenuTriggerProps {
  children: React.ReactNode;
  asChild?: boolean;
}

interface DropdownMenuContentProps extends React.HTMLAttributes<HTMLDivElement> {
  align?: "start" | "center" | "end";
  className?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

interface DropdownMenuItemProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  inset?: boolean;
}

interface DropdownMenuContext {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const DropdownMenuContext = React.createContext<DropdownMenuContext>({
  open: false,
  setOpen: () => {},
});

export const DropdownMenuProvider = ({ children, initialOpen = false }: { children: React.ReactNode, initialOpen?: boolean }) => {
  const [open, setOpen] = useState(initialOpen);

  return (
    <DropdownMenuContext.Provider value={{ open, setOpen }}>
      {children}
    </DropdownMenuContext.Provider>
  );
};

export const DropdownMenu: React.FC<DropdownMenuRootProps> = ({ children }) => {
  return (
    <DropdownMenuProvider>
      <div className="relative inline-block text-left">
        {children}
      </div>
    </DropdownMenuProvider>
  );
};

export const DropdownMenuTrigger: React.FC<DropdownMenuTriggerProps> = ({
  children,
  asChild = false,
}) => {
  const { open, setOpen } = React.useContext(DropdownMenuContext);

  const handleClick = () => {
    setOpen(!open);
  };

  if (asChild) {
    return React.cloneElement(children as React.ReactElement, {
      onClick: handleClick,
      "aria-expanded": open,
      "aria-haspopup": true,
    });
  }

  return (
    <div
      onClick={handleClick}
      aria-expanded={open}
      aria-haspopup={true}
      className="cursor-pointer"
    >
      {children}
    </div>
  );
};

export const DropdownMenuContent: React.FC<DropdownMenuContentProps> = ({
  children,
  align = "end",
  className,
  ...props
}) => {
  const { open } = React.useContext(DropdownMenuContext);

  if (!open) return null;

  return (
    <div
      className={cn(
        "absolute z-50 min-w-[8rem] overflow-hidden rounded-md border border-gray-200 bg-white shadow-md",
        "animate-in fade-in-0 zoom-in-95",
        {
          "right-0": align === "end",
          "left-0": align === "start",
          "left-1/2 -translate-x-1/2": align === "center",
        },
        "mt-1",
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};

export const DropdownMenuItem: React.FC<DropdownMenuItemProps> = ({
  className,
  inset,
  children,
  ...props
}) => {
  return (
    <button
      className={cn(
        "relative flex w-full items-center whitespace-nowrap px-3 py-2 text-sm outline-none transition-colors hover:bg-gray-100",
        inset && "pl-8",
        className
      )}
      role="menuitem"
      {...props}
    >
      {children}
    </button>
  );
};