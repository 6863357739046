import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import { Card } from '../components/ui/card';
import { Loader2 } from 'lucide-react';

interface CorrectionLoadingProps {
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  username: string;
}

const CorrectionLoading: React.FC<CorrectionLoadingProps> = ({ 
  setIsAuthenticated, 
  username 
}) => {
  const [dots, setDots] = useState('');
  const [timeElapsed, setTimeElapsed] = useState(0);

  useEffect(() => {
    // 動態的載入點動畫
    const dotsInterval = setInterval(() => {
      setDots(prev => prev.length >= 3 ? '' : prev + '.');
    }, 500);

    // 計時器
    const timerInterval = setInterval(() => {
      setTimeElapsed(prev => prev + 1);
    }, 1000);

    return () => {
      clearInterval(dotsInterval);
      clearInterval(timerInterval);
    };
  }, []);

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <Header setIsAuthenticated={setIsAuthenticated} username={username} />
      <div className="flex-1 flex items-center justify-center p-4">
        <Card className="w-full max-w-md p-8">
          <div className="flex flex-col items-center space-y-6">
            <div className="relative">
              <Loader2 className="h-16 w-16 animate-spin text-blue-600" />
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="text-sm font-medium text-gray-500">
                  {Math.floor(timeElapsed / 60)}:{(timeElapsed % 60).toString().padStart(2, '0')}
                </div>
              </div>
            </div>
            
            <div className="space-y-2 text-center">
              <h2 className="text-2xl font-semibold text-gray-800">
                正在批改中{dots}
              </h2>
              <p className="text-gray-500">
                請稍候，這可能需要 2-3 分鐘的時間
              </p>
            </div>

            <div className="w-full bg-gray-200 rounded-full h-2 dark:bg-gray-700">
              <div 
                className="bg-blue-600 h-2 rounded-full transition-all duration-500"
                style={{ 
                  width: `${Math.min((timeElapsed / 180) * 100, 100)}%` 
                }}
              />
            </div>
            
            <div className="text-sm text-gray-500">
              已經過 {Math.floor(timeElapsed / 60)} 分 {timeElapsed % 60} 秒
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default CorrectionLoading;