import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import LoadingSpinner from '../components/common/LoadingSpinner';
import TestCreationFlow from '../components/test-creation/TestCreationFlow';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from '../components/ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../components/ui/table';
import { Button } from '../components/ui/button';
import { Input } from '../components/ui/input';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '../components/ui/tabs';
import { Badge } from '../components/ui/badge';
import { Eye, ArrowLeft, BookOpen, TrendingUp, Users, UserPlus, FileText } from 'lucide-react';
import { Essay, StudentResponse, EssayResponse, StudentData ,APIStudentResponse} from '../components/types/essay';
import StatisticalAnalysis from '../components/teacher/statistics-analysis';
import {
  EssayAnalysis,
  StudentStats,
  calculateEssayAnalysis,
} from '../components/types/teacher';
import TeacherQuotaManagement from '../components/teacher/teacher-quota-management';
import ManualCorrection from '../components/teacher/ManualCorrection';
import Footer from '../components/Footer';

interface EssaySubmission {
  _id: string;
  assignmentId?: string;
  title: string;
  createdAt: string;
  type?: string;
  status?: string;
  writingResult?: any;
}

interface ClassGroups {
  [className: string]: StudentData[];
}

interface TeacherStats {
  remainingEssays: number;
  remainingReadings: number;
  remainingTranslations: number;
  totalClasses: number;
  totalStudents: number;
  totalSubmissions: number;
}

interface TopicGroup {
  title: string;
  essays: Essay[];
}

interface TeacherDashboardProps {
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
}

const formatScore = (score: number | string | undefined): string => {
  if (!score) return '-';
  const numScore = typeof score === 'string' ? parseFloat(score) : score;
  return isNaN(numScore) ? '-' : (numScore / 10).toFixed(1);
};

export const fetchTeacherDashboardStats = async () => {
  const token = localStorage.getItem('token');
  const response = await fetch('/api/teacher/dashboard-stats', {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });

  if (!response.ok) throw new Error('Failed to fetch dashboard stats');
  const data = await response.json();
  return data.data;
};

const TeacherDashboard: React.FC<TeacherDashboardProps> = ({ setIsAuthenticated }) => {
  const [students, setStudents] = useState<StudentData[]>([]);
  const [classGroups, setClassGroups] = useState<ClassGroups>({});
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState<string>('');
  const [essays, setEssays] = useState<Essay[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStudent, setSelectedStudent] = useState<string | null>(null);
  const [selectedStudentName, setSelectedStudentName] = useState<string>('');
  const [essayTopics, setEssayTopics] = useState<TopicGroup[]>([]);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const [essayAnalysis, setEssayAnalysis] = useState<EssayAnalysis>(() => ({
    totalEssays: 0,
    averageScore: 0,
    scoreDistribution: {
      excellent: 0,
      good: 0,
      average: 0,
      needsImprovement: 0,
    },
    monthlyStats: [],
    detailedScores: {
      contentScore: 0,
      grammarScore: 0,
      vocabularyScore: 0,
      organizationScore: 0,
    },
    scoresByTopic: {},
    cefrLevels: {},
  }));

  const [teacherStats, setTeacherStats] = useState<TeacherStats>({
    remainingEssays: 0,
    remainingReadings: 0,
    remainingTranslations: 0,
    totalClasses: 0,
    totalStudents: 0,
    totalSubmissions: 0,
  });

  const [statisticsData, setStatisticsData] = useState({
    classGroups: {},
    topics: [],
    selectedClass: '',
    selectedTopic: ''
  });
  

  const formatDate = (dateString: string) => {
    try {
      if (!dateString) return '-';
      let date = dateString.includes('T') 
        ? new Date(dateString)
        : new Date(parseInt(dateString));
      
      if (isNaN(date.getTime())) {
        console.error('Invalid date:', dateString);
        return '-';
      }
      
      return date.toLocaleString('zh-TW', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      });
    } catch (error) {
      console.error('Error formatting date:', error);
      return '-';
    }
  };

  const calculateTeacherStats = (studentData: StudentStats[]) => {
    const classes = new Set(studentData.map(student => student.className));
    const totalSubmissions = studentData.reduce((sum, student) => sum + student.totalEssays, 0);
    setTeacherStats({
      remainingEssays: studentData[0]?.remainingEssays || 0,
      remainingReadings: studentData[0]?.remainingReadings || 0,
      remainingTranslations: studentData[0]?.remainingTranslations || 0,
      totalClasses: classes.size,
      totalStudents: studentData.length,
      totalSubmissions,
    });
  };

  const processScore = (score: string | undefined) => {
    if (!score) return '-';
    const scoreNum = typeof score === 'string' 
      ? parseFloat(score.replace('/100', '')) 
      : parseFloat(score);
    return isNaN(scoreNum) ? '-' : (scoreNum / 10).toFixed(1);
  };

  const fetchStudents = async () => {
    try {
      const response = await fetch('/api/teacher/class-statistics', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      
      const data = await response.json();

      if (!data.success || !data.classes) {
        throw new Error('獲取學生資料失敗');
      }

      // 直接使用後端返回的分組數據
      const groupedStudents: ClassGroups = {};
      
      // 處理每個班級的學生數據
      Object.entries(data.classes).forEach(([className, students]) => {
        if (Array.isArray(students)) {
          groupedStudents[className] = students.map(student => ({
            _id: student._id,
            user_id: student.username,
            username: student.username,
            realName: student.realName,
            className: student.className,
            totalEssays: student.totalEssays || 0,
            averageScore: parseFloat(student.averageScore) || 0,
            vocabularyCount: student.vocabularyCount || 0,
            vocabularyToRemember: student.vocabularyToRemember || 0,
            essays: student.essays || [],
            submissions: student.submissions || [],
            remainingEssays: student.remainingEssays || 0,
            remainingTranslations: student.remainingTranslations || 0,
            remainingReadings: student.remainingReadings || 0
          }));
        }
      });

      const allStudents = Object.values(groupedStudents).flat();
      setClassGroups(groupedStudents);
      setStudents(allStudents);

      // 使用教師的額度數據
      if (data.teacherQuota) {
        setTeacherStats(prevStats => ({
          ...prevStats,
          remainingEssays: data.teacherQuota.remainingEssays || 0,
          remainingTranslations: data.teacherQuota.remainingTranslations || 0,
          remainingReadings: data.teacherQuota.remainingReadings || 0,
          totalClasses: Object.keys(groupedStudents).length,
          totalStudents: allStudents.length,
          totalSubmissions: allStudents.reduce((sum, student) => sum + (student.totalEssays || 0), 0)
        }));
      }

    } catch (error) {
      console.error('Error fetching students:', error);
      setError(error instanceof Error ? error.message : '獲取學生資料失敗');
    }
  };

  const handleViewStudentEssays = (student: StudentData) => {
    try {
      setSelectedStudent(student.username);
      setSelectedStudentName(student.realName);
   
      const essayData: Essay[] = student.submissions?.map(sub => ({
        _id: sub._id,
        title: sub.title,
        createdAt: sub.createdAt,
        content: '',
        userId: student.username,
        WordCount: sub.writingResult?.WordCount || 0,
        correctionResult: sub.writingResult || {} 
      })) || [];
   
      setEssays(essayData);
    } catch (error) {
      console.error('Error viewing student essays:', error);
      setError(error instanceof Error ? error.message : '無法查看學生作文');
    }
   };

  const handleViewEssay = async (essayId: string) => {
    try {
      setIsLoading(true);
      const response = await fetch(`/api/teacher/submission/${essayId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch essay data');
      }

      const data = await response.json();
      if (data.success) {
        navigate(`/correction-result/teacher/${essayId}`);
      }
    } catch (error) {
      console.error('Error fetching essay:', error);
      setError(error instanceof Error ? error.message : '獲取作文失敗');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchEssayTopics = async () => {
    try {
      setIsLoading(true);
      const response = await fetch('/api/teacher/essays-analysis', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch essays');
      }

      const data = await response.json();
      console.log('Essays Response:', data);

      if (!data.success || !data.essaysByTopic) {
        throw new Error('Invalid response format');
      }

      // 修改數據處理邏輯
      const formattedTopics = Object.entries(data.essaysByTopic).map(([title, essays]) => ({
        title,
        essays: (essays as any[]).map(essay => ({
          _id: essay.id,
          title: title,
          createdAt: essay.createdAt,
          content: '',
          userId: essay.studentInfo?.username || '',
          WordCount: essay.writingResult?.WordCount || 0,
          correctionResult: essay.writingResult || {},
          studentInfo: {
            ...essay.studentInfo,
            realName: essay.studentInfo?.realName || essay.customStudentName || '-',
            isCustomStudent: essay.studentInfo?.isCustomStudent || false
          },
          type: essay.type,
          isTeacherSubmission: essay.isTeacherSubmission || false
        }))
      }));

      console.log('Formatted Topics:', formattedTopics);
      setEssayTopics(formattedTopics);
    } catch (error) {
      console.error('Error fetching essay topics:', error);
      setError(error instanceof Error ? error.message : '獲取作文主題失敗');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchStatisticsData = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch('/api/analytics/dashboard-stats', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (!response.ok) throw new Error('獲取統計數據失敗');
      const data = await response.json();
      setStatisticsData(data);
    } catch (error) {
      console.error('Error fetching statistics:', error);
    }
  };

  useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    if (storedUsername) {
      setUsername(storedUsername);
    }
    fetchStudents();
    fetchEssayTopics();
    fetchDashboardData();
    fetchStatisticsData();
  }, []);

  const fetchDashboardData = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch('/api/teacher/dashboard-stats', {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) throw new Error('Failed to fetch dashboard stats');
      const data = await response.json();
      if (data.success) {
        setTeacherStats({
          remainingEssays: data.data.remainingEssays,
          remainingReadings: data.data.remainingReadings,
          remainingTranslations: data.data.remainingTranslations,
          totalClasses: data.data.totalClasses,
          totalStudents: data.data.totalStudents,
          totalSubmissions: data.data.totalSubmissions
        });
      }
    } catch (error) {
      console.error('Error fetching dashboard stats:', error);
    }
  };

  const filteredStudents = useMemo(() => {
    return students.filter(
      student =>
        student.realName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        student.username?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        student.className?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [students, searchTerm]);

  if (isLoading) {
    return <LoadingSpinner />;
  }
  
  return (
    <div className="min-h-screen flex flex-col">
      <Header setIsAuthenticated={setIsAuthenticated} username={username} />
      <div className="flex-1 container mx-auto p-6 pb-16">
        {/* Stats Cards */}
        <div className="grid grid-cols-3 gap-4 mb-6">
          <Card>
            <CardContent className="pt-6">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <BookOpen className="h-5 w-5 text-purple-500" />
                  <div>
                    <p className="text-sm text-gray-500">剩餘作文額度</p>
                    <p className="text-2xl font-bold">{teacherStats.remainingEssays}</p>
                  </div>
                </div>
                <TrendingUp className="h-5 w-5 text-purple-200" />
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardContent className="pt-6">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <BookOpen className="h-5 w-5 text-blue-500" />
                  <div>
                    <p className="text-sm text-gray-500">剩餘翻譯額度</p>
                    <p className="text-2xl font-bold">{teacherStats.remainingTranslations}</p>
                  </div>
                </div>
                <TrendingUp className="h-5 w-5 text-blue-200" />
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardContent className="pt-6">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <BookOpen className="h-5 w-5 text-orange-500" />
                  <div>
                    <p className="text-sm text-gray-500">剩餘閱讀額度</p>
                    <p className="text-2xl font-bold">{teacherStats.remainingReadings}</p>
                  </div>
                </div>
                <TrendingUp className="h-5 w-5 text-orange-200" />
              </div>
            </CardContent>
          </Card>
        </div>

        <div className="grid grid-cols-3 gap-4 mb-6">
          <Card>
            <CardContent className="pt-6">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <Users className="h-5 w-5 text-green-500" />
                  <div>
                    <p className="text-sm text-gray-500">負責班級數</p>
                    <p className="text-2xl font-bold">{teacherStats.totalClasses}</p>
                  </div>
                </div>
                <TrendingUp className="h-5 w-5 text-green-200" />
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardContent className="pt-6">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <UserPlus className="h-5 w-5 text-indigo-500" />
                  <div>
                    <p className="text-sm text-gray-500">學生總數</p>
                    <p className="text-2xl font-bold">{teacherStats.totalStudents}</p>
                  </div>
                </div>
                <TrendingUp className="h-5 w-5 text-indigo-200" />
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardContent className="pt-6">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <FileText className="h-5 w-5 text-red-500" />
                  <div>
                    <p className="text-sm text-gray-500">作業提交總數</p>
                    <p className="text-2xl font-bold">{teacherStats.totalSubmissions}</p>
                  </div>
                </div>
                <TrendingUp className="h-5 w-5 text-red-200" />
              </div>
            </CardContent>
          </Card>
        </div>

        <Card className="mb-6">
          <CardHeader>
            <CardTitle>教師儀表板</CardTitle>
            <CardDescription>管理和查看學生作文</CardDescription>
          </CardHeader>
        </Card>

        <Tabs defaultValue="students" className="space-y-4">
          <TabsList className="grid w-full grid-cols-6 h-10">
            <TabsTrigger value="students">學生管理</TabsTrigger>
            <TabsTrigger value="test-creation">開始出題</TabsTrigger>
            <TabsTrigger value="manual-correction">輸入批改</TabsTrigger>
            <TabsTrigger value="essays">作文批改</TabsTrigger>
            <TabsTrigger value="statistics">統計分析</TabsTrigger>
            <TabsTrigger value="quota">額度管理</TabsTrigger>
          </TabsList>

          <TabsContent value="students">
            <Card>
              <CardHeader>
                <div className="flex justify-between items-center">
                  <CardTitle>
                    {selectedStudent ? (
                      <div className="flex items-center gap-2">
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => {
                            setSelectedStudent(null);
                            setSelectedStudentName('');
                            setEssays([]);
                          }}
                        >
                          <ArrowLeft className="h-4 w-4 mr-2" />
                          返回
                        </Button>
                        <span>{selectedStudentName} 的作文列表</span>
                      </div>
                    ) : (
                      '學生列表'
                    )}
                  </CardTitle>
                  {!selectedStudent && (
                    <Input
                      placeholder="搜尋學生..."
                      value={searchTerm}
                      onChange={e => setSearchTerm(e.target.value)}
                      className="max-w-xs"
                    />
                  )}
                </div>
              </CardHeader>
              <CardContent>
                {!selectedStudent ? (
                  <div>
                    {Object.entries(classGroups).map(([className, classStudents]) => (
                      <div key={className} className="mb-8">
                        <h3 className="text-lg font-semibold mb-4">{className}</h3>
                        <Table>
                          <TableHeader>
                            <TableRow>
                              <TableHead>姓名</TableHead>
                              <TableHead>剩餘額度</TableHead>
                              <TableHead>已交作文</TableHead>
                              <TableHead>平均分數</TableHead>
                              <TableHead>累積單詞量</TableHead>
                              <TableHead>需要記憶單詞量</TableHead>
                              <TableHead>操作</TableHead>
                            </TableRow>
                          </TableHeader>
                          <TableBody>
                            {classStudents.map((student) => (
                              <TableRow key={student._id}>
                                <TableCell>{student.realName}</TableCell>
                                <TableCell>{student.remainingEssays}</TableCell>
                                <TableCell>{student.totalEssays}</TableCell>
                                <TableCell>
                                  <Badge variant="secondary">
                                    {student.averageScore}
                                  </Badge>
                                </TableCell>
                                <TableCell className="text-center">{student.vocabularyCount}</TableCell>
                                <TableCell className="text-center">{student.vocabularyToRemember}</TableCell>
                                <TableCell>
                                  <Button
                                    variant="outline"
                                    size="sm"
                                    onClick={() => handleViewStudentEssays(student)}
                                  >
                                    查看作文
                                  </Button>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </div>
                    ))}
                  </div>
                ) : (
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead className="w-[300px]">作文標題</TableHead>
                        <TableHead className="w-[180px]">提交時間</TableHead>
                        <TableHead className="text-center">分數</TableHead>
                        <TableHead className="text-center">字數</TableHead>
                        <TableHead className="text-center">CEFR</TableHead>
                        <TableHead className="text-right">操作</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {essays.length > 0 ? (
                        essays.map(essay => (
                          <TableRow key={essay._id}>
                            <TableCell className="font-medium">{essay.title}</TableCell>
                            <TableCell>{formatDate(essay.createdAt)}</TableCell>
                            <TableCell className="text-center">
                              <Badge variant="secondary">
                                {essay.correctionResult?.Suggestion_lang1?.['Total Score']
                                  ? formatScore(essay.correctionResult.Suggestion_lang1['Total Score'])
                                  : '-'}
                              </Badge>
                            </TableCell>
                            <TableCell className="text-center">
                              {essay.WordCount || '-'}
                            </TableCell>
                            <TableCell className="text-center">
                              <Badge variant="outline">
                                {essay.correctionResult?.article_cerf_lang1?.CEFRLevel || '-'}
                              </Badge>
                            </TableCell>
                            <TableCell className="text-right">
                              <Button
                                variant="outline"
                                size="sm"
                                onClick={() => handleViewEssay(essay._id)}
                              >
                                <Eye className="h-4 w-4 mr-2" />
                                查看詳情
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={6} className="text-center py-8 text-gray-500">
                            暫無作文記錄
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                )}
              </CardContent>
            </Card>
          </TabsContent>

          <TabsContent value="test-creation">
            <TestCreationFlow />
          </TabsContent>

          <TabsContent value="manual-correction">
          <ManualCorrection />
        </TabsContent>

          <TabsContent value="essays">
            <Card>
              <CardHeader>
                <CardTitle>作文批改進度追蹤</CardTitle>
                <CardDescription>依主題檢視所有學生作文</CardDescription>
              </CardHeader>
              <CardContent>
                {essayTopics && essayTopics.length > 0 ? (
                  essayTopics.map(topic => (
                    <div key={topic.title} className="mb-8">
                      <div className="flex items-center justify-between mb-4">
                        <h2 className="text-xl font-semibold">{topic.title}</h2>
                        <Badge variant="secondary">共 {topic.essays.length} 篇作文</Badge>
                      </div>
                      <Table>
                        <TableHeader>
                          <TableRow>
                            <TableHead>學生姓名</TableHead>
                            <TableHead>班級</TableHead>
                            <TableHead>提交間</TableHead>
                            <TableHead className="text-center">作文分數</TableHead>
                            <TableHead className="text-center">字數</TableHead>
                            <TableHead className="text-center">CEFR</TableHead>
                            <TableHead className="text-center">內容</TableHead>
                            <TableHead className="text-center">文法</TableHead>
                            <TableHead className="text-center">詞彙</TableHead>
                            <TableHead className="text-right">操作</TableHead>
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {topic.essays.map((essay: any) => {
                            console.log('Processing essay:', essay);
                            
                            const writingResult = essay.correctionResult || {};
                            const writingScore = writingResult?.Suggestion_lang1?.['Total Score']
                              ? processScore(writingResult.Suggestion_lang1['Total Score'])
                              : '-';

                            const contentScore = writingResult?.ContentScoreAgen_lang1?.ContentScore
                              ? processScore(writingResult.ContentScoreAgen_lang1.ContentScore)
                              : '-';

                            const grammarScore = writingResult?.GrammarStructureScoreAgent_lang1?.Score
                              ? processScore(writingResult.GrammarStructureScoreAgent_lang1.Score)
                              : '-';

                            const vocabScore = writingResult?.VocabularySpellingScoreAgent_lang1?.Score
                              ? processScore(writingResult.VocabularySpellingScoreAgent_lang1.Score)
                              : '-';

                            const cefrLevel = writingResult?.article_cerf_lang1?.CEFRLevel || '-';
                            
                            return (
                              <TableRow key={essay._id}>
                                <TableCell>
                                  {essay.isTeacherSubmission ? (
                                    <>
                                      {essay.studentInfo?.realName || '-'}
                                      {essay.studentInfo?.isCustomStudent ? (
                                        <Badge variant="outline" className="ml-2">自訂學生</Badge>
                                      ) : (
                                        <Badge variant="outline" className="ml-2">手動批改</Badge>
                                      )}
                                    </>
                                  ) : (
                                    essay.studentInfo?.realName || '-'
                                  )}
                                </TableCell>
                                <TableCell>{essay.studentInfo?.className || '-'}</TableCell>
                                <TableCell>{formatDate(essay.createdAt)}</TableCell>
                                <TableCell className="text-center">
                                  <Badge variant="secondary">{writingScore}</Badge>
                                </TableCell>
                                <TableCell className="text-center">
                                  {essay.WordCount || '-'}
                                </TableCell>
                                <TableCell className="text-center">
                                  <Badge variant="outline">{cefrLevel}</Badge>
                                </TableCell>
                                <TableCell className="text-center">
                                  <Badge variant="outline" className="bg-blue-50">
                                    {contentScore}
                                  </Badge>
                                </TableCell>
                                <TableCell className="text-center">
                                  <Badge variant="outline" className="bg-green-50">
                                    {grammarScore}
                                  </Badge>
                                </TableCell>
                                <TableCell className="text-center">
                                  <Badge variant="outline" className="bg-purple-50">
                                    {vocabScore}
                                  </Badge>
                                </TableCell>
                                <TableCell className="text-right">
                                  <Button
                                    variant="outline"
                                    size="sm"
                                    onClick={() => handleViewEssay(essay._id)}
                                  >
                                    <Eye className="h-4 w-4 mr-2" />
                                    查看詳情
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </div>
                  ))
                ) : (
                  <div className="text-center py-8 text-gray-500">
                    {isLoading ? '載入中...' : '暫無作文記錄'}
                  </div>
                )}
              </CardContent>
            </Card>
          </TabsContent>

          <TabsContent value="statistics">
            {students.length > 0 ? (
              <StatisticalAnalysis 
                students={students.map(student => ({
                  _id: student._id,
                  username: student.username,
                  realName: student.realName,
                  className: student.className,
                  totalEssays: student.totalEssays,
                  averageScore: student.averageScore,
                  vocabularyCount: student.vocabularyCount,
                  vocabularyToRemember: student.vocabularyToRemember,
                  submissions: student.submissions || []
                }))}
              />
            ) : (
              <Card>
                <CardHeader>
                  <CardTitle>暫無統計資料</CardTitle>
                </CardHeader>
                <CardContent>
                  <p>目前沒有可用的統計資料。</p>
                </CardContent>
              </Card>
            )}
          </TabsContent>
          <TabsContent value="quota">
            <TeacherQuotaManagement onQuotaUpdate={fetchDashboardData} />
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
};

export default TeacherDashboard;