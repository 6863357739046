import React from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../ui/tabs';
import AccuracyAnalysisTab from './AccuracyAnalysisTab';
import ComparisonTab from './ComparisonTab';
import StructureTab from './StructureTab';
import ScoreTab from './ScoreTab';
import FeedbackTab from './FeedbackTab';
import VocabularyTab from './VocabularyTab';
import { CorrectionData } from '../../types/correction';
import OCRComparisonTab from './OCRComparisonTab';

interface WritingResultTabsProps {
  data: {
    content: string;
    result: CorrectionData;
  };
}

const WritingResultTabs: React.FC<WritingResultTabsProps> = ({ data }) => {
  return (
    <div className="space-y-6">
      <div className="bg-white p-6 rounded-lg shadow">
        <h3 className="text-lg font-semibold mb-4">作文原文</h3>
        <div className="bg-gray-50 p-4 rounded-lg">
          <p className="whitespace-pre-wrap">{data.content}</p>
        </div>
      </div>

      <Tabs defaultValue="comparison" className="space-y-6">
        <TabsList>
          <TabsTrigger value="accuracy">精確性分析</TabsTrigger>
          <TabsTrigger value="comparison">作文對照</TabsTrigger>
          <TabsTrigger value="ocr">OCR 比對</TabsTrigger>
          <TabsTrigger value="structure">作文結構</TabsTrigger>
          <TabsTrigger value="score">評分與評論</TabsTrigger>
          <TabsTrigger value="feedback">錯誤分析</TabsTrigger>
          <TabsTrigger value="vocabulary">語料庫</TabsTrigger>
          
        </TabsList>

        <TabsContent value="accuracy">
          <AccuracyAnalysisTab data={data.result} />
        </TabsContent>
        <TabsContent value="comparison">
          <ComparisonTab data={data.result} />
        </TabsContent>
        <TabsContent value="structure">
          <StructureTab data={data.result} />
        </TabsContent>
        <TabsContent value="score">
          <ScoreTab data={data.result} />
        </TabsContent>
        <TabsContent value="feedback">
          <FeedbackTab data={data.result} />
        </TabsContent>
        <TabsContent value="vocabulary">
          <VocabularyTab data={data.result} />
        </TabsContent>
        <TabsContent value="ocr">
          <OCRComparisonTab writingData={data} />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default WritingResultTabs; 