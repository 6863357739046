import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
  RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar,
  ResponsiveContainer
} from 'recharts';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";

interface ClassAnalysisProps {
  classes: string[];
}

interface ClassAnalysisData {
  minScore: number;
  maxScore: number;
  averageScore: number;
  scoresTrend: Array<{
    date: string;
    score: number;
    totalScore: number;
  }>;
  radarData: Array<{
    topic: string;
    content: number;
    grammar: number;
    vocabulary: number;
    organization: number;
  }>;
  studentScores: Array<{
    studentName: string;
    score: number;
  }>;
  commonMistakes: Array<{
    word: string;
    count: number;
  }>;
  corpusAnalysis: {
    richness: number;
    totalWords: number;
    uniqueWords: number;
  };
  vocabularyStats: {
    basic: Array<{
      word: string;
      count: number;
      translation?: string;
    }>;
    advanced: Array<{
      word: string;
      count: number;
      translation?: string;
    }>;
    repeated: Array<{
      word: string;
      count: number;
    }>;
  };
  studentRankings: Array<{
    name: string;
    score: number;
    trend: Array<{
      date: string;
      score: number;
    }>;
  }>;
  assignmentStats: Array<AssignmentStats>;
  studentTrends: Array<StudentScoreTrend>;
}

interface AssignmentStats {
  topic: string;
  minScore: number;
  maxScore: number;
  averageScore: number;
  rankings: Array<{
    studentName: string;
    score: number;
  }>;
}

interface StudentScoreTrend {
  studentName: string;
  scores: Array<{
    topic: string;
    date: string;
    score: number;
  }>;
}

const ClassAnalysis: React.FC<ClassAnalysisProps> = ({ classes }) => {
  const [selectedClass, setSelectedClass] = useState('');
  const [analysisData, setAnalysisData] = useState<ClassAnalysisData | null>(null);
  const [loading, setLoading] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState('');

  // 添加防護檢查
  useEffect(() => {
    if (!classes || classes.length === 0) return;
    setSelectedClass(classes[0] || '');
  }, [classes]);

  // 獲取班級分析數據
  const fetchClassAnalysis = async () => {
    if (!selectedClass) return;

    setLoading(true);
    try {
      const response = await fetch('/api/analytics/class-analysis', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          className: selectedClass
        })
      });

      if (!response.ok) throw new Error('Failed to fetch class analysis');
      const { data } = await response.json();
      setAnalysisData(data);
    } catch (error) {
      console.error('Error fetching class analysis:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClassAnalysis();
  }, [selectedClass]);

  return (
    <div className="space-y-6">
      {/* 選擇區域 */}
      <Card>
        <CardContent className="pt-6">
          <Select value={selectedClass} onValueChange={setSelectedClass}>
            <SelectTrigger>
              <SelectValue placeholder="選擇班級" />
            </SelectTrigger>
            <SelectContent>
              {(classes || []).map((classItem) => (
                <SelectItem key={classItem} value={classItem}>
                  {classItem}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </CardContent>
      </Card>

      {analysisData && (
        <>
          {/* 基本統計 */}
          <Card>
            <CardHeader>
              <CardTitle>基本統計</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="grid grid-cols-3 gap-4">
                <div>
                  <h4 className="font-semibold">最低分</h4>
                  <p className="text-2xl">{analysisData.minScore}</p>
                </div>
                <div>
                  <h4 className="font-semibold">最高分</h4>
                  <p className="text-2xl">{analysisData.maxScore}</p>
                </div>
                <div>
                  <h4 className="font-semibold">平均分</h4>
                  <p className="text-2xl">{analysisData.averageScore.toFixed(1)}</p>
                </div>
              </div>
            </CardContent>
          </Card>

          {/* 分數趨勢 */}
          <Card>
            <CardHeader>
              <CardTitle>分數趨勢</CardTitle>
            </CardHeader>
            <CardContent>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={analysisData.scoresTrend}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line 
                    type="monotone" 
                    dataKey="score" 
                    stroke="#8884d8" 
                    name="單次分數"
                  />
                  <Line 
                    type="monotone" 
                    dataKey="totalScore" 
                    stroke="#82ca9d" 
                    name="總分"
                  />
                </LineChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>

          {/* 能力雷達圖 */}
          <Card>
            <CardHeader>
              <CardTitle>能力分析</CardTitle>
            </CardHeader>
            <CardContent>
              <Tabs defaultValue={analysisData.radarData[0]?.topic}>
                <TabsList className="w-full h-full flex-wrap">
                  {analysisData.radarData.map((data) => (
                    <TabsTrigger key={data.topic} value={data.topic} className="flex-grow">
                      {data.topic}
                    </TabsTrigger>
                  ))}
                </TabsList>
                {analysisData.radarData.map((data) => (
                  <TabsContent key={data.topic} value={data.topic}>
                    <ResponsiveContainer width="100%" height={400}>
                      <RadarChart data={[
                        { criteria: '內容', score: data.content },
                        { criteria: '文法', score: data.grammar },
                        { criteria: '詞彙', score: data.vocabulary },
                        { criteria: '組織', score: data.organization }
                      ]}>
                        <PolarGrid />
                        <PolarAngleAxis dataKey="criteria" />
                        <PolarRadiusAxis angle={30} domain={[0, 5]} />
                        <Radar
                          name="分數"
                          dataKey="score"
                          stroke="#8884d8"
                          fill="#8884d8"
                          fillOpacity={0.6}
                        />
                        <Legend />
                        <Tooltip />
                      </RadarChart>
                    </ResponsiveContainer>
                  </TabsContent>
                ))}
              </Tabs>
            </CardContent>
          </Card>

          {/* 學生排名 */}
          <Card>
            <CardHeader>
              <CardTitle>學生排名</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-2">
                {analysisData.studentRankings.map((student, index) => (
                  <div 
                    key={student.name} 
                    className="flex items-center justify-between p-3 bg-gray-50 rounded"
                  >
                    <div className="flex items-center space-x-4">
                      <span className="text-lg font-bold">{index + 1}</span>
                      <span>{student.name}</span>
                    </div>
                    <span className="font-semibold">{(student.score / 10).toFixed(1)}</span>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>

          {/* 常見錯誤 */}
          <Card>
            <CardHeader>
              <CardTitle>常見錯誤</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-2">
                {analysisData.commonMistakes.map((mistake, index) => (
                  <div 
                    key={mistake.word} 
                    className="flex justify-between items-center p-2 bg-red-50 rounded"
                  >
                    <span>{mistake.word}</span>
                    <span className="text-red-600">{mistake.count}次</span>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>


          {/* 詞彙統計 */}
          <div className="grid grid-cols-3 gap-6">
            <Card>
              <CardHeader>
                <CardTitle>基礎詞彙</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="space-y-2 max-h-[300px] overflow-y-auto">
                  {analysisData.vocabularyStats.basic.map((word) => (
                    <div 
                      key={word.word} 
                      className="p-2 bg-blue-50 rounded"
                    >
                      <div className="flex justify-between">
                        <span>{word.word}</span>
                        <span>{word.count}次</span>
                      </div>
                      {word.translation && (
                        <p className="text-sm text-gray-600">{word.translation}</p>
                      )}
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>進階詞彙</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="space-y-2 max-h-[300px] overflow-y-auto">
                  {analysisData.vocabularyStats.advanced.map((word) => (
                    <div 
                      key={word.word} 
                      className="p-2 bg-green-50 rounded"
                    >
                      <div className="flex justify-between">
                        <span>{word.word}</span>
                        <span>{word.count}次</span>
                      </div>
                      {word.translation && (
                        <p className="text-sm text-gray-600">{word.translation}</p>
                      )}
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>重複詞彙</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="space-y-2 max-h-[300px] overflow-y-auto">
                  {analysisData.vocabularyStats.repeated.map((word) => (
                    <div 
                      key={word.word} 
                      className="p-2 bg-yellow-50 rounded"
                    >
                      <div className="flex justify-between">
                        <span>{word.word}</span>
                        <span>{word.count}次</span>
                      </div>
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>
          </div>

          {/* 作業統計數據 */}
          <Card>
            <CardHeader>
              <CardTitle>作業統計</CardTitle>
            </CardHeader>
            <CardContent>
              <Tabs defaultValue={analysisData.assignmentStats[0]?.topic}>
                <TabsList className="w-full h-full flex-wrap">
                  {analysisData.assignmentStats.map((stats) => (
                    <TabsTrigger key={stats.topic} value={stats.topic} className="flex-grow">
                      {stats.topic}
                    </TabsTrigger>
                  ))}
                </TabsList>
                {analysisData.assignmentStats.map((stats) => (
                  <TabsContent key={stats.topic} value={stats.topic}>
                    <div className="grid grid-cols-3 gap-4 mb-6">
                      <div className="p-4 bg-gray-50 rounded">
                        <h4 className="font-semibold text-gray-600">最低分</h4>
                        <p className="text-2xl">{stats.minScore.toFixed(1)}</p>
                      </div>
                      <div className="p-4 bg-gray-50 rounded">
                        <h4 className="font-semibold text-gray-600">最高分</h4>
                        <p className="text-2xl">{stats.maxScore.toFixed(1)}</p>
                      </div>
                      <div className="p-4 bg-gray-50 rounded">
                        <h4 className="font-semibold text-gray-600">平均分</h4>
                        <p className="text-2xl">{stats.averageScore.toFixed(1)}</p>
                      </div>
                    </div>
                    <div className="space-y-2">
                      <h4 className="font-semibold text-gray-600 mb-2">排名</h4>
                      {stats.rankings.map((rank, index) => (
                        <div 
                          key={rank.studentName} 
                          className="flex justify-between items-center p-2 bg-gray-50 rounded"
                        >
                          <span className="flex items-center gap-2">
                            <span className="font-bold">{index + 1}</span>
                            <span>{rank.studentName}</span>
                          </span>
                          <span>{rank.score.toFixed(1)}</span>
                        </div>
                      ))}
                    </div>
                  </TabsContent>
                ))}
              </Tabs>
            </CardContent>
          </Card>

          {/* 學生分數曲線 */}
          <Card>
            <CardHeader>
              <CardTitle>學生分數曲線</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <Select 
                  onValueChange={(value) => setSelectedStudent(value)}
                  value={selectedStudent}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="選擇學生" />
                  </SelectTrigger>
                  <SelectContent>
                    {analysisData.studentTrends.map((student) => (
                      <SelectItem key={student.studentName} value={student.studentName}>
                        {student.studentName}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>

                {selectedStudent && (
                  <ResponsiveContainer width="100%" height={300}>
                    <LineChart 
                      data={analysisData.studentTrends.find(
                        s => s.studentName === selectedStudent
                      )?.scores || []}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="topic" />
                      <YAxis domain={[0, 20]} />
                      <Tooltip />
                      <Legend />
                      <Line
                        type="monotone"
                        dataKey="score"
                        stroke="#8884d8"
                        name="分數"
                      />
                    </LineChart>
                  </ResponsiveContainer>
                )}
              </div>
            </CardContent>
          </Card>
        </>
      )}
    </div>
  );
};

export default ClassAnalysis;