import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from '../ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../ui/table';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Label } from '../ui/label';
import { Alert, AlertDescription } from '../ui/alert';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '../ui/dialog';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select';
import { Search } from 'lucide-react';


interface Student {
  _id: string;
  username: string;
  realName: string;
  school: string;
  className: string;
  remainingEssays: number;
  remainingTranslations: number;
  remainingReadings: number;
}

interface QuotaHistory {
  amount: number;
  quotaType: 'essay' | 'translation' | 'reading';
  reason: string;
  adjustedAt: string;
  adjustedBy: string;
}

interface ClassGroups {
  [className: string]: Student[];
}

interface Props {
  onQuotaUpdate: () => Promise<void>;
}

const TeacherQuotaManagement: React.FC<Props> = ({ onQuotaUpdate }) => {
  const [students, setStudents] = useState<Student[]>([]);
  const [selectedStudent, setSelectedStudent] = useState<Student | null>(null);
  const [quotaAmount, setQuotaAmount] = useState<number>(0);
  const [quotaType, setQuotaType] = useState<'essay' | 'translation' | 'reading'>('essay');
  const [reason, setReason] = useState<string>('');
  const [showQuotaDialog, setShowQuotaDialog] = useState(false);
  const [showHistoryDialog, setShowHistoryDialog] = useState(false);
  const [quotaHistory, setQuotaHistory] = useState<QuotaHistory[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedClass, setSelectedClass] = useState<string>('');


  // 獲取教師的學生列表
  const fetchStudents = async () => {
    try {
      setIsLoading(true);
      const response = await fetch('/api/teacher/class-statistics', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) {
        throw new Error('獲取學生資料失敗');
      }

      const data = await response.json();
      if (data.success && data.classes) {
        const allStudents: Student[] = Object.values(data.classes as ClassGroups).flat().map((student: any) => ({
          _id: student._id,
          username: student.username,
          realName: student.realName,
          school: student.school || '',
          className: student.className,
          remainingEssays: student.remainingEssays || 0,
          remainingTranslations: student.remainingTranslations || 0,
          remainingReadings: student.remainingReadings || 0
        }));
        setStudents(allStudents);
      }
    } catch (error) {
      console.error('獲取學生資料失敗:', error);
      setError(error instanceof Error ? error.message : '獲取學生資料失敗');
    } finally {
      setIsLoading(false);
    }
  };

  // 獲取額度歷史
  const fetchQuotaHistory = async (userId: string) => {
    try {
      setIsLoading(true);
      const response = await fetch(`/api/teacher/student/${userId}/quota-history`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) {
        throw new Error('獲取額度歷史失敗');
      }

      const data = await response.json();
      if (data.success) {
        setQuotaHistory(data.history || []);
        setShowHistoryDialog(true);
      }
    } catch (error) {
      console.error('獲取額度歷史失敗:', error);
      setError(error instanceof Error ? error.message : '獲取額度歷史失敗');
    } finally {
      setIsLoading(false);
    }
  };

  // 更新學生額度
  const handleUpdateQuota = async () => {
    if (!selectedStudent || !quotaAmount) return;

    try {
      setIsLoading(true);
      const response = await fetch('/api/teacher/update-student-quota', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          studentId: selectedStudent._id,
          quotaType,
          amount: quotaAmount,
          reason: reason || '教師分配額度'
        }),
      });

      if (!response.ok) {
        throw new Error('更新額度失敗');
      }

      const data = await response.json();
      if (data.success) {
        await onQuotaUpdate(); // 呼叫父組件傳來的更新函數
        await fetchStudents(); // 更新學生列表
        setShowQuotaDialog(false);
        setQuotaAmount(0);
        setReason('');
      }
    } catch (error) {
      console.error('更新額度失敗:', error);
      setError(error instanceof Error ? error.message : '更新額度失敗');
    } finally {
      setIsLoading(false);
    }
  };
  
  useEffect(() => {
    fetchStudents();
  }, []);

  // 篩選學生
  const filteredStudents = students.filter(student =>
    student.realName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    student.className?.toLowerCase().includes(searchTerm.toLowerCase())
  );

   // 按班級分組的學生數據
   const groupedStudents = students.reduce((acc, student) => {
    const className = student.className || '未分類';
    if (!acc[className]) {
      acc[className] = [];
    }
    acc[className].push(student);
    return acc;
  }, {} as Record<string, Student[]>);

  // 獲取所有班級名稱
  const classNames = Object.keys(groupedStudents).sort();

  // 處理班級選擇
  const handleClassSelect = (className: string) => {
    setSelectedClass(className);
    const element = document.getElementById(`class-${className}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // 篩選學生
  const filteredGroupedStudents = Object.entries(groupedStudents).reduce(
    (acc, [className, students]) => {
      const filteredStudents = students.filter(
        student =>
          student.realName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          student.className?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      if (filteredStudents.length > 0) {
        acc[className] = filteredStudents;
      }
      return acc;
    },
    {} as Record<string, Student[]>
  );


  const getQuotaTypeText = (type: 'essay' | 'translation' | 'reading') => {
    switch (type) {
      case 'essay':
        return '作文額度';
      case 'translation':
        return '翻譯額度';
      case 'reading':
        return '閱讀額度';
      default:
        return '未知類型';
    }
  };

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader>
          <div className="flex justify-between items-center">
            <div>
              <CardTitle>額度管理</CardTitle>
              <CardDescription>管理學生的各項功能額度</CardDescription>
            </div>
            <div className="flex items-center space-x-4">
              {/* 班級快速跳轉 */}
              <Select
                value={selectedClass}
                onValueChange={handleClassSelect}
              >
                <SelectTrigger className="w-[200px]">
                  <SelectValue placeholder="選擇班級" />
                </SelectTrigger>
                <SelectContent>
                  {classNames.map((className) => (
                    <SelectItem key={className} value={className}>
                      {className}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              
              {/* 搜尋框 */}
              <div className="relative">
                <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-500" />
                <Input
                  placeholder="搜尋學生..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="pl-8 w-[300px]"
                />
              </div>
            </div>
          </div>
        </CardHeader>
        <CardContent>
          {error && (
            <Alert variant="destructive" className="mb-4">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          {isLoading ? (
            <div className="flex justify-center py-8">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
            </div>
          ) : Object.entries(filteredGroupedStudents).length === 0 ? (
            <div className="text-center py-8 text-gray-500">
              沒有找到符合條件的學生
            </div>
          ) : (
            Object.entries(filteredGroupedStudents).map(([className, students]) => (
              <div key={className} id={`class-${className}`} className="mb-8">
                <h3 className="text-lg font-semibold mb-4">{className}</h3>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>學生姓名</TableHead>
                      <TableHead>作文額度</TableHead>
                      <TableHead>翻譯額度</TableHead>
                      <TableHead>閱讀額度</TableHead>
                      <TableHead className="text-right">操作</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {students.map((student) => (
                      <TableRow key={student._id}>
                        <TableCell className="font-medium">{student.realName}</TableCell>
                        <TableCell>{student.remainingEssays}</TableCell>
                        <TableCell>{student.remainingTranslations}</TableCell>
                        <TableCell>{student.remainingReadings}</TableCell>
                        <TableCell className="text-right">
                          <div className="space-x-2">
                            <Button
                              variant="outline"
                              size="sm"
                              onClick={() => {
                                setSelectedStudent(student);
                                setShowQuotaDialog(true);
                              }}
                              disabled={isLoading}
                            >
                              調整額度
                            </Button>
                            <Button
                              variant="outline"
                              size="sm"
                              onClick={() => {
                                setSelectedStudent(student);
                                fetchQuotaHistory(student._id);
                              }}
                              disabled={isLoading}
                            >
                              查看歷史
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            ))
          )}
        </CardContent>
      </Card>

      {/* 調整額度對話框 */}
      <Dialog open={showQuotaDialog} onOpenChange={() => setShowQuotaDialog(false)}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>調整額度 - {selectedStudent?.realName}</DialogTitle>
          </DialogHeader>
          <div className="space-y-4 py-4">
            <div className="space-y-2">
              <Label>額度類型</Label>
              <Select
                value={quotaType}
                onValueChange={(value: 'essay' | 'translation' | 'reading') => setQuotaType(value)}
              >
                <SelectTrigger>
                  <SelectValue placeholder="選擇額度類型" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="essay">作文額度</SelectItem>
                  <SelectItem value="translation">翻譯額度</SelectItem>
                  <SelectItem value="reading">閱讀額度</SelectItem>
                </SelectContent>
              </Select>
            </div>

            <div className="space-y-2">
              <Label>調整數量</Label>
              <Input
                type="number"
                value={quotaAmount}
                onChange={(e) => setQuotaAmount(parseInt(e.target.value) || 0)}
                placeholder="輸入要增加的額度數量"
              />
            </div>

            <div className="space-y-2">
              <Label>調整原因</Label>
              <Input
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                placeholder="請輸入調整原因"
              />
            </div>
          </div>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setShowQuotaDialog(false)}
              disabled={isLoading}
            >
              取消
            </Button>
            <Button
              onClick={handleUpdateQuota}
              disabled={isLoading || !quotaAmount}
            >
              {isLoading ? '處理中...' : '確認調整'}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* 額度歷史對話框 */}
      <Dialog open={showHistoryDialog} onOpenChange={() => setShowHistoryDialog(false)}>
        <DialogContent className="max-w-3xl">
          <DialogHeader>
            <DialogTitle>額度歷史 - {selectedStudent?.realName}</DialogTitle>
          </DialogHeader>
          <div className="mt-4">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>額度類型</TableHead>
                  <TableHead>調整數量</TableHead>
                  <TableHead>調整原因</TableHead>
                  <TableHead>調整時間</TableHead>
                  <TableHead>操作人</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {quotaHistory.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={5} className="text-center py-4">
                      暫無歷史記錄
                    </TableCell>
                  </TableRow>
                ) : (
                  quotaHistory.map((record, index) => (
                    <TableRow key={index}>
                      <TableCell>{getQuotaTypeText(record.quotaType)}</TableCell>
                      <TableCell className={record.amount > 0 ? 'text-green-600' : 'text-red-600'}>
                        {record.amount > 0 ? `+${record.amount}` : record.amount}
                      </TableCell>
                      <TableCell>{record.reason}</TableCell>
                      <TableCell>{new Date(record.adjustedAt).toLocaleString()}</TableCell>
                      <TableCell>{record.adjustedBy}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TeacherQuotaManagement;