import React, { useState } from 'react';
import { Card, CardContent } from '../../ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../ui/tabs';
import { CorrectionData } from '../../types/correction';

interface ComparisonTabProps {
  data: CorrectionData;
}

interface ErrorItem {
  'Original Sentence': string;
  'Corrected Sentence': string;
  'Reason for Modification': string;
  type?: 'grammar' | 'spelling';
}

interface UpgradeEssay {
  EnhancedEssay: string;
}

const ComparisonTab: React.FC<ComparisonTabProps> = ({ data }) => {
  const [selectedError, setSelectedError] = useState<number | null>(null);

  const getEnhancedEssay = () => {
    return {
      original: data.upgrade_essay_lang1?.EnhancedEssay || null,
      translated: data.lang2aupgrade_essay_lang2?.EnhancedEssayTransla || null
    };
  };

  const enhancedEssay = getEnhancedEssay();

  const processErrors = () => {
    const errorList = data.Suggestion_lang1['Errors and Corrections'];
    const misspelledWords = data.Suggestion_lang1['Misspelled Words'];

    return {
      grammarErrors: errorList.filter((error) => {
        if (!error.type) {
          return !misspelledWords.includes(error['Original Sentence']);
        }
        return error.type === 'grammar';
      }),
      spellingErrors: errorList.filter((error) => {
        return error.type === 'spelling' || misspelledWords.includes(error['Original Sentence']);
      })
    };
  };

  const { grammarErrors, spellingErrors } = processErrors();

  const renderErrorList = (errors: ErrorItem[], type: 'grammar' | 'spelling') => {
    const title = type === 'grammar' ? '文法錯誤' : '拼寫錯誤';
    const emptyMessage = `沒有${title}`;

    return (
      <div className="flex flex-col gap-4">
        <h3 className="text-lg font-medium">{title}</h3>
        {errors.length === 0 ? (
          <p className="text-center text-gray-500 py-4">{emptyMessage}</p>
        ) : (
          <div className="space-y-4">
            {errors.map((error, index) => {
              const errorIndex = data.Suggestion_lang1['Errors and Corrections']
                .findIndex(e => e['Original Sentence'] === error['Original Sentence']);

              return (
                <div
                  key={index}
                  className={`p-3 rounded-lg cursor-pointer border-l-4 transition-all
                    ${selectedError === errorIndex ? 'bg-yellow-50' : 'bg-white'}
                    ${type === 'spelling' ? 'border-red-400' : 'border-blue-400'}`}
                  onClick={() => setSelectedError(errorIndex)}
                  role="button"
                  tabIndex={0}
                >
                  <div className="mb-2">
                    <p className="text-sm text-red-600 font-medium mb-1">
                      {error['Original Sentence']}
                    </p>
                    <p className="text-sm text-green-600">
                      → {error['Corrected Sentence']}
                    </p>
                  </div>
                  <p className="text-xs text-gray-500">
                    {error['Reason for Modification']}
                  </p>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const createMarkedText = (text: string, isOriginal: boolean = true) => {
    if (!text) return '無資料';
    
    let markedText = text;
    const errorList = data.Suggestion_lang1?.['Errors and Corrections'] || [];
    const misspelledWords = data.Suggestion_lang1?.['Misspelled Words'] || [];

    errorList.forEach((error, index) => {
      const searchText = isOriginal ? error['Original Sentence'] : error['Corrected Sentence'];
      if (!searchText) return;

      const errorType = error.type || 
        (misspelledWords.includes(error['Original Sentence']) ? 'spelling' : 'grammar');
      const isHighlighted = index === selectedError;
      
      const className = `
        inline-block px-1 mx-0.5 rounded
        cursor-pointer relative
        border-b-2
        ${isHighlighted ? 'bg-yellow-100' : ''}
        ${isOriginal 
          ? `text-red-600 ${errorType === 'spelling' ? 'border-red-400' : 'border-blue-400'}`
          : 'text-green-600 border-green-400'}
      `;

      markedText = markedText.replace(
        searchText,
        `<span class="${className}" data-error-index="${index}">${searchText}</span>`
      );
    });

    return <div dangerouslySetInnerHTML={{ __html: markedText }} />;
  };

  return (
    <div className="space-y-6">
      <p className="text-sm text-gray-500">
        批改作文說明：可以對照「拼寫錯誤」及「文法錯誤」比對作文，我們將給予作文評分、教師評語以及升級後的作文供參考。
      </p>

      <Tabs defaultValue="normal" className="space-y-6">
        <TabsList>
          <TabsTrigger value="normal">作文對照</TabsTrigger>
          <TabsTrigger value="enhanced">升級版作文</TabsTrigger>
        </TabsList>

        <TabsContent value="normal">
          <div className="grid grid-cols-3 gap-6">
            <div className="col-span-2 grid grid-cols-2 gap-6">
              <div>
                <h3 className="font-medium mb-4">原文</h3>
                <div className="bg-white p-4 rounded-lg border border-gray-200 min-h-[200px]">
                  {createMarkedText(data.article || '', true)}
                </div>
              </div>
              <div>
                <h3 className="font-medium mb-4">修改後</h3>
                <div className="bg-white p-4 rounded-lg border border-gray-200 min-h-[200px]">
                  {createMarkedText(data.article_FIX?.['After Revision'] || '', false)}
                </div>
              </div>
            </div>
            <div>
              <Tabs defaultValue="grammar">
                <TabsList>
                  <TabsTrigger value="grammar">文法錯誤</TabsTrigger>
                  <TabsTrigger value="spelling">拼寫錯誤</TabsTrigger>
                </TabsList>
                <TabsContent value="grammar">
                  {renderErrorList(grammarErrors, 'grammar')}
                </TabsContent>
                <TabsContent value="spelling">
                  {renderErrorList(spellingErrors, 'spelling')}
                </TabsContent>
              </Tabs>
            </div>
          </div>
        </TabsContent>

        <TabsContent value="enhanced">
          <div className="grid grid-cols-1 gap-6">
            <div className="grid grid-cols-2 gap-6">
              <div>
                <h3 className="font-medium mb-4">原文</h3>
                <div className="bg-white p-4 rounded-lg border border-gray-200 whitespace-pre-wrap text-sm leading-relaxed">
                  {data.article}
                </div>
              </div>
              <div>
                <h3 className="font-medium mb-4">升級版作文</h3>
                <div className="bg-white p-4 rounded-lg border border-gray-200 whitespace-pre-wrap text-sm leading-relaxed">
                  {enhancedEssay.original || '無升級版作文'}
                </div>
              </div>
            </div>

            {enhancedEssay.translated && (
              <div>
                <h3 className="font-medium mb-4">升級版作文翻譯</h3>
                <div className="bg-white p-4 rounded-lg border border-gray-200 whitespace-pre-wrap text-sm leading-relaxed">
                  {enhancedEssay.translated}
                </div>
              </div>
            )}

            <div className="bg-blue-50 p-4 rounded-lg">
              <p className="text-sm text-blue-600">
                此版本是由 AI 輔助產生的升級版本，提供更精確和流暢的表達方式供參考。
              </p>
            </div>
          </div>
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default ComparisonTab;