import * as React from "react";
import { X } from "lucide-react";
import { cn } from "../../lib/utils";

export interface ToastNewProps {
  title?: string;
  description?: string;
  action?: React.ReactNode;
  variant?: "default" | "destructive";
  onClose?: () => void;
}

export function ToastNew({
  title,
  description,
  action,
  variant = "default",
  onClose,
}: ToastNewProps) {
  return (
    <div
      className={cn(
        "relative w-full rounded-lg border p-4 pr-8 shadow-lg",
        variant === "destructive"
          ? "border-red-200 bg-red-50 text-red-900"
          : "border-gray-200 bg-white text-gray-900"
      )}
    >
      {onClose && (
        <button
          onClick={onClose}
          className="absolute right-2 top-2 rounded-md p-1 text-gray-500 hover:text-gray-900"
        >
          <X className="h-4 w-4" />
        </button>
      )}
      {title && <div className="font-semibold">{title}</div>}
      {description && <div className="mt-1 text-sm">{description}</div>}
      {action && <div className="mt-2">{action}</div>}
    </div>
  );
} 