import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import { Button } from '../ui/button';
import { Badge } from '../ui/badge';
import { Input } from '../ui/input';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../ui/table';
import { Eye, Search, Clock, Trash2 } from 'lucide-react';
import { Alert, AlertDescription } from '../ui/alert';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '../ui/dialog';
import { DayPicker } from 'react-day-picker';
import { zhTW } from 'date-fns/locale';
import 'react-day-picker/dist/style.css';

interface Translation {
  _id: string;
  question: string;
  answer: string;
  questionNumber: number;
}

interface Test {
  _id: string;
  examType: string;
  year: number;
  round: number;
  title: string;
  description?: string;
  translations: Translation[];
  type: '翻譯+作文' | '純作文';
  targetClass: string;
  createdAt: string;
  deadline: string;
  canDelete: boolean;
  submissionCount: number;
  writing: {
    description: string;
    title: string;
    imageDescription?: string;
    image?: {
      data: string;
      contentType: string;
    };
  };
}

const TestHistory: React.FC = () => {
  const [tests, setTests] = useState<Test[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTest, setSelectedTest] = useState<Test | null>(null);
  const [showDeadlineModal, setShowDeadlineModal] = useState(false);
  const [selectedTestId, setSelectedTestId] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);
  const [selectedTests, setSelectedTests] = useState<Set<string>>(new Set());
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showBatchDeleteConfirm, setShowBatchDeleteConfirm] = useState(false);

  useEffect(() => {
    fetchTestHistory();
  }, []);

  const fetchTestHistory = async () => {
    try {
      const response = await fetch('/api/test/history', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch test history');
      }

      const data = await response.json();
      if (data.success) {
        setTests(data.history);
      } else {
        throw new Error(data.message || '獲取出題紀錄失敗');
      }
    } catch (error) {
      setError(error instanceof Error ? error.message : '獲取出題紀錄失敗');
    } finally {
      setLoading(false);
    }
  };

  const deleteTest = async (testId: string) => {
    try {
      const response = await fetch(`/api/test/assignments/${testId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || '刪除題目失敗');
      }
  
      const data = await response.json();
      if (data.success) {
        await fetchTestHistory();
        setError('');
        setShowDeleteConfirm(false);
      }
    } catch (error) {
      setError(error instanceof Error ? error.message : '刪除題目失敗');
    }
  };

  const batchDeleteTests = async () => {
    try {
      const response = await fetch('/api/test/assignments/batch-delete', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          assignmentIds: Array.from(selectedTests)
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || '批量刪除失敗');
      }

      const data = await response.json();
      if (data.success) {
        await fetchTestHistory();
        setSelectedTests(new Set());
        setShowBatchDeleteConfirm(false);
        setError('');
      }
    } catch (error) {
      setError(error instanceof Error ? error.message : '批量刪除失敗');
    }
  };

  const handleDateSelect = (date: Date | undefined) => {
    if (date) {
      const currentTime = selectedDate || new Date();
      const newDate = new Date(date);
      newDate.setHours(currentTime.getHours());
      newDate.setMinutes(currentTime.getMinutes());
      setSelectedDate(newDate);
    }
  };

  const updateDeadline = async (testId: string, newDeadline: Date) => {
    try {
      if (!newDeadline) {
        setError('請選擇有效的截止日期');
        return;
      }

      const response = await fetch(`/api/test/assignments/${testId}/deadline`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ deadline: newDeadline.toISOString() })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || '更新截止時間失敗');
      }

      const data = await response.json();
      if (data.success) {
        await fetchTestHistory();
        setShowDeadlineModal(false);
        setError('');
      }
    } catch (error) {
      setError(error instanceof Error ? error.message : '更新截止時間失敗');
    }
  };

  const filteredTests = tests.filter(test => 
    ((test.writing?.title?.toLowerCase() || '').includes(searchTerm.toLowerCase())) ||
    ((test.targetClass || '').toLowerCase().includes(searchTerm.toLowerCase())) ||
    ((test.type || '').includes(searchTerm))
  );
  

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertDescription>{error}</AlertDescription>
      </Alert>
    );
  }
  return (
    <div className="space-y-6">
      <Card>
        <CardHeader>
          <div className="flex justify-between items-center">
            <CardTitle>出題紀錄</CardTitle>
            <div className="w-64">
              <div className="relative">
                <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-500" />
                <Input
                  placeholder="搜尋題目..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="pl-8"
                />
              </div>
            </div>
          </div>
        </CardHeader>
        <CardContent>
          {filteredTests.length > 0 ? (
            <div className="space-y-4">
              {selectedTests.size > 0 && (
                <div className="flex items-center justify-between bg-gray-50 p-2 rounded-md">
                  <span>已選擇 {selectedTests.size} 個題目</span>
                  <Button
                    variant="outline"
                    size="sm"
                    className="text-red-500 hover:text-red-700"
                    onClick={() => setShowBatchDeleteConfirm(true)}
                  >
                    <Trash2 className="h-4 w-4 mr-2" />
                    批量刪除
                  </Button>
                </div>
              )}
              
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead className="w-[30px]">
                      <input
                        type="checkbox"
                        className="h-4 w-4"
                        checked={selectedTests.size === filteredTests.filter(t => t.canDelete).length}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedTests(new Set(filteredTests.filter(t => t.canDelete).map(t => t._id)));
                          } else {
                            setSelectedTests(new Set());
                          }
                        }}
                      />
                    </TableHead>
                    <TableHead>題目名稱</TableHead>
                    <TableHead>類型</TableHead>
                    <TableHead>指派班級</TableHead>
                    <TableHead>出題時間</TableHead>
                    <TableHead>截止時間</TableHead>
                    <TableHead>提交數量</TableHead>
                    <TableHead className="text-right">操作</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {filteredTests.map(test => (
                    <TableRow key={test._id}>
                      <TableCell>
                        {test.canDelete && (
                          <input
                            type="checkbox"
                            className="h-4 w-4"
                            checked={selectedTests.has(test._id)}
                            onChange={(e) => {
                              const newSelected = new Set(selectedTests);
                              if (e.target.checked) {
                                newSelected.add(test._id);
                              } else {
                                newSelected.delete(test._id);
                              }
                              setSelectedTests(newSelected);
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell className="font-medium max-w-md truncate">
                        {test.writing?.title || test.title || '未提供標題'}
                      </TableCell>
                      <TableCell>
                        <Badge variant="secondary">
                          {test.type}
                        </Badge>
                      </TableCell>
                      <TableCell>{test.targetClass}</TableCell>
                      <TableCell>
                        {new Date(test.createdAt).toLocaleString('zh-TW')}
                      </TableCell>
                      <TableCell>
                        {new Date(test.deadline).toLocaleString('zh-TW')}
                      </TableCell>
                      <TableCell>
                        <Badge variant={test.submissionCount > 0 ? "default" : "secondary"}>
                          {test.submissionCount}
                        </Badge>
                      </TableCell>
                      <TableCell className="text-right space-x-2">
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => {
                            setSelectedTestId(test._id);
                            setSelectedDate(new Date(test.deadline));
                            setShowDeadlineModal(true);
                          }}
                        >
                          <Clock className="h-4 w-4 mr-2" />
                          修改期限
                        </Button>
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => setSelectedTest(test)}
                        >
                          <Eye className="h-4 w-4 mr-2" />
                          查看詳情
                        </Button>
                        {test.canDelete && (
                          <Button
                            variant="outline"
                            size="sm"
                            className="text-red-500 hover:text-red-700"
                            onClick={() => {
                              setSelectedTestId(test._id);
                              setShowDeleteConfirm(true);
                            }}
                          >
                            <Trash2 className="h-4 w-4 mr-2" />
                            刪除
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              {showDeleteConfirm && (
                <Dialog open={showDeleteConfirm} onOpenChange={setShowDeleteConfirm}>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>確認刪除</DialogTitle>
                    </DialogHeader>
                    <div>確定要刪除這個題目嗎？此操作無法復原。</div>
                    <DialogFooter>
                      <Button 
                        variant="outline" 
                        onClick={() => setShowDeleteConfirm(false)}
                      >
                        取消
                      </Button>
                      <Button
                        variant="outline"
                        className="text-red-500 hover:text-red-700"
                        onClick={() => selectedTestId && deleteTest(selectedTestId)}
                      >
                        確認刪除
                      </Button>
                    </DialogFooter>
                  </DialogContent>
                </Dialog>
              )}

              {showBatchDeleteConfirm && (
                <Dialog open={showBatchDeleteConfirm} onOpenChange={setShowBatchDeleteConfirm}>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>確認批量刪除</DialogTitle>
                    </DialogHeader>
                    <div>確定要刪除選中的 {selectedTests.size} 個題目嗎？此操作無法復原。</div>
                    <DialogFooter>
                      <Button 
                        variant="outline" 
                        onClick={() => setShowBatchDeleteConfirm(false)}
                      >
                        取消
                      </Button>
                      <Button
                        variant="outline"
                        className="text-red-500 hover:text-red-700"
                        onClick={batchDeleteTests}
                      >
                        確認批量刪除
                      </Button>
                    </DialogFooter>
                  </DialogContent>
                </Dialog>
              )}

              {showDeadlineModal && (
               <Dialog open={showDeadlineModal} onOpenChange={setShowDeadlineModal}>
                 <DialogContent>
                   <DialogHeader>
                     <DialogTitle>修改截止時間</DialogTitle>
                   </DialogHeader>
                   <div className="py-4">
                     <DayPicker
                       mode="single"
                       selected={selectedDate}
                       onSelect={handleDateSelect}
                       disabled={(date: Date) => {
                         const today = new Date();
                         today.setHours(0, 0, 0, 0);
                         return date < today;
                       }}
                       locale={zhTW}
                       className="border rounded-md p-3"
                       fromDate={new Date()}
                       captionLayout="dropdown"
                       weekStartsOn={1}
                     />
                   </div>
                   <DialogFooter>
                     <Button
                       variant="outline"
                       onClick={() => setShowDeadlineModal(false)}
                     >
                       取消
                     </Button>
                     <Button
                       onClick={() => {
                         if (selectedDate && selectedTestId) {
                           updateDeadline(selectedTestId, selectedDate);
                         }
                       }}
                     >
                       確認修改
                     </Button>
                   </DialogFooter>
                 </DialogContent>
               </Dialog>
              )}

              {selectedTest && (
                <Card className="mt-4">
                  <CardHeader>
                    <div className="flex justify-between items-center">
                      <CardTitle>題目詳情</CardTitle>
                      <Button 
                        variant="outline" 
                        size="sm"
                        onClick={() => setSelectedTest(null)}
                      >
                        關閉
                      </Button>
                    </div>
                  </CardHeader>
                  <CardContent>
                    <div className="space-y-4">
                      {selectedTest.translations?.length > 0 && (
                        <div>
                          <h3 className="text-lg font-semibold mb-2">翻譯題目</h3>
                          <div className="space-y-4">
                            {selectedTest.translations.map((translation, index) => (
                              <div key={translation._id} className="bg-gray-50 p-4 rounded-md">
                                <div className="font-medium mb-2">題目 {index + 1}</div>
                                <div className="space-y-2">
                                  <p>中文：{translation.question}</p>
                                  <p>英文：{translation.answer}</p>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}

                      <div>
                        <h3 className="text-lg font-semibold mb-2">作文題目</h3>
                        <div className="bg-gray-50 p-4 rounded-md">
                          <div className="space-y-2">
                            <p className="font-medium">題目標題：{selectedTest.writing?.title}</p>
                            <p>題目內容：{selectedTest.writing?.description}</p>
                            {selectedTest.writing?.image && (
                              <div className="space-y-2">
                                <img
                                  src={`data:${selectedTest.writing.image.contentType};base64,${selectedTest.writing.image.data}`}
                                  alt="作文題目圖片"
                                  className="max-w-full h-auto mt-2 rounded-md"
                                />
                                {selectedTest.writing.imageDescription && (
                                  <div className="mt-2 p-4 bg-gray-100 rounded-md">
                                    <p className="font-medium">圖片描述：</p>
                                    <p className="mt-1 text-gray-600">
                                      {selectedTest.writing.imageDescription}
                                    </p>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="text-sm text-gray-500">
                        <p>指派班級：{selectedTest.targetClass}</p>
                        <p>出題時間：{new Date(selectedTest.createdAt).toLocaleString('zh-TW')}</p>
                        <p>截止時間：{new Date(selectedTest.deadline).toLocaleString('zh-TW')}</p>
                        <p>提交數量：{selectedTest.submissionCount}</p>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              )}
            </div>
          ) : (
            <div className="text-center py-8 text-gray-500">
              暫無出題紀錄
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default TestHistory;