// src/components/test-creation/SelectMockType.tsx
import React from 'react';
import { Card, CardContent } from '../ui/card';
import { Button } from '../ui/button';

  interface MockType {
    id: string;
    title: string;
    description: string;
  }
  
  const SelectMockType: React.FC<{ onSelect: (type: string) => void; onBack: () => void }> = ({ onSelect, onBack }) => {
    const mockTypes: MockType[] = [
      {
        id: 'north',
        title: '北區模考',
        description: '北區高中聯合模擬考試'
      },
      {
        id: 'central',
        title: '中區模考',
        description: '中區高中聯合模擬考試'
      }
    ];
  
    return (
      <div className="space-y-6">
        <div className="grid grid-cols-2 gap-4">
          {mockTypes.map(type => (
            <Card
              key={type.id}
              className="cursor-pointer hover:shadow-lg transition-all"
              onClick={() => onSelect(type.id)}
            >
              <CardContent className="pt-6">
                <h3 className="text-lg font-semibold mb-2">{type.title}</h3>
                <p className="text-sm text-gray-600">{type.description}</p>
              </CardContent>
            </Card>
          ))}
        </div>
        <div className="flex justify-start">
          <Button variant="outline" onClick={onBack}>返回</Button>
        </div>
      </div>
    );
  };

  export default SelectMockType;