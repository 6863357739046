// src/components/correction/tabs/StructureTab.tsx
import React from 'react';
import { Check, X } from 'lucide-react';
import { Card, CardContent } from '../../ui/card';
import { CorrectionData } from '../../types/correction';

interface StructureTabProps {
  data: CorrectionData;
}

type SectionType = 'Introduction' | 'Body Paragraph' | 'Conclusion';
type TranslatedSectionType = 
  | 'Introduction (translated)' 
  | 'Body Paragraph (translated)' 
  | 'Conclusion (translated)';

type IntroductionKeys = 'Hook' | 'Background Information' | 'Thesis Statement';
type BodyParagraphKeys = 'Topic Sentence' | 'Supporting Details' | 'Explanation and Analysis' | 'Transition';
type ConclusionKeys = 'Restate the Thesis' | 'Summary' | 'Final Thought';

type StructureKeys = IntroductionKeys | BodyParagraphKeys | ConclusionKeys;

interface StructureElement {
  key: StructureKeys;
  name: string;
  section: SectionType;
  order: number;
}

interface SectionContent {
  Hook?: string;
  "Background Information"?: string;
  "Thesis Statement"?: string;
  "Topic Sentence"?: string;
  "Supporting Details"?: string;
  "Explanation and Analysis"?: string;
  Transition?: string;
  "Restate the Thesis"?: string;
  Summary?: string;
  "Final Thought"?: string;
  "Missing Elements"?: string[];
}

interface StructureSection {
  Introduction: SectionContent;
  "Body Paragraph": SectionContent;
  Conclusion: SectionContent;
}

const StructureTab: React.FC<StructureTabProps> = ({ data }) => {
  const structureElements: StructureElement[] = [
    { key: 'Hook', name: 'Hook', section: 'Introduction', order: 1 },
    { key: 'Background Information', name: 'Background Information', section: 'Introduction', order: 2 },
    { key: 'Thesis Statement', name: 'Thesis Statement', section: 'Introduction', order: 3 },
    { key: 'Topic Sentence', name: 'Topic Sentence', section: 'Body Paragraph', order: 4 },
    { key: 'Supporting Details', name: 'Supporting Details', section: 'Body Paragraph', order: 5 },
    { key: 'Explanation and Analysis', name: 'Explanation and Analysis', section: 'Body Paragraph', order: 6 },
    { key: 'Transition', name: 'Transition', section: 'Body Paragraph', order: 7 },
    { key: 'Restate the Thesis', name: 'Restate the Thesis', section: 'Conclusion', order: 8 },
    { key: 'Summary', name: 'Summary', section: 'Conclusion', order: 9 },
    { key: 'Final Thought', name: 'Final Thought', section: 'Conclusion', order: 10 }
  ];

  const isElementPresent = (section: SectionType, elementKey: StructureKeys): boolean => {
    const sectionData = data.StructureDivisionAgent_lang1[section] as SectionContent;
    
    if (!sectionData || !(elementKey in sectionData)) {
      return false;
    }

    const elementValue = sectionData[elementKey];
    if (!elementValue || elementValue === '<Text or indication if missing>') {
      return false;
    }

    const missingElements = sectionData['Missing Elements'] || [];
    return !missingElements.includes(elementKey);
  };

  const getTranslatedSectionKey = (section: SectionType): TranslatedSectionType => {
    return `${section} (translated)` as TranslatedSectionType;
  };

  const getTranslatedText = (section: SectionType, key: StructureKeys): string => {
    const translatedSection = getTranslatedSectionKey(section);
    const sectionData = data.StructureDivisionAgent_lang2[translatedSection] as SectionContent;
    return sectionData?.[key] || '';
  };

  const renderStructureIndicator = () => {
    const sectionElements = {
      Introduction: structureElements.filter(el => el.section === 'Introduction'),
      'Body Paragraph': structureElements.filter(el => el.section === 'Body Paragraph'),
      Conclusion: structureElements.filter(el => el.section === 'Conclusion')
    };

    let presentCount = 0;
    const totalElements = structureElements.length;


    return (
      <div className="rounded-lg mb-8">
        {/* Section Headers */}
        <div className="grid grid-cols-11 gap-0">
          <div className="col-span-4 bg-blue-500 py-2 rounded-tl-lg">
            <h3 className="text-center text-white font-medium">引言 Introduction</h3>
          </div>
          <div className="col-span-4 bg-indigo-500 py-2">
            <h3 className="text-center text-white font-medium">主體段落 Body Paragraph</h3>
          </div>
          <div className="col-span-2 bg-violet-500 py-2">
            <h3 className="text-center text-white font-medium">結論 Conclusion</h3>
          </div>
          <div className="col-span-1 bg-gray-600 py-2 rounded-tr-lg">
            <h3 className="text-center text-white font-medium">完整度</h3>
          </div>
        </div>

        {/* Status Indicators */}
        <div className="grid grid-cols-11 gap-0">
          {/* Introduction Section */}
          <div className="col-span-4 grid grid-cols-3 gap-0">
            {sectionElements.Introduction.map((element, index) => {
              const isPresent = isElementPresent(element.section, element.key);
              if (isPresent) presentCount++;
              return (
                <div
                  key={element.key}
                  className="bg-blue-50 p-4 flex flex-col items-center justify-center gap-2 border-r border-white"
                >
                  <div className="w-8 h-8 rounded-full bg-white flex items-center justify-center shadow-sm">
                    <span className="text-sm font-medium text-blue-500">{index + 1}</span>
                  </div>
                  {isPresent ? (
                    <div className="w-6 h-6 rounded-full bg-emerald-100 flex items-center justify-center">
                      <Check className="w-4 h-4 text-emerald-600" />
                    </div>
                  ) : (
                    <div className="w-6 h-6 rounded-full bg-red-100 flex items-center justify-center">
                      <X className="w-4 h-4 text-red-600" />
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          {/* Body Paragraph Section */}
          <div className="col-span-4 grid grid-cols-4 gap-0">
            {sectionElements['Body Paragraph'].map((element, index) => {
              const isPresent = isElementPresent(element.section, element.key);
              if (isPresent) presentCount++;
              return (
                <div
                  key={element.key}
                  className="bg-indigo-50 p-4 flex flex-col items-center justify-center gap-2 border-r border-white"
                >
                  <div className="w-8 h-8 rounded-full bg-white flex items-center justify-center shadow-sm">
                    <span className="text-sm font-medium text-indigo-500">{index + 4}</span>
                  </div>
                  {isPresent ? (
                    <div className="w-6 h-6 rounded-full bg-emerald-100 flex items-center justify-center">
                      <Check className="w-4 h-4 text-emerald-600" />
                    </div>
                  ) : (
                    <div className="w-6 h-6 rounded-full bg-red-100 flex items-center justify-center">
                      <X className="w-4 h-4 text-red-600" />
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          {/* Conclusion Section */}
          <div className="col-span-2 grid grid-cols-3 gap-0">
            {sectionElements.Conclusion.map((element, index) => {
              const isPresent = isElementPresent(element.section, element.key);
              if (isPresent) presentCount++;
              return (
                <div
                  key={element.key}
                  className="bg-violet-50 p-4 flex flex-col items-center justify-center gap-2 border-r border-white"
                >
                  <div className="w-8 h-8 rounded-full bg-white flex items-center justify-center shadow-sm">
                    <span className="text-sm font-medium text-violet-500">{index + 8}</span>
                  </div>
                  {isPresent ? (
                    <div className="w-6 h-6 rounded-full bg-emerald-100 flex items-center justify-center">
                      <Check className="w-4 h-4 text-emerald-600" />
                    </div>
                  ) : (
                    <div className="w-6 h-6 rounded-full bg-red-100 flex items-center justify-center">
                      <X className="w-4 h-4 text-red-600" />
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          {/* Completion Rate */}
          <div className="col-span-1 bg-gray-50 p-4 flex flex-col items-center justify-center border-l border-white">
            <div className="text-2xl font-bold text-gray-700">
              {Math.round((presentCount / totalElements) * 100)}%
            </div>
          </div>
        </div>

        {/* Element Names */}
        <div className="grid grid-cols-11 gap-0">
          <div className="col-span-4 grid grid-cols-3 gap-0">
            {sectionElements.Introduction.map(element => (
              <div key={element.key} className="p-2 text-center bg-blue-50/50">
                <span className="text-xs text-blue-700">{element.name}</span>
              </div>
            ))}
          </div>
          <div className="col-span-4 grid grid-cols-4 gap-0">
            {sectionElements['Body Paragraph'].map(element => (
              <div key={element.key} className="p-2 text-center bg-indigo-50/50">
                <span className="text-xs text-indigo-700">{element.name}</span>
              </div>
            ))}
          </div>
          <div className="col-span-2 grid grid-cols-3 gap-0">
            {sectionElements.Conclusion.map(element => (
              <div key={element.key} className="p-2 text-center bg-violet-50/50">
                <span className="text-xs text-violet-700">{element.name}</span>
              </div>
            ))}
          </div>
          <div className="col-span-1"></div>
        </div>
      </div>
    );
  };
  const renderSection = (section: SectionType, title: string) => {
    const sectionData = data.StructureDivisionAgent_lang1[section] as SectionContent;
    const upgradedData = data.upgrade_essay_lang1?.EnhancedEssay;
    
    if (!sectionData) return null;

    const sectionElements = structureElements
      .filter(element => element.section === section)
      .sort((a, b) => a.order - b.order);

    return (
      <div>
        <h3 className="font-bold text-lg mb-4">{title}</h3>
        <div className="space-y-4 pl-4">
          {sectionElements.map(element => {
            const value = sectionData[element.key];
            const missingElements = sectionData['Missing Elements'] || [];
            const isMissing = missingElements.includes(element.key) || !value;

            return (
              <div key={element.key}>
                <h4 className="font-medium mb-2">【{element.name}】</h4>
                <div className={`p-4 rounded-lg ${isMissing ? 'bg-red-50' : 'bg-gray-50'}`}>
                  {isMissing ? (
                    <div className="space-y-2">
                      <p className="text-red-500">缺少該結構</p>
                      {upgradedData && (
                        <div className="mt-2 p-3 bg-blue-50 rounded">
                          <p className="text-blue-700 font-medium">根據升級版作為給予結構建議：</p>
                          <p className="mt-1">{upgradedData}</p>
                        </div>
                      )}
                    </div>
                  ) : (
                    <>
                      <p className="mb-2">{value}</p>
                      <p className="text-gray-600">
                        {getTranslatedText(section, element.key)}
                      </p>
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <Card>
      <CardContent className="p-6">
        <h2 className="text-xl font-bold mb-6">作文結構分析</h2>
        {renderStructureIndicator()}
        <div className="space-y-8">
          {renderSection('Introduction', 'I. 引言 Introduction')}
          {renderSection('Body Paragraph', 'II. 主體段落 Body Paragraphs')}
          {renderSection('Conclusion', 'III. 結論 Conclusion')}
        </div>
      </CardContent>
    </Card>
  );
};

export default StructureTab;