// src/components/students/student-assignment.tsx
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, CardContent } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { Textarea } from '../components/ui/textarea';
import { Label } from '../components/ui/label';
import { Camera, Loader2, ChevronLeft, ChevronRight } from 'lucide-react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../components/ui/select';
import { Alert, AlertDescription } from '../components/ui/alert';
import Header from '../components/Header';
import { CorrectionData } from '../components/types/correction';

// Types
interface StudentAssignmentProps {
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  username: string;
}

interface ImageData {
  data: string;
  contentType: string;
}

interface WritingData {
  description: string;
  title: string;
  imageDescription?: string;
  image?: ImageData;
}

interface Assignment {
  id: string;
  title: string;
  testType: 'translation' | 'writing' | 'translation-writing' | 'english-writing' ;
  examType?: string;
  year?: number;
  round?: number;
  translationQuestions?: Array<{
    id: string;
    text: string;
    questionNumber: number;
  }>;
  writing?: {
    description: string;
    title: string;
    imageDescription?: string;
    image?: {
      data: string;
      contentType: string;
    };
  };
  deadline: string;
  status: string;
}

// 基本圖片介面
interface FormImage {
  file: File;
  preview: string;
  ocrText: string;
}

// API 提交用的介面
interface APITranslationSubmission {
  assignmentId: string;
  translations: Array<{
    questionId: string;
    answer: string;
  }>;
  lang: string;
}

interface APIWritingSubmission {
  assignmentId: string;
  content: string;
  lang: string;
  pic_description?: string;
}

// 表單數據介面
interface TranslationSubmission {
  questionId: string;
  answer: string;
  images?: FormImage[];
  currentImageIndex?: number;
}

interface WritingSubmission {
  content: string;
  lang: string;
  images: FormImage[];
}

// 完整表單數據介面
interface FormData {
  translations: TranslationSubmission[];
  writing: WritingSubmission;
}

// API 響應介面
interface SubmissionResponse {
  success: boolean;
  message: string;
  submissionId: string;
}

interface TranslationOutput {
  basic_voc: Array<{
    CEFR: string;
    kk_phonetic: string;
    part_of_speech: string;
    translation: string;
    word: string;
  }>;
  lang1: {
    CEFRLevel: string;
    Conclusion: string;
    ContentTheme: string;
    IntentandExpression: string;
    SyntaxStructure: string;
    VocabularyRange: string;
  };
  lang2: {
    CEFRLevel: string;
    Conclusion: string;
    ContentTheme: string;
    IntentandExpression: string;
    SyntaxStructure: string;
    VocabularyRange: string;
  };
  output: {
    Elegance: {
      Examples: string[];
      Feedback: string;
    };
    Expressiveness: {
      Examples: string[];
      Feedback: string;
      Score: string;
    };
    Faithfulness: {
      Examples: string[];
      Feedback: string;
      Score: string;
    };
    OverallEvaluation: {
      Explanation: string;
      Rating: string;
      Suggestions: string[];
    };
  };
}

interface SubmissionResult {
  id: string;
  type: 'translation' | 'writing' | 'translation-writing';
  status: 'pending' | 'completed';
  translationResults?: Array<{
    questionId: string;
    result: TranslationOutput;
  }>;
  writingResult?: CorrectionData;
  createdAt: string;
  updatedAt: string;
}

// API Endpoints
const API_ENDPOINTS = {
  TRANSLATION: '/api/essay/submit/translation',
  WRITING: '/api/essay/submit/writing',
  GET_RESULT: '/api/essay/submission'
};

// API Functions
const submitTranslation = async (data: APITranslationSubmission): Promise<SubmissionResponse> => {
  console.log('Calling translation API with data:', {
    assignmentId: data.assignmentId,
    translationsCount: data.translations.length,
    hasLang: Boolean(data.lang),
  });

  const response = await fetch(API_ENDPOINTS.TRANSLATION, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });

  console.log('Translation API response status:', response.status);

  if (!response.ok) {
    throw new Error('翻譯提交失敗');
  }

  const result = await response.json();
  console.log('Translation API response:', result);
  return result;
};

const submitWriting = async (data: APIWritingSubmission): Promise<SubmissionResponse> => {
  console.log('Calling writing API with data:', {
    assignmentId: data.assignmentId,
    contentLength: data.content.length,
    hasLang: Boolean(data.lang),
    hasPicDescription: Boolean(data.pic_description)
  });

  const response = await fetch(API_ENDPOINTS.WRITING, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });

  console.log('Writing API response status:', response.status);

  if (!response.ok) {
    throw new Error('作文提交失敗');
  }

  const result = await response.json();
  console.log('Writing API response:', result);
  return result;
};

const getImageSrc = (image?: ImageData): string | undefined => {
  if (!image?.data) return undefined;
  
  try {
    return `data:image/${image.contentType};base64,${image.data}`;
  } catch (error) {
    console.error('Error processing image:', error);
    return undefined;
  }
};

const getSubmissionResult = async (
  submissionId: string
): Promise<SubmissionResult> => {
  const response = await fetch(`${API_ENDPOINTS.GET_RESULT}/${submissionId}`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  });

  if (!response.ok) {
    throw new Error('獲取結果失敗');
  }

  const data = await response.json();
  return data.submission;
};

const fetchAssignmentStatus = async (assignmentId: string) => {
  const response = await fetch(`/api/student/assignment-status/${assignmentId}`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  });
  
  if (!response.ok) {
    throw new Error('獲取作業狀態失敗');
  }
  
  const data = await response.json();
  return data.status;
};

// Component
const StudentAssignment: React.FC<StudentAssignmentProps> = ({
  setIsAuthenticated,
  username
}) => {
  const [assignment, setAssignment] = useState<Assignment | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>('');
  const [successMessage, setSuccessMessage] = useState('');
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { assignmentId } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState<FormData>({
    translations: [],
    writing: {
      content: '',
      lang: '',
      images: []
    }
  });

  useEffect(() => {
    const fetchAssignment = async () => {
      try {
        const response = await fetch(`/api/test/assignments/${assignmentId}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        const data = await response.json();
        console.log('Fetched assignment data:', data);
        
        if (data.success) {
          setAssignment(data.assignment);
          console.log('Assignment details:', {
            testType: data.assignment.testType,
            writing: data.assignment.writing,
            image: data.assignment?.writing?.image
          });
        } else {
          setError(data.message || '獲取作業失敗');
        }
      } catch (error) {
        console.error('Error fetching assignment:', error);
        setError('獲取作業失敗');
      } finally {
        setLoading(false);
      }
    };

    if (assignmentId) {
      fetchAssignment();
    }
  }, [assignmentId]);

  useEffect(() => {
    const questions = assignment?.translationQuestions || [];
    setFormData(prev => ({
      ...prev,
      translations: questions.map(q => ({
        questionId: q.id,
        answer: '',
        images: [],
        currentImageIndex: 0
      }))
    }));
  }, [assignment]);

  const handleImageUpload = async (
    e: React.ChangeEvent<HTMLInputElement>,
    type: 'writing' | 'translation',
    translationIndex?: number
  ) => {
    const file = e.target.files?.[0];
    if (!file) return;

    try {
      setLoading(true);
      const preview = URL.createObjectURL(file);

      // 將圖片轉換為 base64
      const base64 = await new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result?.toString() || '';
          resolve(base64String);
        };
        reader.readAsDataURL(file);
      });

      // OCR 處理
      const ocrResponse = await fetch('/api/ocr', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ image: base64.split(',')[1] })
      });

      if (!ocrResponse.ok) {
        throw new Error('OCR 處理失敗');
      }

      const ocrData = await ocrResponse.json();

      // 創建新的圖片物件
      const newImage: FormImage = {
        file,
        preview,
        ocrText: ocrData.text
      };

      // 更新表單數據，包括 OCR 文字
      setFormData(prev => {
        if (type === 'writing') {
          // 將 OCR 文字添加到作文內容中
          const newContent = prev.writing.content 
            ? `${prev.writing.content}\n\n${ocrData.text}` 
            : ocrData.text;

          return {
            ...prev,
            writing: {
              ...prev.writing,
              content: newContent,
              images: [...prev.writing.images, newImage]
            }
          };
        } else if (type === 'translation' && typeof translationIndex === 'number') {
          const newTranslations = [...prev.translations];
          const currentTranslation = newTranslations[translationIndex];
          
          if (currentTranslation) {
            // 將 OCR 文字添加到翻譯內容中
            const newAnswer = currentTranslation.answer 
              ? `${currentTranslation.answer}\n\n${ocrData.text}`
              : ocrData.text;

            newTranslations[translationIndex] = {
              ...currentTranslation,
              answer: newAnswer,
              images: [...(currentTranslation.images || []), newImage]
            };
          }
          
          return {
            ...prev,
            translations: newTranslations
          };
        }
        return prev;
      });

    } catch (error) {
      console.error('圖片上傳失敗:', error);
      setError(error instanceof Error ? error.message : '圖片上傳失敗');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      setLoading(true);
      setError(null);

      // 處理作文圖片
      const processedWritingImages = await Promise.all(
        formData.writing.images.map(async (image: FormImage) => {
          const base64 = await new Promise<string>((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              const base64String = reader.result?.toString() || '';
              resolve(base64String);
            };
            reader.readAsDataURL(image.file);
          });

          return {
            data: base64.split(',')[1], // 移除 data:image/jpeg;base64, 前綴
            contentType: image.file.type,
            ocrText: image.ocrText
          };
        })
      );

      // 處理翻譯圖片
      const processedTranslations = await Promise.all(
        formData.translations.map(async (translation: TranslationSubmission) => {
          const processedImages = await Promise.all(
            (translation.images || []).map(async (image: FormImage) => {
              const base64 = await new Promise<string>((resolve) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                  const base64String = reader.result?.toString() || '';
                  resolve(base64String);
                };
                reader.readAsDataURL(image.file);
              });

              return {
                data: base64.split(',')[1],
                contentType: image.file.type,
                ocrText: image.ocrText
              };
            })
          );

          return {
            questionId: translation.questionId,
            answer: translation.answer,
            images: processedImages
          };
        })
      );

      // 準備提交數據
      const submitData = {
        assignmentId: assignment?.id,
        type: assignment?.testType,
        writing: {
          content: formData.writing.content,
          images: processedWritingImages
        },
        translations: processedTranslations,
        lang: formData.writing.lang
      };

      // 發送到後端
      const response = await fetch('/api/essay/submit/combined', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(submitData)
      });

      const data = await response.json();
      if (!data.success) {
        throw new Error(data.message || '提交失敗');
      }

      // 提交成功，導向結果頁面
      navigate(`/correction-result/${data.submissionId}`);

    } catch (error) {
      console.error('提交失敗:', error);
      setError(error instanceof Error ? error.message : '提交失敗');
    } finally {
      setLoading(false);
    }
  };

  const handlePrevImage = (type: 'writing' | 'translation', translationIndex?: number) => {
    if (type === 'writing') {
      setCurrentImageIndex(prev => Math.max(0, prev - 1));
    } else if (type === 'translation' && typeof translationIndex === 'number') {
      const newTranslations = [...formData.translations];
      const currentTranslation = newTranslations[translationIndex];
      if (currentTranslation) {
        const currentIndex = currentTranslation.currentImageIndex || 0;
        newTranslations[translationIndex] = {
          ...currentTranslation,
          currentImageIndex: Math.max(0, currentIndex - 1)
        };
        setFormData(prev => ({ ...prev, translations: newTranslations }));
      }
    }
  };

  const handleNextImage = (type: 'writing' | 'translation', translationIndex?: number) => {
    if (type === 'writing') {
      const maxIndex = formData.writing.images.length - 1;
      setCurrentImageIndex(prev => Math.min(maxIndex, prev + 1));
    } else if (type === 'translation' && typeof translationIndex === 'number') {
      const newTranslations = [...formData.translations];
      const currentTranslation = newTranslations[translationIndex];
      if (currentTranslation) {
        const currentIndex = currentTranslation.currentImageIndex || 0;
        const maxIndex = (currentTranslation.images?.length || 1) - 1;
        newTranslations[translationIndex] = {
          ...currentTranslation,
          currentImageIndex: Math.min(maxIndex, currentIndex + 1)
        };
        setFormData(prev => ({ ...prev, translations: newTranslations }));
      }
    }
  };

  const renderTranslationSection = () => (
    <div className="space-y-6">
      {assignment?.translationQuestions?.map((question, index) => {
        const currentTranslation = formData.translations[index];
        const hasImages = Boolean(currentTranslation?.images?.length);
        const currentImageIndex = currentTranslation?.currentImageIndex || 0;

        return (
          <Card key={question.id}>
            <CardContent className="p-6">
              <div className="space-y-4">
                <div className="p-4 bg-gray-50 rounded-lg">
                  <p className="font-medium mb-2">題目 {question.questionNumber}:</p>
                  <p>{question.text}</p>
                </div>

                <div className="space-y-4">
                  <div className="flex justify-between items-center">
                    <Label>翻譯 *</Label>
                    <div className="relative">
                      <Button
                        type="button"
                        variant="outline"
                        size="icon"
                        disabled={loading}
                        className="z-10"
                      >
                        {loading ? (
                          <Loader2 className="h-4 w-4 animate-spin" />
                        ) : (
                          <Camera className="h-4 w-4" />
                        )}
                      </Button>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleImageUpload(e, 'translation', index)}
                        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                      />
                    </div>
                  </div>

                  <div className={hasImages ? "grid grid-cols-2 gap-4" : ""}>
                    <div className={hasImages ? "" : "w-full"}>
                      <Textarea
                        value={currentTranslation?.answer || ''}
                        onChange={(e) => {
                          const newTranslations = [...formData.translations];
                          if (newTranslations[index]) {
                            newTranslations[index].answer = e.target.value;
                          } else {
                            newTranslations[index] = {
                              questionId: question.id,
                              answer: e.target.value,
                              images: [],
                              currentImageIndex: 0
                            };
                          }
                          setFormData(prev => ({ ...prev, translations: newTranslations }));
                        }}
                        placeholder="請輸入翻譯"
                        className="min-h-[100px]"
                        required
                      />
                    </div>

                    {hasImages && currentTranslation?.images && (
                      <div className="relative">
                        <div className="space-y-4">
                          <div className="relative">
                            <img
                              src={currentTranslation.images[currentImageIndex]?.preview}
                              alt={`翻譯題目 ${question.questionNumber} 的上傳圖片`}
                              className="w-full h-auto rounded-lg border"
                            />

                            {currentTranslation.images.length > 1 && (
                              <>
                                <Button
                                  variant="outline"
                                  size="icon"
                                  className="absolute left-0 top-1/2 transform -translate-y-1/2 -translate-x-4"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handlePrevImage('translation', index);
                                  }}
                                  disabled={currentImageIndex === 0}
                                >
                                  <ChevronLeft className="h-4 w-4" />
                                </Button>
                                <Button
                                  variant="outline"
                                  size="icon"
                                  className="absolute right-0 top-1/2 transform -translate-y-1/2 translate-x-4"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleNextImage('translation', index);
                                  }}
                                  disabled={currentImageIndex === currentTranslation.images.length - 1}
                                >
                                  <ChevronRight className="h-4 w-4" />
                                </Button>
                              </>
                            )}
                          </div>

                          <div className="flex justify-center space-x-2">
                            {currentTranslation.images.map((_, imgIndex) => (
                              <Button
                                key={imgIndex}
                                variant="outline"
                                size="sm"
                                onClick={(e) => {
                                  e.preventDefault();
                                  const newTranslations = [...formData.translations];
                                  if (newTranslations[index]) {
                                    newTranslations[index].currentImageIndex = imgIndex;
                                    setFormData(prev => ({ ...prev, translations: newTranslations }));
                                  }
                                }}
                                className={`w-8 h-8 ${
                                  currentImageIndex === imgIndex ? "bg-blue-100" : ""
                                }`}
                              >
                                {imgIndex + 1}
                              </Button>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        );
      })}
    </div>
  );

  const renderWritingSection = () => {
    if (!assignment?.writing) return null;
    
    console.log('Writing data:', assignment.writing);

    const imageSrc = assignment.writing.image ? 
      `data:image/${assignment.writing.image.contentType};base64,${assignment.writing.image.data}` : 
      undefined;

    return (
      <div className="space-y-6">
        <Card>
          <CardContent className="p-6">
            {assignment?.writing && (
              <div className="space-y-6">
                <div className="space-y-4">
                  {/* 作文題目 */}
                  <div className="p-4 bg-gray-50 rounded-lg">
                    <h3 className="font-medium mb-2">作文題目：</h3>
                    <p>{assignment.writing.description}</p>
                  </div>
    
                  {/* 題目圖片 - 使用 pic_description */}
                  
    
                  {/* 或者使用 image (如果端提供的是 base64 格式) */}
                  {assignment.writing.imageDescription && (
                    <div className="p-4 bg-white rounded-lg border">
                      <h4 className="font-medium mb-4">題目圖片說明：</h4>
                      <p>{assignment.writing.imageDescription}</p>
                    </div>
                  )}

                  {assignment.writing.image && (
                    <div className="p-4 bg-white rounded-lg border">
                      <h4 className="font-medium mb-4">題目圖片：</h4>
                      <div className="rounded-lg overflow-hidden">
                        <img 
                          src={getImageSrc(assignment.writing.image)}
                          alt="作文題目圖片"
                          className="max-w-full h-auto rounded-md"
                          onError={(e) => {
                            console.error('Image load failed:', e);
                            e.currentTarget.style.display = 'none';
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
                
                <div className="space-y-4">
                  <div className="flex justify-between items-center">
                    <Label>作文內容 *</Label>
                    <div className="relative">
                      <Button
                        type="button"
                        variant="outline"
                        size="icon"
                        disabled={loading}
                        className="z-10"
                      >
                        {loading ? (
                          <Loader2 className="h-4 w-4 animate-spin" />
                        ) : (
                          <Camera className="h-4 w-4" />
                        )}
                      </Button>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleImageUpload(e, 'writing')}
                        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                      />
                    </div>
                  </div>
                  
                  <div className={formData.writing.images.length > 0 ? "grid grid-cols-2 gap-4" : ""}>
                    <div className={formData.writing.images.length > 0 ? "" : "w-full"}>
                      <Textarea
                        value={formData.writing.content}
                        onChange={(e) => setFormData(prev => ({
                          ...prev,
                          writing: { ...prev.writing, content: e.target.value }
                        }))}
                        className="min-h-[400px] w-full"
                        placeholder="請輸入作文內容"
                        required
                      />
                    </div>
                    
                    {formData.writing.images.length > 0 && (
                      <div className="relative">
                        <div className="space-y-4">
                          <div className="relative">
                            {formData.writing.images.map((image, index) => (
                              <div
                                key={index}
                                className={`transition-opacity duration-300 ${
                                  currentImageIndex === index ? "opacity-100" : "opacity-0 hidden"
                                }`}
                              >
                                <Card>
                                  <CardContent className="p-4">
                                    <img
                                      src={image.preview}
                                      alt={`上傳的圖片 ${index + 1}`}
                                      className="w-full h-auto"
                                    />
                                    <p className="text-sm text-gray-500 mt-2">
                                      第 {index + 1} 頁，共 {formData.writing.images.length} 頁
                                    </p>
                                  </CardContent>
                                </Card>
                              </div>
                            ))}

                            {formData.writing.images.length > 1 && (
                              <>
                                <Button
                                  variant="outline"
                                  size="icon"
                                  className="absolute left-0 top-1/2 transform -translate-y-1/2 -translate-x-4"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handlePrevImage('writing');
                                  }}
                                  disabled={currentImageIndex === 0}
                                >
                                  <ChevronLeft className="h-4 w-4" />
                                </Button>
                                <Button
                                  variant="outline"
                                  size="icon"
                                  className="absolute right-0 top-1/2 transform -translate-y-1/2 translate-x-4"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleNextImage('writing');
                                  }}
                                  disabled={currentImageIndex === formData.writing.images.length - 1}
                                >
                                  <ChevronRight className="h-4 w-4" />
                                </Button>
                              </>
                            )}
                          </div>

                          <div className="flex justify-center space-x-2">
                            {formData.writing.images.map((_, index) => (
                              <Button
                                key={index}
                                variant="outline"
                                size="sm"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setCurrentImageIndex(index);
                                }}
                                className={`w-8 h-8 ${
                                  currentImageIndex === index ? "bg-blue-100" : ""
                                }`}
                              >
                                {index + 1}
                              </Button>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="w-[200px]">
                    <Label htmlFor="lang" className="mb-2 block">批改說明語言 *</Label>
                    <Select
                      value={formData.writing.lang}
                      onValueChange={(value) => setFormData(prev => ({
                        ...prev,
                        writing: { ...prev.writing, lang: value }
                      }))}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="請選擇語言" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="繁體中文">繁體中文</SelectItem>
                        <SelectItem value="日本語">日本語</SelectItem>
                        <SelectItem value="한국어">한국어</SelectItem>
                        <SelectItem value="Tiếng Việt">Tiếng Việt</SelectItem>
                        <SelectItem value="简体中文">简体中文</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                </div>
              </div>
            )}
          </CardContent>
        </Card>

        <Card>
          <CardContent className="p-6">
            <h3 className="text-lg font-semibold mb-4">使用說明</h3>
            <div className="space-y-2 text-sm text-gray-600">
              <p>1. 所有標註 * 的欄位為必填項目</p>
              <p>2. 您可以使用相機圖示上傳圖片，系統會自動識別文字</p>
              <p>3. 批改過程可能需要 2-3 分鐘，請耐心等待</p>
              <p>4. 每篇作文會消耗一次額度，請合理使用</p>
              <p>5. 如遇到問題，請聯繫系統管理員協助</p>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  };

  if (loading && !assignment) {
    return (
      <div className="min-h-screen bg-gray-50 flex flex-col">
        <Header setIsAuthenticated={setIsAuthenticated} username={username} />
        <div className="flex-1 flex items-center justify-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
        </div>
      </div>
    );
  }

  if (error && !assignment) {
    return (
      <div className="min-h-screen bg-gray-50 flex flex-col">
        <Header setIsAuthenticated={setIsAuthenticated} username={username} />
        <div className="flex-1 flex flex-col items-center justify-center">
          <p className="text-red-500 mb-4">{error}</p>
          <Button variant="outline" onClick={() => navigate('/student-dashboard')}>
            返回首頁
          </Button>
        </div>
      </div>
    );
  }

  return (
      <div className="min-h-screen bg-gray-50 flex flex-col">
        <Header setIsAuthenticated={setIsAuthenticated} username={username} />
        <div className="container mx-auto p-6">
          <Card className="mb-6">
            <CardContent className="p-6">
              <div className="flex justify-between items-center">
                <div>
                  <h1 className="text-2xl font-bold">{assignment?.title}</h1>
                  <p className="text-gray-600 mt-2">
                    截止日期：{assignment?.deadline ? new Date(assignment.deadline).toLocaleDateString('zh-TW') : ''}
                  </p>
                </div>
                <Button variant="outline" onClick={() => navigate('/student-dashboard')}>
                  返回
                </Button>
              </div>
            </CardContent>
          </Card>
  
          {assignment && (  // 添加條件檢查確保 assignment 存在
            <form onSubmit={handleSubmit} className="space-y-6">
              {error && (
                <Alert variant="destructive">
                  <AlertDescription>{error}</AlertDescription>
                </Alert>
              )}
  
              {successMessage && (
                <Alert>
                  <AlertDescription>{successMessage}</AlertDescription>
                </Alert>
              )}
  
              {/* 翻譯部分 */}
              {(assignment.testType === 'translation' || assignment.testType === 'translation-writing') && 
                assignment.translationQuestions && 
                assignment.translationQuestions.length > 0 && 
                renderTranslationSection()
              }
              
              {/* 作文部分 */}
              {(assignment.testType === 'writing' || assignment.testType === 'translation-writing' || assignment.testType === 'english-writing') && 
                assignment.writing && 
                renderWritingSection()
              }
  
              <div className="flex justify-end space-x-4">
                <Button type="submit" disabled={loading}>
                  {loading ? (
                    <div className="flex items-center">
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      處理中...
                    </div>
                  ) : '提交作業'}
                </Button>
              </div>
            </form>
          )}
        </div>
      </div>
    );
  };

export default StudentAssignment;