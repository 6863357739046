import React from 'react';
import { Card, CardContent } from '../ui/card';
import { Button } from '../ui/button';

interface SelectSourceTypeProps {
  onSelect: (source: string) => void;
  onBack: () => void;
}

const SelectSourceType: React.FC<SelectSourceTypeProps> = ({ onSelect, onBack }) => {
  const sources = [
    {
      id: 'past-exam',
      title: '考古題',
      description: '選擇歷年試題'
    },
    {
      id: 'custom',
      title: '自行出題',
      description: '創建新的題目'
    },
    {
      id: 'random',
      title: '隨機出題',
      description: '系統隨機選擇題目'
    }
  ];

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-3 gap-4">
        {sources.map((source) => (
          <Card
            key={source.id}
            className="cursor-pointer hover:shadow-lg transition-all"
            onClick={() => onSelect(source.id)}
          >
            <CardContent className="pt-6">
              <h3 className="text-lg font-semibold mb-2">{source.title}</h3>
              <p className="text-sm text-gray-600">{source.description}</p>
            </CardContent>
          </Card>
        ))}
      </div>
      <div className="flex justify-start">
        <Button variant="outline" onClick={onBack}>
          返回
        </Button>
      </div>
    </div>
  );
};

export default SelectSourceType;