import React from 'react';

interface Step {
  title: string;
  description: string;
}

interface StepsProps {
  steps: Step[];
  currentStep: number;
  completedSteps: Record<string, string>;
}

export const Steps: React.FC<StepsProps> = ({ steps, currentStep, completedSteps }) => {
  return (
    <div className="mb-8">
      <div className="relative">
        <div className="absolute left-0 top-5 w-full h-0.5 bg-gray-200">
          <div 
            className="absolute left-0 top-0 h-full bg-blue-500 transition-all duration-500"
            style={{ width: `${(currentStep / (steps.length - 1)) * 100}%` }}
          />
        </div>
        
        <div className="relative flex justify-between">
          {steps.map((step, index) => {
            const isCompleted = completedSteps[step.title];
            const isCurrent = index === currentStep;

            return (
              <div 
                key={index} 
                className="flex flex-col items-center"
              >
                <div className={`
                  relative flex items-center justify-center w-10 h-10 rounded-full 
                  ${isCompleted || isCurrent ? 'bg-blue-500' : 'bg-gray-200'}
                  transition-colors duration-300
                `}>
                  {isCompleted ? (
                    <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                  ) : isCurrent ? (
                    <div className="w-3 h-3 bg-white rounded-full" />
                  ) : (
                    <div className="w-3 h-3 bg-gray-400 rounded-full" />
                  )}
                </div>
                <div className="mt-2 text-center">
                  <div className={`text-sm font-medium ${isCurrent ? 'text-blue-500' : ''}`}>
                    {step.title}
                  </div>
                  <div className="text-xs text-gray-500 mt-1">
                    {step.description}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}; 