// src/App.tsx

import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate, Routes, Route } from 'react-router-dom';
import Login from '../pages/login';
import AdminDashboard from '../pages/AdminDashboard';
import TeacherDashboard from '../pages/TeacherDashboard';
import SalesDashboard from '../pages/SalesDashboard';
import EssaySubmission from '../pages/essay-submission';
import CorrectionResult from '../components/correction/CorrectionResult';
import CorrectionLoading from '../pages/correction-loading';
import ProfileSettings from '../pages/ProfileSettings';
import CorrectionHistory from '../pages/CorrectionHistory';

interface PrivateRouteProps {
  children: React.ReactNode;
  allowedRoles?: string[];
  isAuthenticated: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ 
  children, 
  allowedRoles = [], 
  isAuthenticated 
}) => {
  const userRole = localStorage.getItem('userRole');
  
  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  if (allowedRoles.length > 0 && !allowedRoles.includes(userRole || '')) {
    return <Navigate to="/unauthorized" replace />;
  }

  return <>{children}</>;
};

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [username, setUsername] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    const savedUsername = localStorage.getItem('username');
    
    if (token && savedUsername) {
      setIsAuthenticated(true);
      setUsername(savedUsername);
    } else {
      setIsAuthenticated(false);
      setUsername('');
      if (window.location.pathname !== '/') {
        navigate('/', { replace: true });
      }
    }
  }, [navigate]);

  return (
    <Routes>
      <Route 
        path="/" 
        element={
          isAuthenticated ? (
            <Navigate 
              to={`/${localStorage.getItem('userRole')}-dashboard`} 
              replace 
            />
          ) : (
            <Login 
              setIsAuthenticated={setIsAuthenticated} 
              setUsername={setUsername} 
            />
          )
        } 
      />
      
      <Route 
        path="/admin-dashboard" 
        element={
          <PrivateRoute 
            isAuthenticated={isAuthenticated} 
            allowedRoles={['admin']}
          >
            <AdminDashboard />
          </PrivateRoute>
        } 
      />

      <Route 
        path="/sales-dashboard" 
        element={
          <PrivateRoute 
            isAuthenticated={isAuthenticated} 
            allowedRoles={['sales']}
          >
            <SalesDashboard />
          </PrivateRoute>
        } 
      />

      <Route 
        path="/essay-submission" 
        element={
          <PrivateRoute 
            isAuthenticated={isAuthenticated} 
            allowedRoles={['student_c']}
          >
            <EssaySubmission 
              setIsAuthenticated={setIsAuthenticated} 
              username={username} 
            />
          </PrivateRoute>
        } 
      />
      
      <Route 
        path="/correction-result/:id" 
        element={
          <PrivateRoute 
            isAuthenticated={isAuthenticated}
          >
            <CorrectionResult 
              setIsAuthenticated={setIsAuthenticated} 
              username={username} 
            />
          </PrivateRoute>
        } 
      />
      
      <Route 
        path="/correction-history" 
        element={
          <PrivateRoute 
            isAuthenticated={isAuthenticated}
          >
            <CorrectionHistory 
              setIsAuthenticated={setIsAuthenticated} 
              username={username} 
            />
          </PrivateRoute>
        } 
      />
      
      <Route 
        path="/profile-settings" 
        element={
          <PrivateRoute isAuthenticated={isAuthenticated}>
            <ProfileSettings 
              setIsAuthenticated={setIsAuthenticated} 
              username={username} 
            />
          </PrivateRoute>
        } 
      />

      <Route path="/unauthorized" element={<div>無訪問權限</div>} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default App;