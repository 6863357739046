// src/components/correction/tabs/AccuracyAnalysisTab.tsx

import React from 'react';
import { Card } from '../../ui/card';
import { CorrectionData } from '../../types/correction';

interface AccuracyAnalysisTabProps {
  data: CorrectionData;
}

const AccuracyAnalysisTab: React.FC<AccuracyAnalysisTabProps> = ({ data }) => {
  const cefrAnalysisItems = [
    {
      title: '詞彙範疇',
      titleEn: 'Vocabulary Range',
      english: data.article_cerf_lang1?.VocabularyRange,
      chinese: data.article_cerf_lang2?.VocabularyRange
    },
    {
      title: '句法結構',
      titleEn: 'Syntax Structure',
      english: data.article_cerf_lang1?.SyntaxStructure,
      chinese: data.article_cerf_lang2?.SyntaxStructure
    },
    {
      title: '內容主題',
      titleEn: 'Content Theme',
      english: data.article_cerf_lang1?.ContentTheme,
      chinese: data.article_cerf_lang2?.ContentTheme
    },
    {
      title: '意圖與表達',
      titleEn: 'Intent and Expression',
      english: data.article_cerf_lang1?.IntentandExpression,
      chinese: data.article_cerf_lang2?.IntentandExpression
    },
    {
      title: '總結',
      titleEn: 'Conclusion',
      english: data.article_cerf_lang1?.Conclusion,
      chinese: data.article_cerf_lang2?.Conclusion
    }
  ];

  const accuracyItems = [
    {
      title: '語言與文法',
      english: data.article_Accuracy_lang1?.['Language and Grammar'],
      chinese: data.article_Accuracy_lang2?.['Language and Grammar (translated)']
    },
    {
      title: '事實錯誤',
      english: data.article_Accuracy_lang1?.['Factual Errors'],
      chinese: data.article_Accuracy_lang2?.['Factual Errors (translated)']
    },
    {
      title: '邏輯缺陷',
      english: data.article_Accuracy_lang1?.['Logical Flaws'],
      chinese: data.article_Accuracy_lang2?.['Logical Flaws (translated)']
    },
    {
      title: '敘事清晰度',
      english: data.article_Accuracy_lang1?.['Narrative Clarity'],
      chinese: data.article_Accuracy_lang2?.['Narrative Clarity (translated)']
    }
  ];

  // 找出符合度最高的風格
  const getHighestProbabilityStyle = (styles: Array<{ Style: string; Probability: string }> = []) => {
    if (!styles.length) return null;
    return styles.reduce((prev, current) => {
      const prevProb = parseFloat(prev.Probability.replace('%', ''));
      const currentProb = parseFloat(current.Probability.replace('%', ''));
      return currentProb > prevProb ? current : prev;
    });
  };

  const highestStyle = getHighestProbabilityStyle(data.article_style_lang1?.['Style Odds Table']);
  const highestTranslatedStyle = getHighestProbabilityStyle(data.article_style_lang2?.['Style Odds Table']);

  return (
    <div className="space-y-8">
      <h2 className="text-2xl font-bold mb-6">文章評級分析結果</h2>

      {/* CEFR 等級分析 */}
      <section>
        <h3 className="text-xl font-semibold mb-4">文章評級分析</h3>
        <div className="space-y-6">
          {/* CEFR 等級評定 */}
          <div className="bg-white p-4 rounded-lg shadow">
            <h4 className="text-lg font-medium mb-2">根據 CEFR 標準本文評定為:</h4>
            <p className="mb-4">Level: {data.article_cerf_lang1?.CEFRLevel || '無資料'}</p>
            
            {/* 評級分析詳情 */}
            <div className="grid grid-cols-1 gap-6">
              {cefrAnalysisItems.map((item) => (
                <div key={item.title} className="space-y-2">
                  <h5 className="font-medium text-blue-600">{item.title} ({item.titleEn})</h5>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 bg-gray-50 p-4 rounded-lg">
                    <div className="space-y-1">
                      <p className="text-sm text-gray-600">English:</p>
                      <p>{item.english || '無資料'}</p>
                    </div>
                    <div className="space-y-1">
                      <p className="text-sm text-gray-600">中文:</p>
                      <p>{item.chinese || '無資料'}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* 精確度與邏輯分析 */}
      <section>
        <h3 className="text-xl font-semibold mb-4 text-blue-600">精確度與邏輯分析</h3>
        <div className="space-y-6">
          {accuracyItems.map((section) => (
            <Card key={section.title} className="p-6">
              <h4 className="text-lg font-medium mb-4 text-blue-600">{section.title}</h4>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="space-y-2">
                  <p className="font-medium">English:</p>
                  <p className="text-gray-700">{section.english || '無資料'}</p>
                </div>
                <div className="space-y-2">
                  <p className="font-medium">中文:</p>
                  <p className="text-gray-700">{section.chinese || '無資料'}</p>
                </div>
              </div>
            </Card>
          ))}
        </div>
      </section>

      {/* 文章風格分析 - 簡化版 */}
      <section>
        <h3 className="text-xl font-semibold mb-4 text-blue-600">文章風格分析</h3>
        <Card className="p-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="space-y-2">
              <h4 className="font-medium">English:</h4>
              <div className="bg-gray-50 p-4 rounded-lg">
                <p>文章風格: <span className="font-medium">{highestStyle?.Style}</span></p>
                <p>符合度: <span className="text-blue-600 font-medium">{highestStyle?.Probability}</span></p>
              </div>
            </div>
            <div className="space-y-2">
              <h4 className="font-medium">中文:</h4>
              <div className="bg-gray-50 p-4 rounded-lg">
                <p>文章風格: <span className="font-medium">{highestTranslatedStyle?.Style}</span></p>
                <p>符合度: <span className="text-blue-600 font-medium">{highestTranslatedStyle?.Probability}</span></p>
              </div>
            </div>
          </div>
        </Card>
      </section>

      {/* 文章分析結論 */}
      <section>
        <h3 className="text-xl font-semibold mb-4 text-blue-600">文章分析結論</h3>
        <Card className="p-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="space-y-2">
              <h4 className="font-medium">English:</h4>
              <p className="text-gray-700">{data.article_Accuracy_lang1?.Summary || '無資料'}</p>
            </div>
            <div className="space-y-2">
              <h4 className="font-medium">中文:</h4>
              <p className="text-gray-700">{data.article_Accuracy_lang2?.['Summary (translated)'] || '無資料'}</p>
            </div>
          </div>
        </Card>
      </section>
    </div>
  );
};

export default AccuracyAnalysisTab;