// src/components/admin/TeacherAssignment.tsx

import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from '../ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../ui/table';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Alert, AlertDescription } from '../ui/alert';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from '../ui/dialog';
import { Label } from '../ui/label';
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from '../ui/select';

import { Trash2 } from 'lucide-react'; // 如果您使用的是 Lucide React 圖示庫

interface Student {
  _id: string;
  username: string;
  realName: string;
  school: string;
  className: string;
  teacherId?: string;
}

interface Teacher {
  _id: string;
  username: string;
  realName: string;
  school: string;
  className: string;
  students: Student[];
}

interface ClassGroup {
  school: string;
  className: string;
  students: Student[];
}

const TeacherAssignment: React.FC = () => {
  const [teachers, setTeachers] = useState<Teacher[]>([]);
  const [students, setStudents] = useState<Student[]>([]);
  const [selectedTeacher, setSelectedTeacher] = useState<string | null>(null);
  const [selectedStudents, setSelectedStudents] = useState<string[]>([]);
  const [showAssignDialog, setShowAssignDialog] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [expandedTeacherId, setExpandedTeacherId] = useState<string | null>(null);
  const [removingStudentId, setRemovingStudentId] = useState<string | null>(null);
  const [showClassAssignDialog, setShowClassAssignDialog] = useState(false);
  const [classGroups, setClassGroups] = useState<ClassGroup[]>([]);
  const [selectedClass, setSelectedClass] = useState<string>('');

  useEffect(() => {
    fetchTeachers();
    fetchStudents();
  }, []);

  useEffect(() => {
    const groupStudentsByClass = () => {
      const groups: { [key: string]: Student[] } = {};
      students.forEach(student => {
        if (student.school && student.className) {
          const key = `${student.school}-${student.className}`;
          if (!groups[key]) {
            groups[key] = [];
          }
          groups[key].push(student);
        }
      });

      const classGroupsList = Object.entries(groups).map(([key, students]) => {
        const [school, className] = key.split('-');
        return { school, className, students };
      });

      setClassGroups(classGroupsList);
    };

    groupStudentsByClass();
  }, [students]);

  const fetchTeachers = async () => {
    try {
      const response = await fetch('/api/admin/teachers', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      
      if (!response.ok) {
        throw new Error('獲取教師列表失敗');
      }

      const data = await response.json();
      if (data.success) {
        setTeachers(data.teachers);
      } else {
        setError(data.message || '獲取教師列表失敗');
      }
    } catch (error) {
      console.error('Error fetching teachers:', error);
      setError('取得教師列表時發生錯誤');
    }
  };

  const fetchStudents = async () => {
    try {
      const response = await fetch('/api/admin/students', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      
      if (!response.ok) {
        throw new Error('獲取學生列表失敗');
      }

      const data = await response.json();
      setStudents(data.students);
    } catch (error) {
      setError('取得學生列表時發生錯誤');
    }
  };

  const handleAssignStudents = async () => {
    if (!selectedTeacher || selectedStudents.length === 0) return;

    try {
      setIsLoading(true);
      const response = await fetch('/api/admin/assign-teacher', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          teacherId: selectedTeacher,
          studentIds: selectedStudents,
        }),
      });

      if (!response.ok) {
        throw new Error('分配學生失敗');
      }

      await fetchTeachers();
      await fetchStudents();
      
      setShowAssignDialog(false);
      setSelectedStudents([]);
      setSelectedTeacher(null);
    } catch (error) {
      setError('分配學生時發生錯誤');
    } finally {
      setIsLoading(false);
    }
  };

  const handleUnassignStudent = async (studentId: string, teacherId: string) => {
    try {
      setRemovingStudentId(studentId);
      const response = await fetch('/api/admin/unassign-students', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          teacherId,
          studentIds: [studentId],
        }),
      });

      if (!response.ok) {
        throw new Error('取消分配學生失敗');
      }

      await fetchTeachers();
      await fetchStudents();
    } catch (error) {
      setError('取消分配學生時發生錯誤');
    } finally {
      setRemovingStudentId(null);
    }
  };

  const handleAssignClass = async () => {
    if (!selectedTeacher || !selectedClass) return;

    const [selectedSchool, selectedClassName] = selectedClass.split('-');
    const classStudents = students.filter(
      student => 
        student.school === selectedSchool && 
        student.className === selectedClassName
    );

    try {
      setIsLoading(true);
      const response = await fetch('/api/admin/assign-teacher', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          teacherId: selectedTeacher,
          studentIds: classStudents.map(s => s._id),
        }),
      });

      if (!response.ok) {
        throw new Error('分配班級失敗');
      }

      setShowClassAssignDialog(false);
      await Promise.all([fetchTeachers(), fetchStudents()]);
    } catch (error) {
      setError('分配班級時發生錯誤');
    } finally {
      setIsLoading(false);
    }
  };

  // 過濾教師列表
  const filteredTeachers = teachers.filter(teacher =>
    teacher.realName.includes(searchTerm) ||
    teacher.username.includes(searchTerm) ||
    teacher.school.includes(searchTerm) ||
    teacher.className.includes(searchTerm)
  );

  // 過濾學生列表
  const filteredStudents = students.filter(student =>
    student.realName.includes(searchTerm) ||
    student.username.includes(searchTerm) ||
    student.school.includes(searchTerm) ||
    student.className.includes(searchTerm)
  );

  const handleCloseClassAssignDialog = () => {
    setShowClassAssignDialog(false);
    setSelectedClass('');
    setSelectedTeacher(null);
  };

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader>
          <CardTitle>教師分配</CardTitle>
          <CardDescription>管理教師和學生的分配關係</CardDescription>
        </CardHeader>
        <CardContent>
          {error && (
            <Alert variant="destructive" className="mb-4">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}
          
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <Input
                placeholder="搜尋教師或學生..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="max-w-sm"
              />
            </div>

            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-12"></TableHead>
                  <TableHead>教師名稱</TableHead>
                  <TableHead>學校</TableHead>
                  <TableHead>班級</TableHead>
                  <TableHead>學生數量</TableHead>
                  <TableHead>操作</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {filteredTeachers.map((teacher) => (
                  <React.Fragment key={teacher._id}>
                    <TableRow>
                      <TableCell>
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => setExpandedTeacherId(
                            expandedTeacherId === teacher._id ? null : teacher._id
                          )}
                        >
                          {expandedTeacherId === teacher._id ? '-' : '+'}
                        </Button>
                      </TableCell>
                      <TableCell>{teacher.realName}</TableCell>
                      <TableCell>{teacher.school}</TableCell>
                      <TableCell>{teacher.className}</TableCell>
                      <TableCell>{teacher.students.length}</TableCell>
                      <TableCell>
                        <div className="flex space-x-2">
                          <Button
                            variant="outline"
                            onClick={() => {
                              setSelectedTeacher(teacher._id);
                              setShowAssignDialog(true);
                            }}
                          >
                            分配學生
                          </Button>
                          <Button
                            variant="outline"
                            onClick={() => {
                              setSelectedTeacher(teacher._id);
                              setShowClassAssignDialog(true);
                            }}
                          >
                            整班分配
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                    {expandedTeacherId === teacher._id && (
                      <TableRow>
                        <TableCell colSpan={6}>
                          <Table className="mt-2">
                            <TableHeader>
                              <TableRow>
                                <TableHead>學生姓名</TableHead>
                                <TableHead>帳號</TableHead>
                                <TableHead>學校</TableHead>
                                <TableHead>班級</TableHead>
                                <TableHead>操作</TableHead>
                              </TableRow>
                            </TableHeader>
                            <TableBody>
                              {teacher.students.map(student => (
                                <TableRow key={student._id}>
                                  <TableCell>{student.realName}</TableCell>
                                  <TableCell>{student.username}</TableCell>
                                  <TableCell>{student.school}</TableCell>
                                  <TableCell>{student.className}</TableCell>
                                  <TableCell>
                                    <Button
                                      variant="ghost"
                                      size="icon"
                                      onClick={() => handleUnassignStudent(student._id, teacher._id)}
                                      disabled={removingStudentId === student._id}
                                    >
                                      {removingStudentId === student._id ? (
                                        <span className="loader"></span>
                                      ) : (
                                        <Trash2 size={16} />
                                      )}
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </div>
        </CardContent>
      </Card>

      {/* 分配學生的對話框 */}
      <Dialog open={showAssignDialog} onOpenChange={setShowAssignDialog}>
        <DialogContent className="max-w-3xl">
          <DialogHeader>
            <DialogTitle>分配學生</DialogTitle>
            <DialogDescription>選擇學生並分配給教師</DialogDescription>
          </DialogHeader>
          <div className="mt-4">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-12">
                    <Input
                      type="checkbox"
                      checked={
                        filteredStudents.length > 0 &&
                        filteredStudents.every(student =>
                          selectedStudents.includes(student._id)
                        )
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedStudents(filteredStudents.map(s => s._id));
                        } else {
                          setSelectedStudents([]);
                        }
                      }}
                    />
                  </TableHead>
                  <TableHead>學生姓名</TableHead>
                  <TableHead>學校</TableHead>
                  <TableHead>班級</TableHead>
                  <TableHead>目前教師</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {filteredStudents.map((student) => (
                  <TableRow key={student._id}>
                    <TableCell>
                      <Input
                        type="checkbox"
                        checked={selectedStudents.includes(student._id)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedStudents([...selectedStudents, student._id]);
                          } else {
                            setSelectedStudents(
                              selectedStudents.filter(id => id !== student._id)
                            );
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell>{student.realName}</TableCell>
                    <TableCell>{student.school}</TableCell>
                    <TableCell>{student.className}</TableCell>
                    <TableCell>
                      {teachers.find(t => t._id === student.teacherId)?.realName || '-'}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <div className="flex justify-end space-x-2 mt-4">
              <Button
                variant="outline"
                onClick={() => setShowAssignDialog(false)}
              >
                取消
              </Button>
              <Button
                onClick={handleAssignStudents}
                disabled={isLoading || selectedStudents.length === 0}
              >
                {isLoading ? '處理中...' : '確認分配'}
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      {/* 整班分配對話框 */}
      <Dialog open={showClassAssignDialog} onOpenChange={setShowClassAssignDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              整班分配給 {teachers.find(t => t._id === selectedTeacher)?.realName}
            </DialogTitle>
            <DialogDescription>
              選擇要分配的班級
            </DialogDescription>
          </DialogHeader>
          <div className="space-y-4">
            <div className="space-y-2">
              <Label>選擇班級</Label>
              <Select
                value={selectedClass}
                onValueChange={setSelectedClass}
              >
                <SelectTrigger>
                  <SelectValue placeholder="選擇班級" />
                </SelectTrigger>
                <SelectContent>
                  {classGroups.map((group) => (
                    <SelectItem 
                      key={`${group.school}-${group.className}`}
                      value={`${group.school}-${group.className}`}
                    >
                      {`${group.school} - ${group.className} (${group.students.length}人)`}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <div className="flex justify-end space-x-2">
              <Button
                variant="outline"
                onClick={handleCloseClassAssignDialog}
              >
                取消
              </Button>
              <Button
                onClick={handleAssignClass}
                disabled={isLoading || !selectedClass}
              >
                {isLoading ? '處理中...' : '確認分配'}
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TeacherAssignment;
