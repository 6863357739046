import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, CardContent } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { Textarea } from '../components/ui/textarea';
import { Label } from '../components/ui/label';
import { Alert, AlertDescription } from '../components/ui/alert';
import { Loader2, Camera, ChevronLeft, ChevronRight } from 'lucide-react';
import Header from '../components/Header';
import { useToast } from '../components/ui/use-toast';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../components/ui/select';

interface PracticePageProps {
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  username: string;
}

interface Practice {
  id: string;
  testType: string;
  writing: {
    id: string;
    title: string;
    description: string;
    imageDescription?: string;
    image?: {
      data: string;
      contentType: string;
    };
  };
  translations?: Array<{
    id: string;
    questionNumber: number;
    text: string;
  }>;
}

interface FormData {
  writing: {
    content: string;
    lang: string;
    images: Array<{
      file: File;
      preview: string;
      ocrText: string;
    }>;
  };
  translations: Array<{
    questionId: string;
    answer: string;
    image?: {
      file: File;
      preview: string;
      ocrText: string;
    };
  }>;
}

interface OCRResponse {
  success: boolean;
  text: string;
}

const getImageSrc = (image?: { data: string; contentType: string }): string | undefined => {
  if (!image?.data) return undefined;
  
  try {
    return `data:image/${image.contentType};base64,${image.data}`;
  } catch (error) {
    console.error('Error processing image:', error);
    return undefined;
  }
};

const PracticePage: React.FC<PracticePageProps> = ({ setIsAuthenticated, username }) => {
  const { practiceId } = useParams<{ practiceId: string }>();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [practice, setPractice] = useState<Practice | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [formData, setFormData] = useState<FormData>({
    writing: {
      content: '',
      lang: '繁體中文',
      images: []
    },
    translations: []
  });
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const fetchPractice = async () => {
      try {
        const response = await fetch(`/api/student/practice/practice/${practiceId}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        if (!response.ok) {
          throw new Error('獲取練習失敗');
        }

        const data = await response.json();
        if (data.success) {
          setPractice(data.practice);
          if (data.practice.translations) {
            setFormData(prev => ({
              ...prev,
              translations: data.practice.translations.map((q: { id: string }) => ({
                questionId: q.id,
                answer: ''
              }))
            }));
          }
        }
      } catch (error) {
        console.error('Error:', error);
        setError(error instanceof Error ? error.message : '獲取練習失敗');
      } finally {
        setLoading(false);
      }
    };

    if (practiceId) {
      fetchPractice();
    }
  }, [practiceId]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch('/api/student/practice/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          practiceId,
          translations: formData.translations,
          writing: practice?.writing ? {
            content: formData.writing.content,
            lang: formData.writing.lang
          } : null
        })
      });

      if (!response.ok) {
        throw new Error('提交失敗');
      }

      const data = await response.json();
      if (data.success) {
        toast({
          title: "成功",
          description: "練習提交成功",
        });
        navigate('/student-dashboard');
      }
    } catch (error) {
      console.error('Error:', error);
      toast({
        variant: "destructive",
        title: "錯誤",
        description: error instanceof Error ? error.message : '提交失敗'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleWritingImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;

    const file = e.target.files[0];
    const preview = URL.createObjectURL(file);

    try {
      setLoading(true);

      const base64 = await new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64 = reader.result as string;
          resolve(base64.split(',')[1]);
        };
        reader.onerror = error => reject(error);
      });

      const response = await fetch('/api/ocr', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          image: base64
        })
      });

      if (!response.ok) {
        throw new Error('OCR 處理失敗');
      }

      const data: OCRResponse = await response.json();

      setFormData(prev => ({
        ...prev,
        writing: {
          ...prev.writing,
          images: [...prev.writing.images, {
            file,
            preview,
            ocrText: data.text
          }],
          content: prev.writing.content + (prev.writing.content ? '\n' : '') + data.text
        }
      }));

      toast({
        title: "成功",
        description: "圖片上傳並識別成功",
      });
    } catch (error) {
      console.error('Error:', error);
      toast({
        variant: "destructive",
        title: "錯誤",
        description: error instanceof Error ? error.message : 'OCR 處理失敗'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleTranslationImageUpload = async (questionId: string, e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;

    const file = e.target.files[0];
    const preview = URL.createObjectURL(file);

    try {
      setLoading(true);

      const base64 = await new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64 = reader.result as string;
          resolve(base64.split(',')[1]);
        };
        reader.onerror = error => reject(error);
      });

      const response = await fetch('/api/ocr', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          image: base64
        })
      });

      if (!response.ok) {
        throw new Error('OCR 處理失敗');
      }

      const data: OCRResponse = await response.json();

      setFormData(prev => ({
        ...prev,
        translations: prev.translations.map(t => 
          t.questionId === questionId ? {
            ...t,
            answer: t.answer + (t.answer ? '\n' : '') + data.text,
            image: {
              file,
              preview,
              ocrText: data.text
            }
          } : t
        )
      }));

      toast({
        title: "成功",
        description: "圖片上傳並識別成功",
      });
    } catch (error) {
      console.error('Error:', error);
      toast({
        variant: "destructive",
        title: "錯誤",
        description: error instanceof Error ? error.message : 'OCR 處理失敗'
      });
    } finally {
      setLoading(false);
    }
  };

  const handlePrevImage = () => {
    setCurrentImageIndex(prev => Math.max(0, prev - 1));
  };

  const handleNextImage = () => {
    setCurrentImageIndex(prev => Math.min(formData.writing.images.length - 1, prev + 1));
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex flex-col">
        <Header setIsAuthenticated={setIsAuthenticated} username={username} />
        <div className="flex-1 flex items-center justify-center">
          <Loader2 className="h-8 w-8 animate-spin" />
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <Header setIsAuthenticated={setIsAuthenticated} username={username} />
      <main className="container mx-auto p-6">
        <Card className="mb-6">
          <CardContent className="p-6">
            <div className="flex justify-between items-center">
              <div>
                <h1 className="text-2xl font-bold">練習作答</h1>
                {practice?.writing?.title && (
                  <p className="text-gray-600 mt-2">{practice.writing.title}</p>
                )}
              </div>
              <Button variant="outline" onClick={() => navigate('/student-dashboard')}>
                <ChevronLeft className="mr-2 h-4 w-4" />
                返
              </Button>
            </div>
          </CardContent>
        </Card>

        {error && (
          <Alert variant="destructive" className="mb-6">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          {practice?.writing && (
            <Card>
              <CardContent className="p-6">
                <div className="space-y-4">
                  <div className="p-4 bg-gray-50 rounded-lg">
                    <h3 className="font-medium mb-2">作文題目：</h3>
                    <p>{practice.writing.description}</p>
                  </div>

                  {practice.writing.image && (
                    <div className="p-4 bg-white rounded-lg border">
                      <h4 className="font-medium mb-4">題目圖片：</h4>
                      <div className="rounded-lg overflow-hidden">
                        <img 
                          src={getImageSrc(practice.writing.image)}
                          alt="作文題目圖片"
                          className="max-w-full h-auto rounded-md"
                          onError={(e) => {
                            console.error('Image load failed:', e);
                            e.currentTarget.style.display = 'none';
                          }}
                        />
                      </div>
                    </div>
                  )}

                  <div className="space-y-4">
                    <div className="flex justify-between items-center">
                      <Label>作文內容</Label>
                      <div className="relative">
                        <Button
                          type="button"
                          variant="outline"
                          size="icon"
                          disabled={loading}
                          className="z-10"
                        >
                          {loading ? (
                            <Loader2 className="h-4 w-4 animate-spin" />
                          ) : (
                            <Camera className="h-4 w-4" />
                          )}
                        </Button>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleWritingImageUpload}
                          className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                        />
                      </div>
                    </div>

                    <div className={formData.writing.images.length > 0 ? "grid grid-cols-2 gap-4" : ""}>
                      <div className={formData.writing.images.length > 0 ? "" : "w-full"}>
                        <Textarea
                          value={formData.writing.content}
                          onChange={(e) => setFormData(prev => ({
                            ...prev,
                            writing: { ...prev.writing, content: e.target.value }
                          }))}
                          className={`w-full ${
                            formData.writing.images.length > 0 
                              ? "h-[calc(100vh-24rem)]" // 有圖片時的高度
                              : "min-h-[400px]"         // 無圖片時的最小高度
                          }`}
                          placeholder="請輸入作文內容"
                        />
                      </div>

                      {formData.writing.images.length > 0 && (
                        <div className="relative">
                          <div className="relative overflow-hidden rounded-lg border">
                            {formData.writing.images.map((image, index) => (
                              <div
                                key={index}
                                className={`transition-opacity duration-300 ${
                                  currentImageIndex === index ? "opacity-100" : "opacity-0 hidden"
                                }`}
                              >
                                <Card>
                                  <CardContent className="p-4">
                                    <img
                                      src={image.preview}
                                      alt={`上傳的圖片 ${index + 1}`}
                                      className="w-full h-auto"
                                    />
                                    <p className="text-sm text-gray-500 mt-2">
                                      第 {index + 1} 頁，共 {formData.writing.images.length} 頁
                                    </p>
                                  </CardContent>
                                </Card>
                              </div>
                            ))}

                            {formData.writing.images.length > 1 && (
                              <>
                                <Button
                                  variant="outline"
                                  size="icon"
                                  className="absolute left-0 top-1/2 transform -translate-y-1/2 -translate-x-4"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handlePrevImage();
                                  }}
                                  disabled={currentImageIndex === 0}
                                >
                                  <ChevronLeft className="h-4 w-4" />
                                </Button>
                                <Button
                                  variant="outline"
                                  size="icon"
                                  className="absolute right-0 top-1/2 transform -translate-y-1/2 translate-x-4"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleNextImage();
                                  }}
                                  disabled={currentImageIndex === formData.writing.images.length - 1}
                                >
                                  <ChevronRight className="h-4 w-4" />
                                </Button>
                              </>
                            )}
                          </div>

                          <div className="flex justify-center space-x-2 mt-4">
                            {formData.writing.images.map((_, index) => (
                              <Button
                                key={index}
                                variant="outline"
                                size="sm"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setCurrentImageIndex(index);
                                }}
                                className={`w-8 h-8 ${
                                  currentImageIndex === index ? "bg-blue-100" : ""
                                }`}
                              >
                                {index + 1}
                              </Button>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          )}

          {practice?.translations && practice.translations.length > 0 && (
            <div className="space-y-4">
              {practice.translations.map((question, index) => (
                <Card key={question.id}>
                  <CardContent className="p-6">
                    <div className="space-y-4">
                      <div className="p-4 bg-gray-50 rounded-lg">
                        <h3 className="font-medium mb-2">翻譯題目 {index + 1}：</h3>
                        <p>{question.text}</p>
                      </div>
                      <div className="space-y-4">
                        <div className="flex justify-between items-center">
                          <Label>翻譯答案</Label>
                          <div className="relative">
                            <Button
                              type="button"
                              variant="outline"
                              size="icon"
                              disabled={loading}
                              className="z-10"
                            >
                              {loading ? (
                                <Loader2 className="h-4 w-4 animate-spin" />
                              ) : (
                                <Camera className="h-4 w-4" />
                              )}
                            </Button>
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) => handleTranslationImageUpload(question.id, e)}
                              className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                            />
                          </div>
                        </div>

                        <div className={formData.translations.find(t => t.questionId === question.id)?.image ? "grid grid-cols-2 gap-4" : ""}>
                          <div className={formData.translations.find(t => t.questionId === question.id)?.image ? "" : "w-full"}>
                            <Textarea
                              value={formData.translations.find(t => t.questionId === question.id)?.answer || ''}
                              onChange={(e) => {
                                const newTranslations = formData.translations.map(t =>
                                  t.questionId === question.id ? { ...t, answer: e.target.value } : t
                                );
                                setFormData(prev => ({ ...prev, translations: newTranslations }));
                              }}
                              className={`w-full ${
                                formData.translations.find(t => t.questionId === question.id)?.image
                                  ? "h-[calc(100vh-32rem)]" // 有圖片時的高度
                                  : "min-h-[200px]"         // 無圖片時的最小高度
                              }`}
                              placeholder="請輸入翻譯"
                            />
                          </div>

                          {formData.translations.find(t => t.questionId === question.id)?.image && (
                            <div className="relative">
                              <Card>
                                <CardContent className="p-4">
                                  <img
                                    src={formData.translations.find(t => t.questionId === question.id)?.image?.preview}
                                    alt={`翻譯題目 ${index + 1} 的圖片`}
                                    className="w-full h-auto rounded-md"
                                  />
                                </CardContent>
                              </Card>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              ))}

              <Card>
                <CardContent className="p-6">
                  <div className="w-[200px]">
                    <Label htmlFor="lang" className="mb-2 block">批改說明語言 *</Label>
                    <Select
                      value={formData.writing.lang}
                      onValueChange={(value) => setFormData(prev => ({
                        ...prev,
                        writing: { ...prev.writing, lang: value }
                      }))}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="請選擇語言" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="繁體中文">繁體中文</SelectItem>
                        <SelectItem value="日本語">日本語</SelectItem>
                        <SelectItem value="한국어">한국어</SelectItem>
                        <SelectItem value="Tiếng Việt">Tiếng Việt</SelectItem>
                        <SelectItem value="简体中文">简体中文</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                </CardContent>
              </Card>
            </div>
          )}

          <div className="flex justify-end space-x-4">
            <Button type="submit" disabled={loading}>
              {loading ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  提交中...
                </>
              ) : '提交練習'}
            </Button>
          </div>
        </form>

        <Card>
          <CardContent className="p-6">
            <h3 className="text-lg font-semibold mb-4">使用說明</h3>
            <div className="space-y-2 text-sm text-gray-600">
              <p>1. 所有標註 * 的欄位為必填項目</p>
              <p>2. 您可以使用相機圖示上傳圖片，系統會自動識別文字</p>
              <p>3. 批改過程可能需要 2-3 分鐘，請耐心等待</p>
              <p>4. 每篇作文會消耗一次額度，請合理使用</p>
              <p>5. 如遇到問題，請聯繫系統管理員協助</p>
            </div>
          </CardContent>
        </Card>
      </main>
    </div>
  );
};

export default PracticePage;