// src/components/admin/SystemSettings.tsx

import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from '../ui/card';
import { Input } from '../ui/input';
import { Label } from '../ui/label';
import { Button } from '../ui/button';
import { Alert, AlertDescription } from '../ui/alert';
import { Checkbox } from '../ui/checkbox';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";

interface SystemConfig {
  allowUserRegistration: boolean;
  maxLoginAttempts: number;
  sessionTimeout: number;
  defaultQuota: number;
  maintenanceMode: boolean;
  apiKeys: {
    cozeApiKey: string;
    cozeWorkflowId: string;
  };
  emailSettings: {
    enabled: boolean;
    host: string;
    port: number;
    username: string;
    password: string;
  };
}

const SystemSettings: React.FC = () => {
  const [config, setConfig] = useState<SystemConfig>({
    allowUserRegistration: true,
    maxLoginAttempts: 5,
    sessionTimeout: 24,
    defaultQuota: 10,
    maintenanceMode: false,
    apiKeys: {
      cozeApiKey: '',
      cozeWorkflowId: '',
    },
    emailSettings: {
      enabled: false,
      host: '',
      port: 587,
      username: '',
      password: '',
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showApiKeys, setShowApiKeys] = useState(false);

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      const response = await fetch('/api/admin/system-settings', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch settings');
      }

      const data = await response.json();
      setConfig(data);
    } catch (error) {
      setError('無法載入系統設置');
    }
  };

  const handleSaveSettings = async () => {
    try {
      setIsLoading(true);
      setError('');
      setSuccessMessage('');

      const response = await fetch('/api/admin/system-settings', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(config),
      });

      if (!response.ok) {
        throw new Error('Failed to save settings');
      }

      setSuccessMessage('設置已成功保存');
    } catch (error) {
      setError('保存設置失敗');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="space-y-6">
      {/* 基本設置 */}
      <Card>
        <CardHeader>
          <CardTitle>基本設置</CardTitle>
          <CardDescription>設置系統的基本運行參數</CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="flex items-center space-x-2">
            <Checkbox
              label="允許用戶自行註冊"
              checked={config.allowUserRegistration}
              onChange={(e) =>
                setConfig((prev) => ({
                  ...prev,
                  allowUserRegistration: e.target.checked,
                }))
              }
            />
          </div>

          <div className="flex items-center space-x-2">
            <Checkbox
              label="維護模式"
              checked={config.maintenanceMode}
              onChange={(e) =>
                setConfig((prev) => ({
                  ...prev,
                  maintenanceMode: e.target.checked,
                }))
              }
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <Label htmlFor="maxLoginAttempts">最大登入嘗試次數</Label>
              <Input
                id="maxLoginAttempts"
                type="number"
                value={config.maxLoginAttempts}
                onChange={(e) =>
                  setConfig((prev) => ({
                    ...prev,
                    maxLoginAttempts: parseInt(e.target.value),
                  }))
                }
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="sessionTimeout">會話超時時間（小時）</Label>
              <Input
                id="sessionTimeout"
                type="number"
                value={config.sessionTimeout}
                onChange={(e) =>
                  setConfig((prev) => ({
                    ...prev,
                    sessionTimeout: parseInt(e.target.value),
                  }))
                }
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="defaultQuota">預設作文額度</Label>
              <Input
                id="defaultQuota"
                type="number"
                value={config.defaultQuota}
                onChange={(e) =>
                  setConfig((prev) => ({
                    ...prev,
                    defaultQuota: parseInt(e.target.value),
                  }))
                }
              />
            </div>
          </div>
        </CardContent>
      </Card>

      {/* API 設置 */}
      <Card>
        <CardHeader>
          <CardTitle>API 設置</CardTitle>
          <CardDescription>配置外部 API 連接參數</CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="cozeApiKey">Coze API Key</Label>
            <div className="flex space-x-2">
              <Input
                id="cozeApiKey"
                type={showApiKeys ? "text" : "password"}
                value={config.apiKeys.cozeApiKey}
                onChange={(e) =>
                  setConfig((prev) => ({
                    ...prev,
                    apiKeys: {
                      ...prev.apiKeys,
                      cozeApiKey: e.target.value,
                    },
                  }))
                }
              />
              <Button
                type="button"
                variant="outline"
                onClick={() => setShowApiKeys(!showApiKeys)}
              >
                {showApiKeys ? '隱藏' : '顯示'}
              </Button>
            </div>
          </div>

          <div className="space-y-2">
            <Label htmlFor="cozeWorkflowId">Coze Workflow ID</Label>
            <Input
              id="cozeWorkflowId"
              value={config.apiKeys.cozeWorkflowId}
              onChange={(e) =>
                setConfig((prev) => ({
                  ...prev,
                  apiKeys: {
                    ...prev.apiKeys,
                    cozeWorkflowId: e.target.value,
                  },
                }))
              }
            />
          </div>
        </CardContent>
      </Card>

      {/* 郵件設置 */}
      <Card>
        <CardHeader>
          <CardTitle>郵件設置</CardTitle>
          <CardDescription>配置系統郵件服務</CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="flex items-center space-x-2">
            <Checkbox
              label="啟用郵件通知"
              checked={config.emailSettings.enabled}
              onChange={(e) =>
                setConfig((prev) => ({
                  ...prev,
                  emailSettings: {
                    ...prev.emailSettings,
                    enabled: e.target.checked,
                  },
                }))
              }
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <Label htmlFor="emailHost">SMTP 主機</Label>
              <Input
                id="emailHost"
                value={config.emailSettings.host}
                onChange={(e) =>
                  setConfig((prev) => ({
                    ...prev,
                    emailSettings: {
                      ...prev.emailSettings,
                      host: e.target.value,
                    },
                  }))
                }
                disabled={!config.emailSettings.enabled}
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="emailPort">SMTP 端口</Label>
              <Input
                id="emailPort"
                type="number"
                value={config.emailSettings.port}
                onChange={(e) =>
                  setConfig((prev) => ({
                    ...prev,
                    emailSettings: {
                      ...prev.emailSettings,
                      port: parseInt(e.target.value),
                    },
                  }))
                }
                disabled={!config.emailSettings.enabled}
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="emailUsername">郵箱帳號</Label>
              <Input
                id="emailUsername"
                value={config.emailSettings.username}
                onChange={(e) =>
                  setConfig((prev) => ({
                    ...prev,
                    emailSettings: {
                      ...prev.emailSettings,
                      username: e.target.value,
                    },
                  }))
                }
                disabled={!config.emailSettings.enabled}
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="emailPassword">郵箱密碼</Label>
              <Input
                id="emailPassword"
                type="password"
                value={config.emailSettings.password}
                onChange={(e) =>
                  setConfig((prev) => ({
                    ...prev,
                    emailSettings: {
                      ...prev.emailSettings,
                      password: e.target.value,
                    },
                  }))
                }
                disabled={!config.emailSettings.enabled}
              />
            </div>
          </div>
        </CardContent>
      </Card>

      {/* 保存按鈕和消息提示 */}
      <div className="flex justify-end space-x-4">
        {error && (
          <Alert variant="destructive" className="flex-1">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
        {successMessage && (
          <Alert variant="default" className="flex-1">
            <AlertDescription>{successMessage}</AlertDescription>
          </Alert>
        )}
        <Button
          onClick={handleSaveSettings}
          disabled={isLoading}
        >
          {isLoading ? '保存中...' : '保存設置'}
        </Button>
      </div>
    </div>
  );
};

export default SystemSettings;