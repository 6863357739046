import React, { useState, useMemo } from 'react';
import { Card, CardContent } from '../../ui/card';
import { Checkbox } from '../../ui/checkbox';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../ui/tabs';
import { Volume2, Loader2 } from 'lucide-react';
import { Button } from '../../ui/button';
import { SearchInput } from '../../shared/SearchInput';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../../ui/tooltip';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableHeader, 
  TableRow 
} from '../../ui/table';
import { CorrectionData, BasicVocabulary, VocabularyItem } from '../../types/correction';

type TabType = 'basic' | 'incorrect' | 'improve' | 'better' | 'phrases';

interface VocabularyTabProps {
  data?: CorrectionData;
}

const CEFR_LEVELS = ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'] as const;
type CEFRLevel = typeof CEFR_LEVELS[number];

// 定義有效的詞性及其完整名稱
const PARTS_OF_SPEECH_INFO: Record<string, string> = {
  'N': 'Noun (名詞)',
  'V': 'Verb (動詞)',
  'Adj': 'Adjective (形容詞)',
  'Adv': 'Adverb (副詞)',
  'Prep': 'Preposition (介系詞)',
  'Conj': 'Conjunction (連接詞)',
  'Pron': 'Pronoun (代名詞)',
  'Int': 'Interjection (感嘆詞)',
  'Art': 'Article (冠詞)',
  'Det': 'Determiner (限定詞)',
  'Aux': 'Auxiliary Verb (助動詞)',
  'Modal': 'Modal Verb (情態動詞)',
  'Num': 'Numeral (數詞)',
  'Ph.V': 'Phrasal Verb (片語動詞)',
  'Abbr': 'Abbreviation (縮寫)',
  'Inf': 'Infinitive (不定詞)',
  'Ger': 'Gerund (動名詞)',
  'Part': 'Participle (分詞)',
} as const;

type PartOfSpeech = keyof typeof PARTS_OF_SPEECH_INFO;

// 檢查詞性是否有效的函數
const isValidPartOfSpeech = (pos: string | undefined | null): pos is PartOfSpeech => {
  if (!pos) return false;
  return pos in PARTS_OF_SPEECH_INFO;
};

// 格式化詞性顯示的函數
const formatPartOfSpeech = (pos: string | undefined | null): PartOfSpeech | '' => {
  if (!pos) return '';
  
  // 標準化映射
  const posMap: Record<string, PartOfSpeech> = {
    'n': 'N',
    'v': 'V',
    'adj': 'Adj',
    'adv': 'Adv',
    'prep': 'Prep',
    'conj': 'Conj',
    'pron': 'Pron',
    'int': 'Int',
    'art': 'Art',
    'det': 'Det',
    'aux': 'Aux',
    'modal': 'Modal',
    'num': 'Num',
    'noun': 'N',
    'verb': 'V',
    'adjective': 'Adj',
    'adverb': 'Adv',
    'preposition': 'Prep',
    'conjunction': 'Conj',
    'pronoun': 'Pron',
    'interjection': 'Int',
    'article': 'Art',
    'determiner': 'Det',
    'auxiliary': 'Aux',
    'numeral': 'Num'
  };

  const normalizedPos = pos.toLowerCase();
  const standardPos = posMap[normalizedPos] || pos as PartOfSpeech;
  
  return isValidPartOfSpeech(standardPos) ? standardPos : '';
};

// 詞性單元格元件
const PartOfSpeechCell: React.FC<{ pos: string | undefined | null }> = ({ pos }) => {
  const formattedPos = formatPartOfSpeech(pos);
  
  if (!formattedPos) return <TableCell>-</TableCell>;
  
  return (
    <TableCell>
      <TooltipProvider delayDuration={100}>
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="cursor-help text-gray-900 hover:text-gray-600 transition-colors">
              {formattedPos}
            </span>
          </TooltipTrigger>
          <TooltipContent
            side="top"
            align="center"
            className="bg-gray-100 px-3 py-1.5 text-sm text-gray-900 rounded-md shadow-sm border border-gray-200"
          >
            <p>{PARTS_OF_SPEECH_INFO[formattedPos]}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </TableCell>
  );
};

interface PlayButtonProps {
  word: string;
}

const PlayButton: React.FC<PlayButtonProps> = ({ word }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = async () => {
    if (isPlaying) return;
    
    setIsPlaying(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch('/api/tts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ text: word }),
      });
  
      if (!response.ok) throw new Error('TTS request failed');
      
      const { audioContent } = await response.json();
      const audio = new Audio(`data:audio/mp3;base64,${audioContent}`);
      await audio.play();
    } catch (error) {
      console.error('Failed to play audio:', error);
    } finally {
      setIsPlaying(false);
    }
  };
  
  return (
    <Button
      variant="speaker"
      size="icon"
      onClick={handlePlay}
      disabled={isPlaying}
    >
      {isPlaying ? (
        <Loader2 className="h-4 w-4 animate-spin" />
      ) : (
        <Volume2 className="h-4 w-4" />
      )}
    </Button>
  );
};

const VocabularyTab: React.FC<VocabularyTabProps> = ({ data }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedLetter, setSelectedLetter] = useState<string | null>(null);
  const [currentTab, setCurrentTab] = useState<TabType>('basic');
  const [filters, setFilters] = useState<Record<CEFRLevel, boolean>>(
    CEFR_LEVELS.reduce((acc, level) => ({ ...acc, [level]: true }), {} as Record<CEFRLevel, boolean>)
  );

  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

  // 根據當前標籤頁計算 CEFR 等級統計
  const vocabularyStats = useMemo(() => {
    const stats: Partial<Record<CEFRLevel, number>> = {};
    
    const calculateStats = (items: BasicVocabulary[] | VocabularyItem[], type: 'basic' | 'upgrade') => {
      items?.forEach(item => {
        let level: string | undefined;
        
        if (type === 'basic') {
          level = (item as BasicVocabulary).CEFR;
        } else {
          level = (item as VocabularyItem).CEFRLevel;
        }

        if (level && CEFR_LEVELS.includes(level as CEFRLevel)) {
          stats[level as CEFRLevel] = (stats[level as CEFRLevel] || 0) + 1;
        }
      });
    };

    switch (currentTab) {
      case 'basic':
        calculateStats(data?.Basic_voc || [], 'basic');
        break;
      case 'incorrect':
        calculateStats(data?.Vocabulary_Upgrade_lang1?.IncorrectVocabulary || [], 'upgrade');
        break;
      case 'improve':
        calculateStats(data?.Vocabulary_Upgrade_lang1?.ImproveVocabulary || [], 'upgrade');
        break;
      case 'better':
        calculateStats(data?.Vocabulary_Upgrade_lang1?.BetterVocabulary || [], 'upgrade');
        break;
    }

    return stats;
  }, [currentTab, data?.Basic_voc, data?.Vocabulary_Upgrade_lang1]);

  // 根據當前標籤頁計算可用字母
  const availableLetters = useMemo(() => {
    const letters = new Set<string>();

    switch (currentTab) {
      case 'basic':
        data?.Basic_voc?.forEach((word: BasicVocabulary) => {
          letters.add(word.word[0].toUpperCase());
        });
        break;
      case 'incorrect':
        data?.Vocabulary_Upgrade_lang1?.IncorrectVocabulary?.forEach((word: VocabularyItem) => {
          letters.add(word.Word[0].toUpperCase());
        });
        break;
      case 'improve':
        data?.Vocabulary_Upgrade_lang1?.ImproveVocabulary?.forEach((word: VocabularyItem) => {
          letters.add(word.Word[0].toUpperCase());
        });
        break;
      case 'better':
        data?.Vocabulary_Upgrade_lang1?.BetterVocabulary?.forEach((word: VocabularyItem) => {
          letters.add(word.Word[0].toUpperCase());
        });
        break;
    }

    return alphabet.filter(letter => letters.has(letter));
  }, [currentTab, data?.Basic_voc, data?.Vocabulary_Upgrade_lang1]);

  // JSX for CEFR filters
  const renderCEFRFilters = () => {
    if (currentTab === 'phrases') return null;

    return (
      <div className="flex flex-wrap gap-4">
        {CEFR_LEVELS.map(level => {
          const count = vocabularyStats[level] || 0;
          if (count === 0) return null;
          
          return (
            <label key={level} className="flex items-center">
              <Checkbox
                checked={filters[level]}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => 
                  setFilters(prev => ({
                    ...prev,
                    [level]: e.target.checked
                  }))
                }
              />
              <span className="ml-2">
                {level} ({count})
              </span>
            </label>
          );
        })}
      </div>
    );
  };

  // 字母選擇器
  const renderAlphabetSelector = () => (
    <div className="flex flex-wrap gap-1 my-4 px-4 py-2 bg-white rounded-lg shadow">
      {alphabet.map(letter => (
        <Button
          key={letter}
          variant={selectedLetter === letter ? 'default' : 'ghost'}
          size="sm"
          onClick={() => setSelectedLetter(selectedLetter === letter ? null : letter)}
          disabled={!availableLetters.includes(letter)}
          className={!availableLetters.includes(letter) ? 'opacity-50' : ''}
        >
          {letter}
        </Button>
      ))}
    </div>
  );

  // 基本詞彙表格
  const renderVocabularyTable = () => {
    const filteredWords = data?.Basic_voc?.filter((word: BasicVocabulary) => 
      filters[word.CEFR as CEFRLevel] &&
      (!searchTerm || 
        word.word.toLowerCase().includes(searchTerm.toLowerCase()) ||
        word.translation.toLowerCase().includes(searchTerm.toLowerCase())
      ) &&
      (!selectedLetter || word.word.toUpperCase().startsWith(selectedLetter))
    ).sort((a, b) => a.word.localeCompare(b.word)) || [];

    return (
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>單字</TableHead>
            <TableHead>發音</TableHead>
            <TableHead>音標</TableHead>
            <TableHead>翻譯</TableHead>
            <TableHead>詞性</TableHead>
            <TableHead>CEFR</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {filteredWords.map((word, index) => (
            <TableRow key={`${word.word}-${index}`}>
              <TableCell className="font-medium">{word.word}</TableCell>
              <TableCell>
                <PlayButton word={word.word} />
              </TableCell>
              <TableCell>{word.kk_phonetic}</TableCell>
              <TableCell>{word.translation}</TableCell>
              <PartOfSpeechCell pos={word.part_of_speech} />
              <TableCell>{word.CEFR}</TableCell>
            </TableRow>
          ))}
          {filteredWords.length === 0 && (
            <TableRow>
              <TableCell colSpan={6} className="text-center py-4 text-gray-500">
                無符合條件的單字
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    );
  };

  // 改進詞彙表格
  const renderUpgradeVocabulary = (type: 'better' | 'improve' | 'incorrect') => {
    const vocabularyList = {
      better: data?.Vocabulary_Upgrade_lang1?.BetterVocabulary || [],
      improve: data?.Vocabulary_Upgrade_lang1?.ImproveVocabulary || [],
      incorrect: data?.Vocabulary_Upgrade_lang1?.IncorrectVocabulary || []
    }[type];
  
    const filteredList = vocabularyList.filter((word: VocabularyItem) => 
      filters[word.CEFRLevel as CEFRLevel] &&
      (!searchTerm || 
        word.Word.toLowerCase().includes(searchTerm.toLowerCase()) ||
        word.WordTranslation.toLowerCase().includes(searchTerm.toLowerCase()) ||
        word.Suggestion.toLowerCase().includes(searchTerm.toLowerCase()) ||
        word.SuggestionTranslation.toLowerCase().includes(searchTerm.toLowerCase())
      ) &&
      (!selectedLetter || word.Word.toUpperCase().startsWith(selectedLetter))
    );
  
    return (
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead colSpan={5}>原始單字</TableHead>
            <TableHead colSpan={5}>建議單字</TableHead>
          </TableRow>
          <TableRow>
            <TableHead>單字</TableHead>
            <TableHead>發音</TableHead>
            <TableHead>音標</TableHead>
            <TableHead>翻譯</TableHead>
            <TableHead>詞性</TableHead>
            <TableHead>建議用詞</TableHead>
            <TableHead>音標</TableHead>
            <TableHead>翻譯</TableHead>
            <TableHead>詞性</TableHead>
            <TableHead>CEFR</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {filteredList.map((word: VocabularyItem, index) => (
            <TableRow key={`${word.Word}-${index}`}>
              {/* 原始單字部分 */}
              <TableCell>{word.Word}</TableCell>
              <TableCell>
                <PlayButton word={word.Word} />
              </TableCell>
              <TableCell>{word.KKPhonetic || '-'}</TableCell>
              <TableCell>{word.WordTranslation}</TableCell>
              <PartOfSpeechCell pos={word.PartofSpeech} />
              
              {/* 建議單字部分 */}
              <TableCell>{word.Suggestion}</TableCell>
              <TableCell>{word.SuggestionKKPhonetic || '-'}</TableCell>
              <TableCell>{word.SuggestionTranslation}</TableCell>
              <PartOfSpeechCell pos={word.PartofSpeech} />
              <TableCell>{word.SuggestionCEFR}</TableCell>
            </TableRow>
          ))}
          {filteredList.length === 0 && (
            <TableRow>
              <TableCell colSpan={10} className="text-center py-4 text-gray-500">
                無符合條件的單字
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    );
  };
  

  // 片語展示
  const renderAdvancedPhrases = () => {
    const phrases = data?.Vocabulary_Upgrade_lang1?.AdvancedPhrases || [];
    return (
      <div className="space-y-4">
        {phrases.map((phrase, index) => (
          <Card key={index}>
            <CardContent className="grid grid-cols-1 md:grid-cols-2 gap-4 p-4">
              <div>
                <h4 className="font-medium">原始片語</h4>
                <p>{phrase.OriginalExpression}</p>
              </div>
              <div>
                <h4 className="font-medium">建議用語</h4>
                <p>{phrase.RecommendedPhrase}</p>
              </div>
              <div className="md:col-span-2">
                <h4 className="font-medium">例句</h4>
                <p>{phrase.PhraseExample}</p>
              </div>
              <div className="md:col-span-2">
                <h4 className="font-medium">翻譯</h4>
                <p>{phrase.PhraseTranslation}</p>
              </div>
            </CardContent>
          </Card>
        ))}
        {phrases.length === 0 && (
          <div className="text-center py-4 text-gray-500">
            無進階片語
          </div>
        )}
      </div>
    );
  };

  // CEFR 等級資訊
  const cefrInfo = (
    <div className="mb-6 bg-white p-4 rounded-lg border">
      <h3 className="text-lg font-semibold mb-4">CEFR 等級評分</h3>
      <p className="text-gray-700">
        CEFR 等級: {data?.article_cerf_lang1?.CEFRLevel || '未提供'}
      </p>
      <p className="text-gray-600 mt-2">
        {data?.article_cerf_lang1?.VocabularyRange || '未提供'}
      </p>
    </div>
  );

  return (
    <Card>
      <CardContent className="p-6">
        {/* CEFR 等級資訊 */}
        {cefrInfo}

        {/* 搜尋和過濾 */}
        <div className="bg-blue-50 p-4 rounded-lg mb-6">
          <div className="mb-4">
            <SearchInput 
              value={searchTerm}
              onChange={(e: string) => setSearchTerm(e)}
              placeholder="搜尋單字..."
            />
          </div>
          {renderCEFRFilters()}
        </div>

        {/* 字母選擇器，只在非片語頁面顯示 */}
        {currentTab !== 'phrases' && renderAlphabetSelector()}

        {/* 詞彙內容 */}
        <Tabs defaultValue="basic" className="space-y-4">
          <TabsList>
            <TabsTrigger value="basic">擁有詞彙</TabsTrigger>
            <TabsTrigger value="incorrect">不正確詞彙</TabsTrigger>
            <TabsTrigger value="improve">改進詞彙</TabsTrigger>
            <TabsTrigger value="better">需具備的詞彙</TabsTrigger>
            <TabsTrigger value="phrases">建議的片語</TabsTrigger>
          </TabsList>

          <TabsContent value="basic">
            {renderVocabularyTable()}
          </TabsContent>
          <TabsContent value="incorrect">
            {renderUpgradeVocabulary('incorrect')}
          </TabsContent>
          <TabsContent value="improve">
            {renderUpgradeVocabulary('improve')}
          </TabsContent>
          <TabsContent value="better">
            {renderUpgradeVocabulary('better')}
          </TabsContent>
          <TabsContent value="phrases">
            {renderAdvancedPhrases()}
          </TabsContent>
        </Tabs>
      </CardContent>
    </Card>
  );
};

export default VocabularyTab;