// src/pages/essay-submission.tsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent } from '../components/ui/card';
import { Input } from '../components/ui/input';
import { Button } from '../components/ui/button';
import { Textarea } from '../components/ui/textarea';
import { Label } from '../components/ui/label';
import { Camera, Loader2 } from 'lucide-react';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../components/ui/select';
import { Alert, AlertDescription } from '../components/ui/alert';
import Header from '../components/Header';

interface EssaySubmissionProps {
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  username: string;
}

interface EssayStatistics {
  remainingEssays: number;
  totalEssays: number;
  monthlyEssays: number;
  averageScore: number;
}

const EssaySubmission: React.FC<EssaySubmissionProps> = ({
  setIsAuthenticated,
  username,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [statistics, setStatistics] = useState<EssayStatistics | null>(null);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    content: '',
    lang: '',
  });

  const navigate = useNavigate();

  // 載入統計資料
  useEffect(() => {
    const fetchStatistics = async () => {
      try {
        const response = await fetch('/api/essay/statistics/summary', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch statistics');
        }

        const data = await response.json();
        if (data.success) {
          setStatistics(data.data);
        }
      } catch (err) {
        console.error('Error fetching statistics:', err);
      }
    };

    fetchStatistics();
  }, []);

  const processImageWithOCR = async (file: File) => {
    const base64Image = await new Promise<string>((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result?.toString().split(',')[1];
        resolve(base64String || '');
      };
      reader.readAsDataURL(file);
    });

    try {
      const token = localStorage.getItem('token');
      const response = await fetch('/api/ocr', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ image: base64Image }),
      });

      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.message || '圖片處理失敗');
      }

      if (!data.success) {
        throw new Error(data.message || '圖片處理失敗');
      }

      return data.text;
    } catch (error) {
      console.error('OCR Error:', error);
      throw new Error(error instanceof Error ? error.message : '圖片處理失敗，請稍後再試');
    }
  };

  const handleImageUpload = async (
    e: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    const file = e.target.files?.[0];
    if (!file) return;

    setIsLoading(true);
    setError('');

    try {
      const text = await processImageWithOCR(file);
      setFormData((prev) => ({
        ...prev,
        [field]: text,
      }));
      setSuccessMessage('文字已成功提取並填入');
      setTimeout(() => setSuccessMessage(''), 3000); // 3秒後清除成功訊息
    } catch (err) {
      setError('圖片處理失敗，請稍後再試');
    } finally {
      setIsLoading(false);
    }
  };

  const validateForm = () => {
    if (!formData.title.trim()) {
      setError('請填寫作文標題');
      return false;
    }
    if (!formData.description.trim()) {
      setError('請填寫題目描述');
      return false;
    }
    if (!formData.content.trim()) {
      setError('請填寫作文內容');
      return false;
    }
    if (!formData.lang) {
      setError('請選擇批改說明語言');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      if ((statistics?.remainingEssays ?? 0) <= 0) {
        throw new Error('作文額度不足，請聯繫管理員');
      }

      const response = await fetch('/api/essay/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || '提交失敗');
      }

      const result = await response.json();

      if (!result.success) {
        throw new Error(result.message || '提交失敗');
      }

      // 跳轉到 loading 頁面
      navigate('/correction-loading');

      // 開始輪詢檢查狀態
      let attempts = 0;
      const maxAttempts = 60; // 最多等待 5 分鐘
      const checkStatus = async () => {
        try {
          const statusResponse = await fetch(`/api/essay/${result.essayId}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });

          if (!statusResponse.ok) {
            throw new Error('檢查狀態失敗');
          }

          const statusResult = await statusResponse.json();

          if (statusResult.success && statusResult.essay?.status === 'completed') {
            navigate(`/correction-result/${result.essayId}`);
            return;
          }

          attempts++;
          if (attempts >= maxAttempts) {
            throw new Error('批改超時，請稍後查看結果');
          }

          setTimeout(checkStatus, 5000);
        } catch (error) {
          navigate('/essay-submission');
          setError(error instanceof Error ? error.message : '批改失敗');
          setIsLoading(false);
        }
      };

      checkStatus();

    } catch (err) {
      console.error('批改請求失敗:', err);
      setError(err instanceof Error ? err.message : '提交失敗');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setFormData({
      title: '',
      description: '',
      content: '',
      lang: '',
    });
    setError('');
    setSuccessMessage('');
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <Header setIsAuthenticated={setIsAuthenticated} username={username} />
      <div className="flex-1 p-6">
        <div className="max-w-4xl mx-auto">
          {statistics && (
            <div className="mb-6">
              <Card>
                <CardContent className="pt-6">
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                    <div className="text-center p-4">
                      <div className="text-sm text-gray-500">剩餘額度</div>
                      <div className="text-2xl font-bold text-blue-600">
                        {statistics.remainingEssays}
                      </div>
                    </div>
                    <div className="text-center p-4">
                      <div className="text-sm text-gray-500">本月提交</div>
                      <div className="text-2xl font-bold">
                        {statistics.monthlyEssays}
                      </div>
                    </div>
                    <div className="text-center p-4">
                      <div className="text-sm text-gray-500">總提交數</div>
                      <div className="text-2xl font-bold">
                        {statistics.totalEssays}
                      </div>
                    </div>
                    <div className="text-center p-4">
                      <div className="text-sm text-gray-500">平均分數</div>
                      <div className="text-2xl font-bold">
                        {statistics.averageScore}
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          )}

          <Card>
            <CardContent className="pt-6">
              <h2 className="text-2xl font-bold mb-6">提交作文</h2>
              
              <form onSubmit={handleSubmit} className="space-y-6">
                {error && (
                  <Alert variant="destructive">
                    <AlertDescription>{error}</AlertDescription>
                  </Alert>
                )}

                {successMessage && (
                  <Alert>
                    <AlertDescription>{successMessage}</AlertDescription>
                  </Alert>
                )}

                <div className="space-y-2">
                  <div className="flex justify-between items-center">
                    <Label htmlFor="title">作文標題 *</Label>
                    <div className="relative">
                      <Button
                        type="button"
                        variant="outline"
                        size="icon"
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <Loader2 className="h-4 w-4 animate-spin" />
                        ) : (
                          <Camera className="h-4 w-4" />
                        )}
                      </Button>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleImageUpload(e, 'title')}
                        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                      />
                    </div>
                  </div>
                  <Input
                    id="title"
                    value={formData.title}
                    onChange={(e) =>
                      setFormData((prev) => ({ ...prev, title: e.target.value }))
                    }
                    placeholder="請輸入作文標題"
                    required
                  />
                </div>

                <div className="space-y-2">
                  <div className="flex justify-between items-center">
                    <Label htmlFor="description">題目描述 *</Label>
                    <div className="relative">
                      <Button
                        type="button"
                        variant="outline"
                        size="icon"
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <Loader2 className="h-4 w-4 animate-spin" />
                        ) : (
                          <Camera className="h-4 w-4" />
                        )}
                      </Button>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleImageUpload(e, 'description')}
                        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                      />
                    </div>
                  </div>
                  <Textarea
                    id="description"
                    value={formData.description}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        description: e.target.value,
                      }))
                    }
                    placeholder="請輸入題目描述"
                    required
                  />
                </div>

                <div className="space-y-2">
                  <div className="flex justify-between items-center">
                    <Label htmlFor="content">作文內容 *</Label>
                    <div className="relative">
                      <Button
                        type="button"
                        variant="outline"
                        size="icon"
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <Loader2 className="h-4 w-4 animate-spin" />
                        ) : (
                          <Camera className="h-4 w-4" />
                        )}
                      </Button>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleImageUpload(e, 'content')}
                        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                      />
                    </div>
                  </div>
                  <Textarea
                    id="content"
                    value={formData.content}
                    onChange={(e) =>
                      setFormData((prev) => ({ ...prev, content: e.target.value }))
                    }
                    placeholder="請輸入作文內容"
                    className="min-h-[200px]"
                    required
                  />
                </div>

                <div className="space-y-2">
                  <Label>選擇批改說明語言 *</Label>
                  <Select
                    value={formData.lang}
                    onValueChange={(value) =>
                      setFormData((prev) => ({ ...prev, lang: value }))
                    }
                  >
                    <SelectTrigger className="w-[200px]">
                      <SelectValue placeholder="請選擇語言" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectItem value="繁體中文">繁體中文</SelectItem>
                        <SelectItem value="日本語">日本語</SelectItem>
                        <SelectItem value="한국어">한국어</SelectItem>
                        <SelectItem value="Tiếng Việt">Tiếng Việt</SelectItem>
                        <SelectItem value="简体中文">简体中文</SelectItem>
                        <SelectItem value="Bahasa Melayu">Bahasa Melayu</SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>

                <div className="flex items-center justify-between space-x-4">
                  <Button
                    type="button"
                    variant="outline"
                    onClick={handleCancel}
                    disabled={isLoading}
                  >
                    重置
                  </Button>
                  <div className="flex items-center space-x-2">
                    {statistics && (
                      <span className="text-sm text-gray-500">
                      剩餘額度：{statistics.remainingEssays}
                    </span>
                  )}
                  <Button
                    type="submit"
                    disabled={isLoading || (statistics?.remainingEssays ?? 0) <= 0}
                  >
                    {isLoading ? (
                      <div className="flex items-center">
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        處理中...
                      </div>
                    ) : (
                      '送出批改'
                    )}
                  </Button>
                </div>
              </div>

              {statistics && statistics.remainingEssays <= 0 && (
                <Alert>
                  <AlertDescription>
                    您的作文額度已用完，請聯繫管理員增加額度。
                  </AlertDescription>
                </Alert>
              )}
            </form>
          </CardContent>
        </Card>

        <Card className="mt-6">
          <CardContent className="pt-6">
            <h3 className="text-lg font-semibold mb-4">使用說明</h3>
            <div className="space-y-2 text-sm text-gray-600">
              <p>1. 所有標註 * 的欄位為必填項目</p>
              <p>2. 您可以使用相機圖示上傳圖片，系統會自動識別文字</p>
              <p>3. 批改過程可能需要 2-3 分鐘，請耐心等待</p>
              <p>4. 每篇作文會消耗一次額度，請合理使用</p>
              <p>5. 如遇到問題，請聯繫系統管理員協助</p>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  </div>
);
};

export default EssaySubmission;