// src/components/admin/UserManagement.tsx

import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '../../components/ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../components/ui/table';
import { Button } from '../../components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from '../../components/ui/dialog';
import { Input } from '../../components/ui/input';
import { Label } from '../../components/ui/label';
import { Alert, AlertDescription } from '../../components/ui/alert';
import { Badge } from '../../components/ui/badge';
import { Checkbox } from '../../components/ui/checkbox';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";

interface User {
  _id: string;
  username: string;
  realName: string;
  role: string;
  school: string;
  className: string;
  isActive: boolean;
}

interface EditForm {
  username: string;
  password?: string;
  realName: string;
  school: string;
  className: string;
  role: string;
  isActive: boolean;
}


const UserManagement: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showResetPasswordDialog, setShowResetPasswordDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [userToDelete, setUserToDelete] = useState<string | null>(null);


  const [editForm, setEditForm] = useState<EditForm>({
    username: '',
    password: '',
    realName: '',
    school: '',
    className: '',
    role: 'student_b',
    isActive: true
  });

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleLocalError = (message: string) => {
    setError(message);
  };

  const fetchUsers = async () => {
    try {
      setIsLoading(true);
      const response = await fetch('/api/admin/users', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch users');
      }

      const data = await response.json();
      if (data.success) {
        setUsers(data.users);
      } else {
        throw new Error(data.message || '獲取用戶列表失敗');
      }
    } catch (error) {
      setError(error instanceof Error ? error.message : '獲取用戶列表失敗');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const method = selectedUser ? 'PUT' : 'POST';
      const url = selectedUser ? `/api/admin/users/${selectedUser._id}` : '/api/admin/users';

      if (!selectedUser && !editForm.password) {
        setError('新增用戶時密碼為必填項');
        setIsLoading(false);
        return;
      }


      const userData = selectedUser
        ? {
            realName: editForm.realName,
            role: editForm.role,
            school: editForm.school,
            className: editForm.className,
            isActive: editForm.isActive
          }
        : {
            username: editForm.username,
            password: editForm.password,
            realName: editForm.realName,
            role: editForm.role,
            school: editForm.school,
            className: editForm.className,
            isActive: editForm.isActive
          };

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(userData),
      });

      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.message || '操作失敗');
      }

      await fetchUsers();
      setShowEditDialog(false);
      setEditForm({
        username: '',
        password: '',
        realName: '',
        school: '',
        className: '',
        role: 'student_b',
        isActive: true
      });
      
    } catch (error) {
      handleLocalError(error instanceof Error ? error.message : '操作失敗');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteClick = (userId: string) => {
    setUserToDelete(userId);
    setShowDeleteDialog(true);
  };

  const handleDeleteConfirm = async () => {
    if (!userToDelete) return;

    try {
      setIsLoading(true);
      const response = await fetch(`/api/admin/users/${userToDelete}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.message || '刪除失敗');
      }

      await fetchUsers();
      setShowDeleteDialog(false);
    } catch (error) {
      setError(error instanceof Error ? error.message : '刪除用戶失敗');
    } finally {
      setIsLoading(false);
      setUserToDelete(null);
    }
  };

  const handleResetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedUser) return;

    try {
      setIsLoading(true);
      const response = await fetch(`/api/admin/users/${selectedUser._id}/reset-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ newPassword }),
      });

      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.message || '重置密碼失敗');
      }

      setShowResetPasswordDialog(false);
      setNewPassword('');
    } catch (error) {
      setError(error instanceof Error ? error.message : '重置密碼失敗');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-between items-center">
          <CardTitle>用戶管理</CardTitle>
          <Button onClick={() => {
            setSelectedUser(null);
            setEditForm({
              username: '',
              password: '',
              realName: '',
              school: '',
              className: '',
              role: 'student_b',
              isActive: true
            });
            setError('');
            setShowEditDialog(true);
          }}>
            新增用戶
          </Button>
        </div>
      </CardHeader>
      <CardContent>
        {error && (
          <Alert variant="destructive" className="mb-4">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
        
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>用戶名</TableHead>
              <TableHead>真實姓名</TableHead>
              <TableHead>角色</TableHead>
              <TableHead>學校</TableHead>
              <TableHead>班級</TableHead>
              <TableHead>狀態</TableHead>
              <TableHead>操作</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user._id}>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.realName}</TableCell>
                <TableCell>
                  {user.role === 'student_b' ? '校園用戶' : 
                   user.role === 'student_c' ? '個人用戶' : 
                   user.role === 'teacher' ? '教師' :
                   user.role === 'sales' ? '業務' : '管理員'}
                </TableCell>
                <TableCell>
                  {(user.role === 'student_b' || user.role === 'teacher') ? user.school : '-'}
                </TableCell>
                <TableCell>
                  {(user.role === 'student_b' || user.role === 'teacher') ? user.className : '-'}
                </TableCell>
                <TableCell>
                  <Badge variant={user.isActive ? "success" : "default"}>
                    {user.isActive ? '啟用' : '停用'}
                  </Badge>
                </TableCell>
                <TableCell>
                  <div className="flex space-x-2">
                    <Button
                      variant="outline"
                      onClick={() => {
                        setSelectedUser(user);
                        setEditForm({
                          username: user.username,
                          realName: user.realName,
                          school: user.school,
                          className: user.className,
                          role: user.role,
                          isActive: user.isActive
                        });
                        setError('');
                        setShowEditDialog(true);
                      }}
                    >
                      編輯
                    </Button>
                    <Button
                      variant="outline"
                      onClick={() => {
                        setSelectedUser(user);
                        setNewPassword('');
                        setError('');
                        setShowResetPasswordDialog(true);
                      }}
                    >
                      重置密碼
                    </Button>
                    <Button
                      variant="outline"
                      onClick={() => handleDeleteClick(user._id)}
                    >
                      刪除
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {/* 編輯/新增用戶對話框 */}
        <Dialog open={showEditDialog} onOpenChange={setShowEditDialog}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>
                {selectedUser ? '編輯用戶' : '新增用戶'}
              </DialogTitle>
            </DialogHeader>
            <form onSubmit={handleEdit} className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="username">用戶名</Label>
                <Input
                  id="username"
                  value={editForm.username}
                  onChange={(e) =>
                    setEditForm((prev) => ({
                      ...prev,
                      username: e.target.value,
                    }))
                  }
                  disabled={!!selectedUser}
                  required
                />
              </div>

              {!selectedUser && (
                <div className="space-y-2">
                  <Label htmlFor="password">密碼</Label>
                  <Input
                    id="password"
                    type="password"
                    value={editForm.password}
                    onChange={(e) =>
                      setEditForm((prev) => ({
                        ...prev,
                        password: e.target.value,
                      }))
                    }
                    required
                  />
                </div>
              )}

              <div className="space-y-2">
                <Label htmlFor="realName">真實姓名</Label>
                <Input
                  id="realName"
                  value={editForm.realName}
                  onChange={(e) =>
                    setEditForm((prev) => ({
                      ...prev,
                      realName: e.target.value,
                    }))
                  }
                  required
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="role">角色</Label>
                <Select
                  value={editForm.role}
                  onValueChange={(value) =>
                    setEditForm((prev) => ({ ...prev, role: value }))
                  }
                >
                  <SelectTrigger>
                    <SelectValue placeholder="選擇角色" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="student_b">校園用戶</SelectItem>
                    <SelectItem value="student_c">個人用戶</SelectItem>
                    <SelectItem value="teacher">教師</SelectItem>
                    <SelectItem value="sales">業務</SelectItem>
                    <SelectItem value="admin">管理員</SelectItem>
                  </SelectContent>
                </Select>
              </div>

              {(editForm.role === 'student_b' || editForm.role === 'teacher') && (
                <>
                  <div className="space-y-2">
                    <Label htmlFor="school">學校</Label>
                    <Input
                      id="school"
                      value={editForm.school}
                      onChange={(e) =>
                        setEditForm((prev) => ({
                          ...prev,
                          school: e.target.value,
                        }))
                      }
                      required
                    />
                  </div>

                  <div className="space-y-2">
                    <Label htmlFor="className">班級</Label>
                    <Input
                      id="className"
                      value={editForm.className}
                      onChange={(e) =>
                        setEditForm((prev) => ({
                          ...prev,
                          className: e.target.value,
                        }))
                      }
                      required
                    />
                  </div>
                </>
              )}

              <div className="flex items-center space-x-2">
                <Checkbox
                  id="isActive"
                  checked={editForm.isActive}
                  onChange={(e) =>
                    setEditForm((prev) => ({
                      ...prev,
                      isActive: e.target.checked,
                    }))
                  }
                />
                <Label htmlFor="isActive">啟用帳號</Label>
              </div>

              <div className="flex justify-end space-x-2">
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => {
                    setShowEditDialog(false);
                    setError('');
                  }}
                >
                  取消
                </Button>
                <Button type="submit" disabled={isLoading}>
                  {isLoading ? '處理中...' : '保存'}
                </Button>
              </div>
            </form>
          </DialogContent>
        </Dialog>

        {/* 重置密碼對話框 */}
        <Dialog 
          open={showResetPasswordDialog} 
          onOpenChange={setShowResetPasswordDialog}
        >
          <DialogContent>
            <DialogHeader>
              <DialogTitle>重置密碼</DialogTitle>
            </DialogHeader>
            <form onSubmit={handleResetPassword} className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="newPassword">新密碼</Label>
                <Input
                  id="newPassword"
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
              </div>

              <div className="flex justify-end space-x-2">
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => {
                    setShowResetPasswordDialog(false);
                    setError('');
                  }}
                >
                  取消
                </Button>
                <Button type="submit" disabled={isLoading}>
                  {isLoading ? '處理中...' : '確認重置'}
                </Button>
              </div>
            </form>
          </DialogContent>
        </Dialog>

        {/* 刪除確認對話框 */}
        <Dialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>確認刪除</DialogTitle>
              <DialogDescription>
                確定要刪除此用戶嗎？此操作無法撤銷。
              </DialogDescription>
            </DialogHeader>
            <div className="flex justify-end space-x-2 mt-4">
              <Button
                variant="outline"
                onClick={() => {
                  setShowDeleteDialog(false);
                  setError('');
                }}
              >
                取消
              </Button>
              <Button
                variant="default"
                className="bg-red-600 hover:bg-red-700"
                onClick={handleDeleteConfirm}
                disabled={isLoading}
              >
                {isLoading ? '處理中...' : '確認刪除'}
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default UserManagement;