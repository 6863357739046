// src/components/Footer.tsx
import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="bg-kcblue text-white py-4 text-center mt-auto">
      <p className="text-sm">Powered by KCOnline @2024</p>
    </footer>
  );
};

export default Footer;
