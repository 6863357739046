import React, { useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import { Button } from '../ui/button';
import { useNavigate } from 'react-router-dom';
import Steps from '../test-creation/Steps';
import { useToast } from '../ui/use-toast';

interface PracticeCreationProps {
  examType: string;
}

interface FormData {
  testType: string;
  year: number | null;
  writing_id: string | null;
  translations_id: string[];
}

const StudentPracticeCreation: React.FC<PracticeCreationProps> = ({ examType }) => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [currentStep, setCurrentStep] = useState('選擇類型');
  const [formData, setFormData] = useState<FormData>({
    testType: '',
    year: null,
    writing_id: null,
    translations_id: []
  });

  const steps = [
    { title: '選擇類型', description: '選擇練習類型' },
    { title: '選擇年份', description: '選擇考試年份' },
    { title: '確認題目', description: '確認練習題目' }
  ];

  const handleTestTypeSelect = async (type: 'writing' | 'translation-writing') => {
    setFormData(prev => ({ ...prev, testType: type }));
    setCurrentStep('選擇年份');
  };

  const handleYearSelect = async (year: number) => {
    try {
      const response = await fetch(`/api/student/practice/fetch-exam/${examType}/${year}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (!response.ok) throw new Error('獲取題目失敗');

      const data = await response.json();
      if (data.success) {
        const newFormData: FormData = {
          ...formData,
          year,
          writing_id: data.writing_id || null,
          translations_id: data.translations_id || []
        };
        setFormData(newFormData);
        
        // 創建練習
        const createResponse = await fetch('/api/student/practice/create', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          body: JSON.stringify({
            testType: newFormData.testType,
            sourceType: 'past-exam',
            examType,
            year,
            writing_id: newFormData.writing_id,
            translations_id: newFormData.translations_id
          })
        });

        if (!createResponse.ok) throw new Error('創建練習失敗');

        const createData = await createResponse.json();
        if (createData.success) {
          toast({
            title: "成功",
            description: "練習創建成功",
          });
          navigate(`/practice/${createData.practiceId}`);
        }
      }
    } catch (error) {
      console.error('Error:', error);
      toast({
        variant: "destructive",
        title: "錯誤",
        description: error instanceof Error ? error.message : '創建練習失敗'
      });
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case '選擇類型':
        return (
          <div className="grid grid-cols-2 gap-4">
            <Card className="cursor-pointer hover:shadow-lg" onClick={() => handleTestTypeSelect('writing')}>
              <CardContent className="p-6">
                <h3 className="text-lg font-semibold">純作文</h3>
                <p className="text-sm text-gray-600">僅包含作文題目</p>
              </CardContent>
            </Card>
            <Card className="cursor-pointer hover:shadow-lg" onClick={() => handleTestTypeSelect('translation-writing')}>
              <CardContent className="p-6">
                <h3 className="text-lg font-semibold">翻譯+作文</h3>
                <p className="text-sm text-gray-600">包含翻譯和作文題目</p>
              </CardContent>
            </Card>
          </div>
        );

      case '選擇年份':
        return (
          <div className="grid grid-cols-5 gap-4">
            {Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i - 1911).map(year => (
              <Button
                key={year}
                variant="outline"
                onClick={() => handleYearSelect(year)}
              >
                {year} 年
              </Button>
            ))}
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>創建練習</CardTitle>
      </CardHeader>
      <CardContent>
        <Steps
          steps={steps}
          currentStep={steps.findIndex(step => step.title === currentStep)}
          completedSteps={{}}
        />
        {renderStep()}
      </CardContent>
    </Card>
  );
};

export default StudentPracticeCreation; 