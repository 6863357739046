import React from 'react';
import { Card, CardContent } from '../../ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../ui/tabs';

interface OCRComparisonTabProps {
  writingData?: {
    content: string;
    contentImages?: Array<{
      data: string;
      contentType: string;
      ocrText?: string;
      timestamp?: {
        $date: {
          $numberLong: string;
        };
      };
    }>;
    descriptionImages?: Array<{
      data: string;
      contentType: string;
      ocrText?: string;
      timestamp?: {
        $date: {
          $numberLong: string;
        };
      };
    }>;
  };
  translationData?: Array<{
    images?: Array<{
      data: string;
      contentType: string;
      ocrText: string;
    }>;
  }>;
}

const OCRComparisonTab: React.FC<OCRComparisonTabProps> = ({ writingData, translationData }) => {
  console.log('OCR Tab Data:', { writingData, translationData });

  console.log('Writing Content Images:', writingData?.contentImages?.map(img => ({
    hasData: !!img.data,
    contentType: img.contentType,
    hasOCR: !!img.ocrText,
    dataPrefix: img.data?.substring(0, 50) + '...',
    ocrTextPreview: img.ocrText?.substring(0, 50) + '...'
  })));

  console.log('Writing Description Images:', writingData?.descriptionImages?.map(img => ({
    hasData: !!img.data,
    contentType: img.contentType,
    hasOCR: !!img.ocrText,
    dataPrefix: img.data?.substring(0, 50) + '...',
    ocrTextPreview: img.ocrText?.substring(0, 50) + '...'
  })));

  console.log('Translation Images:', translationData?.map(t => 
    t.images?.map(img => ({
      hasData: !!img.data,
      contentType: img.contentType,
      hasOCR: !!img.ocrText,
      dataPrefix: img.data?.substring(0, 50) + '...',
      ocrTextPreview: img.ocrText?.substring(0, 50) + '...'
    }))
  ));

  const hasWritingImages = writingData?.contentImages?.length || writingData?.descriptionImages?.length;
  const hasTranslationImages = translationData?.some(t => t.images?.length);

  if (!hasWritingImages && !hasTranslationImages) {
    return (
      <div className="text-center p-6 text-gray-500">
        此次提交未包含 OCR 圖片
      </div>
    );
  }

  return (
    <Card>
      <CardContent className="p-6">
        <Tabs defaultValue={hasWritingImages ? "writing" : "translation"}>
          <TabsList>
            {hasWritingImages && <TabsTrigger value="writing">作文 OCR</TabsTrigger>}
            {hasTranslationImages && <TabsTrigger value="translation">翻譯 OCR</TabsTrigger>}
          </TabsList>

          {hasWritingImages && (
            <TabsContent value="writing" className="space-y-6">
              {writingData?.contentImages?.map((image, index) => (
                <div key={`content-${index}`} className="border rounded-lg p-4 space-y-4">
                  <h3 className="font-medium">作文內容圖片 {index + 1}</h3>
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <h4 className="text-sm font-medium mb-2">上傳的圖片</h4>
                      <div className="relative aspect-video">
                        <img
                          src={image.data.startsWith('data:') ? image.data : `data:${image.contentType};base64,${image.data}`}
                          alt={`Content image ${index + 1}`}
                          className="rounded border"
                        />
                      </div>
                    </div>
                    <div>
                      <h4 className="text-sm font-medium mb-2">OCR 辨識結果：</h4>
                      <div className="bg-gray-50 p-4 rounded-lg">
                        <p className="whitespace-pre-wrap">
                          {image.ocrText || '無 OCR 辨識結果'}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              {writingData?.descriptionImages?.map((image, index) => (
                <div key={`description-${index}`} className="border rounded-lg p-4 space-y-4">
                  <h3 className="font-medium">題目描述圖片 {index + 1}</h3>
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <h4 className="text-sm font-medium mb-2">上傳的圖片：</h4>
                      <div className="relative aspect-video">
                        <img
                          src={image.data.startsWith('data:') ? image.data : `data:${image.contentType};base64,${image.data}`}
                          alt={`Description image ${index + 1}`}
                          className="rounded border"
                        />
                      </div>
                    </div>
                    <div>
                      <h4 className="text-sm font-medium mb-2">OCR 辨識結果：</h4>
                      <div className="bg-gray-50 p-4 rounded-lg">
                        <p className="whitespace-pre-wrap">
                          {image.ocrText || '無 OCR 辨識結果'}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </TabsContent>
          )}

          {hasTranslationImages && (
            <TabsContent value="translation" className="space-y-6">
              {translationData?.map((translation, tIndex) => (
                translation.images?.map((image, iIndex) => (
                  <div key={`translation-${tIndex}-${iIndex}`} className="border rounded-lg p-4 space-y-4">
                    <h3 className="font-medium">翻譯題目 {tIndex + 1} 圖片 {iIndex + 1}</h3>
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <h4 className="text-sm font-medium mb-2">上傳的圖片：</h4>
                        <div className="relative aspect-video">
                          <img
                            src={`data:${image.contentType};base64,${image.data}`}
                            alt={`Translation image ${tIndex}-${iIndex}`}
                            className="rounded border"
                          />
                        </div>
                      </div>
                      <div>
                        <h4 className="text-sm font-medium mb-2">OCR 辨識結果：</h4>
                        <div className="bg-gray-50 p-4 rounded-lg">
                          <p className="whitespace-pre-wrap">{image.ocrText}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ))}
            </TabsContent>
          )}
        </Tabs>
      </CardContent>
    </Card>
  );
};

export default OCRComparisonTab;