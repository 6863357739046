import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { useReactToPrint } from 'react-to-print';
import { ReportGeneratorProps, CorrectionData } from '../types/correction';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { notoSansTCBase64 } from './fonts/noto-sans-tc';

// 更新類型定義
interface StructureSection {
  Hook?: string;
  "Background Information"?: string;
  "Thesis Statement"?: string;
  "Topic Sentence"?: string;
  "Supporting Details"?: string;
  "Explanation and Analysis"?: string;
  Transition?: string;
  "Restate the Thesis"?: string;
  Summary?: string;
  "Final Thought"?: string;
  "Missing Elements"?: string[];
}

interface StructureDivisionAgent {
  Introduction: StructureSection;
  "Body Paragraph": StructureSection;
  Conclusion: StructureSection;
  "Missing Elements": string[];
  "Structure Suggestions": string;
  completeness: number;
}

interface TranslatedStructureDivisionAgent {
  "Introduction (translated)": StructureSection;
  "Body Paragraph (translated)": StructureSection;
  "Conclusion (translated)": StructureSection;
  "Missing Elements"?: string[];
  "Structure Suggestions"?: string;
}

const ReportContent: React.FC<ReportGeneratorProps> = ({ data }) => {
  console.log('Report data:', data); // 添加日誌
  return (
    <div className="max-w-[210mm] mx-auto bg-white p-8">
      {/* 報告標題 */}
      <div className="text-center border-b pb-6 mb-8">
        <h1 className="text-3xl font-bold mb-4">作業批改報告</h1>
        <div className="grid grid-cols-2 gap-4 text-sm text-gray-600">
          <div className="text-left">
            <p>學生姓名：{data.studentName}</p>
            <p>班級：{data.className}</p>
            <p>指導老師：{data.teacherName}</p>
          </div>
          <div className="text-right">
            <p>作業項目：{data.title}</p>
            <p>學生作業內容字數：{data.content.length}</p>
            <p>CEFR 等級：{data.result.article_cerf_lang1?.CEFRLevel || 'N/A'}</p>
          </div>
        </div>
      </div>

      {/* 作文內容區 */}
      <section className="mb-8">
        <h2 className="text-xl font-bold mb-4 text-blue-800">作文內容</h2>
        <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
          <p className="whitespace-pre-wrap leading-relaxed">{data.content}</p>
        </div>
      </section>

      {/* 加分頁標記 */}
      <div className="page-break" />

      {/* 評分摘要區 */}
      <section className="mb-8">
        <h2 className="text-xl font-bold mb-4 text-blue-800">評分摘要</h2>
        {/* 總分 */}
        <div className="bg-blue-50 p-6 rounded-lg border border-blue-200 mb-6">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-bold text-blue-800">總分</h3>
            <span className="text-3xl font-bold text-blue-600">
              {calculateTotalScore(data.result)}/20
            </span>
          </div>
          <div className="space-y-2 text-sm">
            <p className="text-gray-800">{data.result.Suggestion_lang1['Overall Evaluation']}</p>
            <p className="text-gray-600">{data.result.Suggestion_lang2['Overall Evaluation (translated)']}</p>
          </div>
        </div>

        {/* 分項評分卡片 */}
        <div className="space-y-4">
          {[
            {
              title: "內容評分",
              score: data.result.ContentScoreAgen_lang1?.ContentScore || "0/5",
              review: data.result.ContentScoreAgen_lang1?.Review || "",
              translatedReview: data.result.ContentScoreAgen_lang2?.Review_translated || ""
            },
            {
              title: "文法結構",
              score: data.result.GrammarStructureScoreAgent_lang1?.Score || "0/5",
              review: data.result.GrammarStructureScoreAgent_lang1?.Review || "",
              translatedReview: data.result.GrammarStructureScoreAgent_lang2?.Review || ""
            },
            {
              title: "組織結構",
              score: data.result.OrganizationScoreAgent_lang1?.Score || "0/5",
              review: data.result.OrganizationScoreAgent_lang1?.Review || "",
              translatedReview: data.result.OrganizationScoreAgent_lang2?.Review || ""
            },
            {
              title: "詞彙拼寫",
              score: data.result.VocabularySpellingScoreAgent_lang1?.Score || "0/5",
              review: data.result.VocabularySpellingScoreAgent_lang1?.Review || "",
              translatedReview: data.result.VocabularySpellingScoreAgent_lang2?.Review || ""
            }
          ].map((item, index) => (
            <React.Fragment key={index}>
              <ScoreCard
                title={item.title}
                score={item.score}
                review={item.review}
                translatedReview={item.translatedReview}
              />
            </React.Fragment>
          ))}
        </div>
      </section>

      {/* 修改後的文章 */}
      <section className="mb-8">
        <h2 className="text-xl font-bold mb-4 text-blue-800">修改後的文章</h2>
        <div className="bg-white p-6 rounded-lg border border-gray-200">
          <p className="whitespace-pre-wrap leading-relaxed">
            {data.result.article_FIX?.['After Revision']}
          </p>
        </div>
      </section>

    
    {/* 添加分頁標記 */}
    <div className="page-break" />

    {/* 錯誤修正區 */}
      <section className="mb-8">
        <h2 className="text-xl font-bold mb-4 text-blue-800">錯誤修正</h2>
        <div className="space-y-4">
          {data.result.Suggestion_lang1?.['Errors and Corrections']?.map((error, index) => (
            <div key={index} className="bg-white p-4 rounded-lg border border-gray-200">
              <div className="grid grid-cols-1 gap-2">
                <div className="text-red-600">
                  <span className="font-medium">原句：</span>
                  {error['Original Sentence']}
                </div>
                <div className="text-green-600">
                  <span className="font-medium">修改：</span>
                  {error['Corrected Sentence']}
                </div>
                <div className="text-gray-600 text-sm">
                  <span className="font-medium">說明：</span>
                  {error['Reason for Modification']}
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

        
      {/* 添加分頁標記 */}
      <div className="page-break" />

      {/* CEFR 分析區 */}
      <section className="mb-8">
        <h2 className="text-xl font-bold mb-4 text-blue-800">CEFR 分析</h2>
        <div className="bg-white p-6 rounded-lg border border-gray-200 space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <h4 className="font-semibold mb-2">詞彙範疇</h4>
              <p>{data.result.article_cerf_lang1?.VocabularyRange}</p>
            </div>
            <div>
              <h4 className="font-semibold mb-2">句法結構</h4>
              <p>{data.result.article_cerf_lang1?.SyntaxStructure}</p>
            </div>
            <div>
              <h4 className="font-semibold mb-2">內容主題</h4>
              <p>{data.result.article_cerf_lang1?.ContentTheme}</p>
            </div>
            <div>
              <h4 className="font-semibold mb-2">表達意圖</h4>
              <p>{data.result.article_cerf_lang1?.IntentandExpression}</p>
            </div>
          </div>
          <div className="mt-4 pt-4 border-t">
            <h4 className="font-semibold mb-2">總結評估</h4>
            <p>{data.result.article_cerf_lang1?.Conclusion}</p>
          </div>
        </div>
      </section>

      {/* 添加分頁標記 */}
      <div className="page-break" />

      {/* 精確性分析區 */}
      <section className="mb-8">
        <h2 className="text-xl font-bold mb-4 text-blue-800">精確性分析</h2>
        <div className="bg-white p-6 rounded-lg border border-gray-200 space-y-6">
          {/* 文章準確性分析 */}
          <div>
            <h3 className="font-semibold mb-3">文章準確性分析</h3>
            <div className="grid gap-4">
              {data.result.article_Accuracy_lang1 && (
                <>
                  <div className="p-3 bg-gray-50 rounded">
                    <h4 className="font-medium text-blue-700 mb-2">語言和文法</h4>
                    <p className="text-gray-700">{data.result.article_Accuracy_lang1["Language and Grammar"]}</p>
                    <p className="text-gray-500 mt-1">{data.result.article_Accuracy_lang2?.["Language and Grammar (translated)"]}</p>
                  </div>
                  <div className="p-3 bg-gray-50 rounded">
                    <h4 className="font-medium text-blue-700 mb-2">邏輯謬誤</h4>
                    <p className="text-gray-700">{data.result.article_Accuracy_lang1["Logical Flaws"]}</p>
                    <p className="text-gray-500 mt-1">{data.result.article_Accuracy_lang2?.["Logical Flaws (translated)"]}</p>
                  </div>
                  <div className="p-3 bg-gray-50 rounded">
                    <h4 className="font-medium text-blue-700 mb-2">事實錯誤</h4>
                    <p className="text-gray-700">{data.result.article_Accuracy_lang1["Factual Errors"]}</p>
                    <p className="text-gray-500 mt-1">{data.result.article_Accuracy_lang2?.["Factual Errors (translated)"]}</p>
                  </div>
                  <div className="p-3 bg-gray-50 rounded">
                    <h4 className="font-medium text-blue-700 mb-2">敘事清晰度</h4>
                    <p className="text-gray-700">{data.result.article_Accuracy_lang1["Narrative Clarity"]}</p>
                    <p className="text-gray-500 mt-1">{data.result.article_Accuracy_lang2?.["Narrative Clarity (translated)"]}</p>
                  </div>
                  <div className="p-3 bg-gray-50 rounded">
                    <h4 className="font-medium text-blue-700 mb-2">總結</h4>
                    <p className="text-gray-700">{data.result.article_Accuracy_lang1.Summary}</p>
                    <p className="text-gray-500 mt-1">{data.result.article_Accuracy_lang2?.["Summary (translated)"]}</p>
                  </div>
                </>
              )}
            </div>
          </div>         
        </div>
      </section>

      {/* 添加分頁標記 */}
      <div className="page-break" />

      {/* 作文結構分析區 */}
      <section className="mb-8">
        <h2 className="text-xl font-bold mb-4 text-blue-800">作文結構分析</h2>
        <div className="bg-white p-6 rounded-lg border border-gray-200 space-y-6">
          {/* 結構進度條表格 */}
          <div className="mb-6">
            {/* 標題列 */}
            <div className="bg-gradient-to-r from-blue-500 via-purple-500 to-purple-600 text-Rwhite p-3 grid grid-cols-4">
              <div className="col-span-1 text-center">引言 Introduction</div>
              <div className="col-span-2 text-center">主體段落 Body Paragraph</div>
              <div className="col-span-1 text-center">結論 Conclusion</div>
            </div>

            {/* 進度條和完整度 */}
            <div className="bg-gray-50 p-4">
              {/* 步驟指示器和完整度 */}
              <div className="flex justify-between items-center">
                <div className="flex-grow grid grid-cols-10 gap-1">
                  {[...Array(10)].map((_, index) => {
                    let bgColor = "bg-blue-500";
                    if (index >= 3 && index <= 6) bgColor = "bg-purple-500";
                    if (index >= 7) bgColor = "bg-purple-600";
                    
                    const stepStatus = index < 3 ? 
                      checkStepCompletion(data.result.StructureDivisionAgent_lang1?.Introduction, index, 'Introduction') :
                      index <= 6 ?
                      checkStepCompletion(data.result.StructureDivisionAgent_lang1?.["Body Paragraph"], index, 'Body Paragraph') :
                      checkStepCompletion(data.result.StructureDivisionAgent_lang1?.Conclusion, index, 'Conclusion');

                    return (
                      <div key={index} className="relative">
                        <div className="flex flex-col items-center">
                          <div className={`h-8 w-8 rounded-full ${stepStatus.isComplete ? bgColor : 'bg-gray-300'} flex items-center justify-center text-white text-sm mb-1`}>
                            {index + 1}
                          </div>
                          {stepStatus.isComplete ? (
                            <div className="text-green-500 text-lg mb-2">✓</div>
                          ) : (
                            <div className="text-red-500 text-lg mb-2">✗</div>
                          )}
                          <div className="text-xs text-center text-blue-600">
                            {index === 0 && "Hook"}
                            {index === 1 && "Background Information"}
                            {index === 2 && "Thesis Statement"}
                            {index === 3 && "Topic Sentence"}
                            {index === 4 && "Supporting Details"}
                            {index === 5 && "Explanation and Analysis"}
                            {index === 6 && "Transition"}
                            {index === 7 && "Restate the Thesis"}
                            {index === 8 && "Summary"}
                            {index === 9 && "Final Thought"}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="ml-4 text-2xl font-bold text-gray-700">
                  {calculateOverallCompleteness(data.result.StructureDivisionAgent_lang1 as StructureDivisionAgent)}%
                </div>
              </div>
            </div>

            {/* 修改結構建議部分 */}
            {data.result.StructureDivisionAgent_lang1 && (
              <>
                <div className="mt-6 p-4 bg-red-50 rounded-lg">
                  <h4 className="font-medium text-red-700 mb-3">缺少的結構元素</h4>
                  <div className="space-y-2">
                    {(data.result.StructureDivisionAgent_lang1 as StructureDivisionAgent)?.["Missing Elements"]?.map((element: string, index: number) => (
                      <div key={index} className="text-red-600">
                        • {element}
                        {(data.result.StructureDivisionAgent_lang2 as TranslatedStructureDivisionAgent)?.["Missing Elements"]?.[index] && (
                          <p className="text-red-400 text-sm ml-4">
                            {(data.result.StructureDivisionAgent_lang2 as TranslatedStructureDivisionAgent)?.["Missing Elements"]?.[index]}
                          </p>
                        )}
                      </div>
                    ))}
                  </div>
                  {(data.result.StructureDivisionAgent_lang1 as StructureDivisionAgent)?.["Structure Suggestions"] && (
                    <div className="mt-4 p-4 bg-blue-50 rounded">
                      <h4 className="font-medium text-blue-700 mb-2">結構改進建議</h4>
                      <p className="text-blue-600">
                        {(data.result.StructureDivisionAgent_lang1 as StructureDivisionAgent)?.["Structure Suggestions"]}
                      </p>
                      {(data.result.StructureDivisionAgent_lang2 as TranslatedStructureDivisionAgent)?.["Structure Suggestions"] && (
                        <p className="text-blue-500 mt-1">
                          {(data.result.StructureDivisionAgent_lang2 as TranslatedStructureDivisionAgent)?.["Structure Suggestions"]}
                        </p>
                      )}
                    </div>
                  )}vv
                </div>
              </>
            )}
          </div>

          {/* 段落結構分析 */}
          <div className="space-y-4">
            {/* 引言部分 */}
            <div className="bg-gray-50 p-4 rounded-lg">
              <h4 className="font-medium text-blue-700 mb-3">引言 Introduction</h4>
              <div className="space-y-3">
                <div>
                  <p className="font-medium text-gray-700">開場引子 Hook</p>
                  <p className="text-gray-600 mt-1">{data.result.StructureDivisionAgent_lang1?.Introduction?.Hook}</p>
                  <p className="text-gray-500 mt-1">{data.result.StructureDivisionAgent_lang2?.["Introduction (translated)"]?.Hook}</p>
                </div>
                <div>
                  <p className="font-medium text-gray-700">背景資訊 Background Information</p>
                  <p className="text-gray-600 mt-1">{data.result.StructureDivisionAgent_lang1?.Introduction?.["Background Information"]}</p>
                  <p className="text-gray-500 mt-1">{data.result.StructureDivisionAgent_lang2?.["Introduction (translated)"]?.["Background Information"]}</p>
                </div>
                <div>
                  <p className="font-medium text-gray-700">主題陳述 Thesis Statement</p>
                  <p className="text-gray-600 mt-1">{data.result.StructureDivisionAgent_lang1?.Introduction?.["Thesis Statement"]}</p>
                  <p className="text-gray-500 mt-1">{data.result.StructureDivisionAgent_lang2?.["Introduction (translated)"]?.["Thesis Statement"]}</p>
                </div>
                {hasMissingElements(data.result.StructureDivisionAgent_lang1?.Introduction) && (
                  <div className="mt-2 p-2 bg-red-50 rounded">
                    <p className="text-red-600 font-medium">缺少元素：</p>
                    <ul className="list-disc list-inside text-red-500">
                      {data.result.StructureDivisionAgent_lang1?.Introduction?.["Missing Elements"]?.map((element, idx) => (
                        <li key={idx}>{element}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            {/* 主體段落 */}
            <div className="bg-gray-50 p-4 rounded-lg">
              <h4 className="font-medium text-blue-700 mb-3">主體段落 Body Paragraph</h4>
              <div className="space-y-3">
                <div>
                  <p className="font-medium text-gray-700">主題句 Topic Sentence</p>
                  <p className="text-gray-600 mt-1">{data.result.StructureDivisionAgent_lang1?.["Body Paragraph"]?.["Topic Sentence"]}</p>
                  <p className="text-gray-500 mt-1">{data.result.StructureDivisionAgent_lang2?.["Body Paragraph (translated)"]?.["Topic Sentence"]}</p>
                </div>
                <div>
                  <p className="font-medium text-gray-700">支持細節 Supporting Details</p>
                  <p className="text-gray-600 mt-1">{data.result.StructureDivisionAgent_lang1?.["Body Paragraph"]?.["Supporting Details"]}</p>
                  <p className="text-gray-500 mt-1">{data.result.StructureDivisionAgent_lang2?.["Body Paragraph (translated)"]?.["Supporting Details"]}</p>
                </div>
                <div>
                  <p className="font-medium text-gray-700">解釋分析 Explanation and Analysis</p>
                  <p className="text-gray-600 mt-1">{data.result.StructureDivisionAgent_lang1?.["Body Paragraph"]?.["Explanation and Analysis"]}</p>
                  <p className="text-gray-500 mt-1">{data.result.StructureDivisionAgent_lang2?.["Body Paragraph (translated)"]?.["Explanation and Analysis"]}</p>
                </div>
                <div>
                  <p className="font-medium text-gray-700">過渡句 Transition</p>
                  <p className="text-gray-600 mt-1">{data.result.StructureDivisionAgent_lang1?.["Body Paragraph"]?.Transition}</p>
                  <p className="text-gray-500 mt-1">{data.result.StructureDivisionAgent_lang2?.["Body Paragraph (translated)"]?.Transition}</p>
                </div>
                {hasMissingElements(data.result.StructureDivisionAgent_lang1?.["Body Paragraph"]) && (
                  <div className="mt-2 p-2 bg-red-50 rounded">
                    <p className="text-red-600 font-medium">缺少元素：</p>
                    <ul className="list-disc list-inside text-red-500">
                      {data.result.StructureDivisionAgent_lang1?.["Body Paragraph"]?.["Missing Elements"]?.map((element, idx) => (
                        <li key={idx}>{element}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            {/* 結論部分 */}
            <div className="bg-gray-50 p-4 rounded-lg">
              <h4 className="font-medium text-blue-700 mb-3">結論 Conclusion</h4>
              <div className="space-y-3">
                <div>
                  <p className="font-medium text-gray-700">重申主題 Restate the Thesis</p>
                  <p className="text-gray-600 mt-1">{data.result.StructureDivisionAgent_lang1?.Conclusion?.["Restate the Thesis"]}</p>
                  <p className="text-gray-500 mt-1">{data.result.StructureDivisionAgent_lang2?.["Conclusion (translated)"]?.["Restate the Thesis"]}</p>
                </div>
                <div>
                  <p className="font-medium text-gray-700">總結 Summary</p>
                  <p className="text-gray-600 mt-1">{data.result.StructureDivisionAgent_lang1?.Conclusion?.Summary}</p>
                  <p className="text-gray-500 mt-1">{data.result.StructureDivisionAgent_lang2?.["Conclusion (translated)"]?.Summary}</p>
                </div>
                <div>
                  <p className="font-medium text-gray-700">結語 Final Thought</p>
                  <p className="text-gray-600 mt-1">{data.result.StructureDivisionAgent_lang1?.Conclusion?.["Final Thought"]}</p>
                  <p className="text-gray-500 mt-1">{data.result.StructureDivisionAgent_lang2?.["Conclusion (translated)"]?.["Final Thought"]}</p>
                </div>
                {hasMissingElements(data.result.StructureDivisionAgent_lang1?.Conclusion) && (
                  <div className="mt-2 p-2 bg-red-50 rounded">
                    <p className="text-red-600 font-medium">缺少元素：</p>
                    <ul className="list-disc list-inside text-red-500">
                      {data.result.StructureDivisionAgent_lang1?.Conclusion?.["Missing Elements"]?.map((element, idx) => (
                        <li key={idx}>{element}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* 添加分頁標記 */}
      <div className="page-break" />

      {/* 翻譯批改結果 */}
      {data.translationResults && data.translationResults.length > 0 && (
        <>
          {data.translationResults.map((translation, index) => {
            const output = translation.result?.output;
            if (!output) return null;

            return (
              <React.Fragment key={index}>
                {index > 0 && <div className="page-break" />}
                <section className="mb-8">
                  <h2 className="text-xl font-bold mb-4 text-blue-800">
                    翻譯批改結果 - 題目 {index + 1}
                  </h2>
                  <div className="bg-white p-6 rounded-lg border border-gray-200 space-y-4">
                    {/* 原文與翻譯 */}
                    <div className="mb-4">
                      <h3 className="font-semibold mb-2">原文</h3>
                      <p className="bg-gray-50 p-3 rounded">{translation.content}</p>
                    </div>

                    {/* 評分項目 */}
                    <div className="grid grid-cols-1 gap-4">
                      {/* 忠實度 */}
                      <div className="border-t pt-4">
                        <h4 className="font-semibold text-blue-700">忠實度評分</h4>
                        <p className="text-gray-600">{output.Faithfulness?.Feedback}</p>
                        <p className="text-gray-500">{output.Faithfulness?.FeedbackTranslation}</p>
                        {output.Faithfulness?.Examples?.length > 0 && (
                          <div className="mt-2">
                            <h5 className="font-medium">例句：</h5>
                            {output.Faithfulness.Examples.map((example, i) => (
                              <div key={i} className="ml-4 mt-1">
                                <p>{example.ExampleText}</p>
                                <p className="text-gray-500">{example.Translation}</p>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>

                      {/* 表達性 */}
                      <div className="border-t pt-4">
                        <h4 className="font-semibold text-blue-700">表達性評分</h4>
                        <p className="text-gray-600">{output.Expressiveness?.Feedback}</p>
                        <p className="text-gray-500">{output.Expressiveness?.FeedbackTranslation}</p>
                      </div>

                      {/* 優雅度 */}
                      <div className="border-t pt-4">
                        <h4 className="font-semibold text-blue-700">優雅度評分</h4>
                        <p className="text-gray-600">{output.Elegance?.Feedback}</p>
                        <p className="text-gray-500">{output.Elegance?.FeedbackTranslation}</p>
                      </div>

                      {/* 整體評估 */}
                      <div className="border-t pt-4">
                        <h4 className="font-semibold text-blue-700">整體評估</h4>
                        <p className="text-gray-600">{output.OverallEvaluation?.Explanation}</p>
                        <p className="text-gray-500">{output.OverallEvaluation?.ExplanTranslation}</p>
                      </div>
                    </div>
                  </div>
                </section>
              </React.Fragment>
            );
          })}
        </>
      )}

      {/* 添加分頁標記 */}
      <div className="page-break" />

      {/* 整合詞彙分析區 - 修改版 */}
      <section className="mb-8">
        <h2 className="text-xl font-bold mb-4 text-blue-800">整合詞彙分析</h2>
        <div className="space-y-6">
          {/* 作文詞彙建議 */}
          <div className="bg-white p-4 rounded-lg border border-gray-200">
            <h3 className="font-semibold mb-4">詞彙改進建議</h3>
            <div className="grid gap-3">
              {/* 作文詞彙 */}
              {data.result.Vocabulary_Upgrade_lang1?.ImproveVocabulary?.map((item, index) => (
                <div key={`writing-${index}`} className="grid grid-cols-2 gap-2 p-2 bg-gray-50 rounded">
                  <div>
                    <span className="text-red-500">{item.Word}</span>
                    <span className="text-gray-500 ml-2">
                      {item.WordTranslation && `(${item.WordTranslation})`}
                    </span>
                  </div>
                  <div>
                    <span className="text-green-500">→ {item.Suggestion}</span>
                    <span className="text-gray-500 ml-2">
                      {item.SuggestionTranslation && `(${item.SuggestionTranslation})`}
                    </span>
                  </div>
                </div>
              ))}

              {/* 翻譯詞彙 */}
              {data.translationResults?.map((trans, tIndex) => {
                const output = trans.result?.output;
                if (!output) return null;

                const allExamples = [
                  ...(output.Faithfulness?.Examples || []),
                  ...(output.Expressiveness?.Examples || []),
                  ...(output.Elegance?.Examples || [])
                ];

                return allExamples.map((example, eIndex) => (
                  <div key={`trans-${tIndex}-${eIndex}`} className="grid grid-cols-2 gap-2 p-2 bg-gray-50 rounded">
                    <div>
                      <span className="text-blue-600">{example.ExampleText}</span>
                    </div>
                    <div>
                      <span className="text-gray-600">→ {example.Translation}</span>
                    </div>
                  </div>
                ));
              })}
            </div>
          </div>

          {/* 進階片語建議 */}
          {data.result.Vocabulary_Upgrade_lang1?.AdvancedPhrases && 
           data.result.Vocabulary_Upgrade_lang1.AdvancedPhrases.length > 0 && (
            <div className="bg-white p-4 rounded-lg border border-gray-200">
              <h3 className="font-semibold mb-4">進階片語建議</h3>
              <div className="grid gap-3">
                {data.result.Vocabulary_Upgrade_lang1.AdvancedPhrases.map((phrase, index) => (
                  <div key={index} className="p-2 bg-gray-50 rounded">
                    <p className="text-blue-600">{phrase.OriginalExpression}</p>
                    <p className="text-gray-600 mt-1">{phrase.RecommendedPhrase}</p>
                    {phrase.PhraseExample && (
                      <p className="text-gray-500 text-sm mt-1">
                        例句：{phrase.PhraseExample}
                        {phrase.PhraseTranslation && (
                          <span className="ml-2">({phrase.PhraseTranslation})</span>
                        )}
                      </p>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

// ScoreCard 組件
interface ScoreCardProps {
  title: string;
  score: string;
  review: string;
  translatedReview: string;
}

const ScoreCard: React.FC<ScoreCardProps> = ({ title, score, review, translatedReview }) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const A4_HEIGHT = 1122; // A4 高度 (點)
  const MARGIN = 75; // 頁面邊距 (點)
  const SAFE_HEIGHT = A4_HEIGHT - (MARGIN * 2); // 可用高度

  useEffect(() => {
    if (!cardRef.current) return;

    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const element = entry.target as HTMLElement;
        const rect = element.getBoundingClientRect();
        const parentRect = element.parentElement?.getBoundingClientRect();
        
        if (!parentRect) continue;

        // 計算元素在頁面中的相對位置
        const topPosition = rect.top - parentRect.top;
        const remainingSpace = SAFE_HEIGHT - (topPosition % SAFE_HEIGHT);

        // 如果內容高度超過剩餘空間，添加分頁
        if (rect.height > remainingSpace) {
          element.style.pageBreakBefore = 'always';
          // 添加上邊距以確保內容不會太靠近頁面頂部
          element.style.marginTop = '1rem';
        }
      }
    });

    observer.observe(cardRef.current);
    return () => observer.disconnect();
  }, []);

  return (
    <div 
      ref={cardRef} 
      className="bg-white p-4 rounded-lg border border-gray-200"
      style={{ breakInside: 'avoid' }} // 防止內容被分割
    >
      <div className="flex items-start gap-4">
        <div className="flex-none w-48">
          <h3 className="font-semibold text-gray-800">{title}</h3>
          <span className="text-xl font-bold text-blue-600 block mt-2">
            {calculateScore(score).toFixed(1)}/5
          </span>
        </div>
        <div className="flex-1 space-y-2 text-sm">
          <p>{review}</p>
          <p className="text-gray-600">{translatedReview}</p>
        </div>
      </div>
    </div>
  );
};

// 修改分數計算的部分
const calculateScore = (score: string): number => {
  if (!score) return 0;
  
  // 處理形如 "25/50" 的分數
  const match = score.match(/(\d+)\/(\d+)/);
  if (match) {
    const [_, numerator, denominator] = match;
    return (Number(numerator) / Number(denominator)) * 5;
  }
  
  // 如果是純數字
  const numScore = Number(score);
  return isNaN(numScore) ? 0 : numScore;
};

// 添加總分計算函數
const calculateTotalScore = (result: any): string => {
  try {
    // 使用與 ScoreTab 相同的計算邏輯
    const totalScore = (
      Number(result.ContentScoreAgen_lang1.ContentScore.split('/')[0]) +
      Number(result.GrammarStructureScoreAgent_lang1.Score.split('/')[0]) +
      Number(result.OrganizationScoreAgent_lang1.Score.split('/')[0]) +
      Number(result.VocabularySpellingScoreAgent_lang1.Score.split('/')[0])
    );
    return (totalScore / 10).toFixed(1);
  } catch (error) {
    console.error('Error calculating total score:', error);
    return '0.0';
  }
};

// 計算結構完整度的函數
const calculateStructureCompleteness = (section: any): number => {
  if (!section || typeof section !== 'object') return 0;
  
  // 排除 Missing Elements 屬性
  const totalElements = Object.keys(section).filter(key => key !== 'Missing Elements').length;
  const missingElements = section["Missing Elements"]?.length || 0;
  const completedElements = totalElements - missingElements;
  
  return Math.round((completedElements / totalElements) * 100);
};

// 添加一個輔助函數來檢查 Missing Elements
const hasMissingElements = (section: any): boolean => {
  return section?.["Missing Elements"]?.length > 0 ?? false;
};

// 修改檢查步驟完成狀態的函數
const checkStepCompletion = (
  section: any,
  index: number,
  type: 'Introduction' | 'Body Paragraph' | 'Conclusion'
): { isComplete: boolean; content: string | null } => {
  if (!section) return { isComplete: false, content: null };

  const getStepStatus = (field: string) => ({
    isComplete: !!section[field],
    content: section[field] || null
  });

  switch (type) {
    case 'Introduction':
      if (index === 0) return getStepStatus('Hook');
      if (index === 1) return getStepStatus('Background Information');
      if (index === 2) return getStepStatus('Thesis Statement');
      break;
    case 'Body Paragraph':
      if (index === 3) return getStepStatus('Topic Sentence');
      if (index === 4) return getStepStatus('Supporting Details');
      if (index === 5) return getStepStatus('Explanation and Analysis');
      if (index === 6) return getStepStatus('Transition');
      break;
    case 'Conclusion':
      if (index === 7) return getStepStatus('Restate the Thesis');
      if (index === 8) return getStepStatus('Summary');
      if (index === 9) return getStepStatus('Final Thought');
      break;
  }
  return { isComplete: false, content: null };
};

// 添加計算總體完整度的函數
const calculateOverallCompleteness = (structure: StructureDivisionAgent | undefined): number => {
  if (!structure) return 0;
  
  let totalComplete = 0;
  let totalSteps = 0;

  const sections = ['Introduction', 'Body Paragraph', 'Conclusion'] as const;
  sections.forEach(sectionName => {
    const section = structure[sectionName];
    if (section) {
      const sectionKeys = Object.keys(section).filter(key => key !== 'Missing Elements');
      totalSteps += sectionKeys.length;
      totalComplete += sectionKeys.filter(key => !!section[key as keyof StructureSection]).length;
    }
  });
  
  return Math.round((totalComplete / totalSteps) * 100);
};

// 修 generateReport 函數
export const generateReport = async (data: ReportGeneratorProps['data']) => {
  return new Promise<void>((resolve, reject) => {
    const PrintComponent = () => {
      const componentRef = useRef(null);

      const handlePrint = useReactToPrint({
        contentRef: componentRef,
        documentTitle: `批改報告_${data.title || '無題'}`,
        onAfterPrint: () => {
          resolve();
          const container = document.getElementById('print-container');
          if (container) {
            document.body.removeChild(container);
          }
        },
        onPrintError: (error) => {
          console.error('Print failed:', error);
          reject(error);
        },
        pageStyle: `
          @page {
            size: A4;
            margin: 20mm;
          }
          @media print {
            html, body {
              height: 100%;
              margin: 0 !important;
              padding: 0 !important;
              background-color: white;
            }
            .page-break {
              margin-top: 1rem;
              page-break-before: always;
            }
          }
        `,
      });

      useEffect(() => {
        setTimeout(handlePrint, 100);
      }, []);

      return (
        <div ref={componentRef} style={{ width: '210mm', margin: '0 auto' }}>
          <ReportContent data={data} />
        </div>
      );
    };

    const container = document.createElement('div');
    container.id = 'print-container';
    document.body.appendChild(container);

    const root = ReactDOM.createRoot(container);
    root.render(<PrintComponent />);
  });
};