import * as React from "react";
import { Toast as ToastOld } from "./toast";
import { ToastNew } from "./toast-new";

// 舊的 Toast 類型
interface ToastProps {
  message: string;
  type?: 'success' | 'error' | 'info';
  onClose?: () => void;
}

// 新的 Toast 類型
interface ToastNewProps {
  title?: string;
  description?: string;
  action?: React.ReactNode;
  variant?: "default" | "destructive";
  onClose?: () => void;
}

// 合併的 Toast 類型
type ToastActionProps = ToastProps | ToastNewProps;

// 判斷是否為新的 Toast 格式
const isNewToast = (toast: ToastActionProps): toast is ToastNewProps => {
  return 'title' in toast || 'description' in toast || 'variant' in toast;
};

const ToastContext = React.createContext<{ toast: (props: ToastActionProps) => void } | null>(null);

export function ToastProvider({ children }: { children: React.ReactNode }) {
  const [toasts, setToasts] = React.useState<(ToastActionProps & { id: string })[]>([]);

  const toast = React.useCallback((props: ToastActionProps) => {
    const id = Math.random().toString(36).slice(2);
    setToasts((prev) => [...prev, { ...props, id }]);

    setTimeout(() => {
      setToasts((prev) => prev.filter((toast) => toast.id !== id));
    }, 5000);
  }, []);

  const handleClose = (id: string) => {
    setToasts((prev) => prev.filter((toast) => toast.id !== id));
  };

  return (
    <ToastContext.Provider value={{ toast }}>
      {children}
      <div className="fixed bottom-0 right-0 z-50 w-full md:max-w-[420px] p-4 md:p-6 flex flex-col gap-2">
        {toasts.map(({ id, ...props }) => {
          if (isNewToast(props)) {
            return (
              <ToastNew
                key={id}
                {...props}
                onClose={() => handleClose(id)}
              />
            );
          } else {
            return (
              <ToastOld
                key={id}
                {...props}
                onClose={() => handleClose(id)}
              />
            );
          }
        })}
      </div>
    </ToastContext.Provider>
  );
}

export function useToast() {
  const context = React.useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
}

export type { ToastProps, ToastNewProps }; 