// src/components/ui/toast.tsx
import React from 'react';
import { X } from 'lucide-react';

export interface ToastProps {
  message: string;
  type?: 'success' | 'error' | 'info';
  onClose?: () => void;
}

export const Toast: React.FC<ToastProps> = ({ message, type = 'info', onClose }) => {
  const backgroundColor = {
    success: 'bg-green-100 text-green-800',
    error: 'bg-red-100 text-red-800',
    info: 'bg-blue-100 text-blue-800',
  }[type];

  return (
    <div className={`fixed bottom-4 right-4 p-4 rounded-lg shadow-lg ${backgroundColor} flex items-center gap-2`}>
      <span>{message}</span>
      {onClose && (
        <button onClick={onClose} className="p-1 hover:bg-black/10 rounded">
          <X size={16} />
        </button>
      )}
    </div>
  );
};