import jwt from 'jsonwebtoken';

interface ReadingSystemResponse {
  status: string;
  redirectUrl?: string;
  message?: string;
}

interface TokenPayload {
  userId: string;
  allowedActions: string[];
  quota: number;
  exp: number;
}

export const initiateReadingSystem = async (): Promise<string> => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('未登入');
    }

    // 向後端請求生成閱讀系統的 token
    const response = await fetch('/api/reading/generate-token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error('無法獲取閱讀系統權限');
    }

    const data: ReadingSystemResponse = await response.json();
    
    if (data.status === 'success' && data.redirectUrl) {
      return data.redirectUrl;
    } else {
      throw new Error(data.message || '系統錯誤');
    }
  } catch (error) {
    console.error('Reading system initiation error:', error);
    throw error;
  }
};

// 處理閱讀系統的扣額通知
export const handleQuotaDeduction = async (deductionData: {
  userId: string;
  action: string;
  itemId: string;
  deductAmount: number;
  timestamp: string;
  signature: string;
}) => {
  try {
    const token = localStorage.getItem('token');
    const response = await fetch('/api/reading/deduct-quota', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(deductionData)
    });

    if (!response.ok) {
      throw new Error('扣除額度失敗');
    }

    return await response.json();
  } catch (error) {
    console.error('Quota deduction error:', error);
    throw error;
  }
};