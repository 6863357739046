// src/components/correction/tabs/ScoreTab.tsx

import React from 'react';
import { Card, CardContent } from '../../ui/card';
import { CorrectionData } from '../../types/correction';

interface ScoreTabProps {
  data: CorrectionData;
}

const ScoreTab: React.FC<ScoreTabProps> = ({ data }) => {
  const formatScore = (score: string) => (parseFloat(score) / 10).toFixed(1);

  const scoreCategories = [
    {
      title: '內容評分',
      score: data.ContentScoreAgen_lang1.ContentScore,
      review: data.ContentScoreAgen_lang1.Review,
      translatedReview: data.ContentScoreAgen_lang2.Review_translated
    },
    {
      title: '文法結構評分',
      score: data.GrammarStructureScoreAgent_lang1.Score,
      review: data.GrammarStructureScoreAgent_lang1.Review,
      translatedReview: data.GrammarStructureScoreAgent_lang2.Review
    },
    {
      title: '組織結構評分',
      score: data.OrganizationScoreAgent_lang1.Score,
      review: data.OrganizationScoreAgent_lang1.Review,
      translatedReview: data.OrganizationScoreAgent_lang2.Review
    },
    {
      title: '詞彙拼寫評分',
      score: data.VocabularySpellingScoreAgent_lang1.Score,
      review: data.VocabularySpellingScoreAgent_lang1.Review,
      translatedReview: data.VocabularySpellingScoreAgent_lang2.Review
    }
  ];

  const totalScore = (
    Number(data.ContentScoreAgen_lang1.ContentScore.split('/')[0]) +
    Number(data.GrammarStructureScoreAgent_lang1.Score.split('/')[0]) +
    Number(data.OrganizationScoreAgent_lang1.Score.split('/')[0]) +
    Number(data.VocabularySpellingScoreAgent_lang1.Score.split('/')[0])
  );

  return (
    <Card>
      <CardContent className="p-6 space-y-6">
        {/* 總分區塊 */}
        <div className="bg-blue-50 p-6 rounded-lg">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-xl font-semibold">總分</h3>
            <span className="text-3xl font-bold text-blue-600">
              {formatScore(data.Suggestion_lang1['Total Score'])}/20
            </span>
          </div>
          <div className="space-y-2">
            <p>{data.Suggestion_lang1['Overall Evaluation'] || ''}</p>
            <p className="text-gray-600">
              {data.Suggestion_lang2['Overall Evaluation (translated)']}
            </p>
          </div>
        </div>

        {/* 分項評分 */}
        <div className="grid gap-6">
          {scoreCategories.map((category, index) => (
            <div key={index} className="bg-white rounded-lg p-6 shadow-sm">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-medium">{category.title}</h3>
                <span className="text-2xl font-bold text-blue-600">
                  {formatScore(category.score)}/5
                </span>
              </div>
              <div className="space-y-4">
                <div>
                  <h4 className="text-sm font-medium text-gray-500 mb-1">
                    英文評語
                  </h4>
                  <p>{category.review}</p>
                </div>
                <div>
                  <h4 className="text-sm font-medium text-gray-500 mb-1">
                    中文評語
                  </h4>
                  <p>{category.translatedReview}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

export default ScoreTab;