import React, { useState, useEffect, useCallback } from 'react';
import { Navigate, useNavigate, Routes, Route, useLocation } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import Login from './pages/login';
import AdminDashboard from './pages/AdminDashboard';
import TeacherDashboard from './pages/TeacherDashboard';
import SalesDashboard from './pages/SalesDashboard';
import EssaySubmission from './pages/essay-submission';
import CorrectionResult from './components/correction/CorrectionResult';
import CorrectionLoading from './pages/correction-loading';
import ProfileSettings from './pages/ProfileSettings';
import CorrectionHistory from './pages/CorrectionHistory';
import Footer from './components/Footer';
import StudentDashboard from './pages/StudentDashboard';
import StudentInterface from './pages/StudentInterface';
import PracticePage from './pages/PracticePage';
import SubscriptionPage from './pages/SubscriptionPage';
import AssignmentSubmission from './pages/AssignmentPage';
import { ToastProvider } from './components/ui/use-toast';
import NewTestCreation from './components/test-creation/NewTestCreation';
import StudentPracticeCreation from './components/practice-creation/StudentPracticeCreation';
import SelfPracticePage from './pages/SelfPracticePage';

interface PrivateRouteProps {
  children: React.ReactNode;
  allowedRoles?: string[];
  isAuthenticated: boolean;
}

const getHomePath = (role: string | null): string => {
  if (!role) return '/';
  switch (role.toLowerCase()) {
    case 'admin':
      return '/admin-dashboard';
    case 'teacher':
      return '/teacher-dashboard';
    case 'sales':
      return '/sales-dashboard';
    case 'student_b':
      return '/student-dashboard';  // 校園端學生進入 dashboard
    case 'student_c':
      return '/student-interface';  // 個人用戶進入新的介面
    default:
      return '/';
  }
};

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, allowedRoles = [], isAuthenticated }) => {
  const userRole = localStorage.getItem('userRole');
  if (!isAuthenticated) return <Navigate to="/" replace />;
  if (allowedRoles.length > 0 && !allowedRoles.includes(userRole || '')) {
    return <Navigate to="/unauthorized" replace />;
  }
  return <>{children}</>;
};

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [username, setUsername] = useState<string>('');
  const navigate = useNavigate();

  const initializeAuth = useCallback(() => {
    const token = localStorage.getItem('token');
    const savedUsername = localStorage.getItem('username');
    const userRole = localStorage.getItem('userRole');
    if (token && savedUsername && userRole) {
      setIsAuthenticated(true);
      setUsername(savedUsername);
      if (window.location.pathname === '/') {
        navigate(getHomePath(userRole), { replace: true });
      }
    } else {
      setIsAuthenticated(false);
      setUsername('');
    }
  }, [navigate]);

  useEffect(() => {
    initializeAuth();
  }, [initializeAuth]);

  const PracticeCreationWrapper = () => {
    const location = useLocation();
    const { examType } = location.state as { examType: string };
    
    return (
      <PrivateRoute 
        isAuthenticated={isAuthenticated} 
        allowedRoles={['student_c']}
      >
        <StudentPracticeCreation examType={examType} />
      </PrivateRoute>
    );
  };

  return (
    <ToastProvider>
      <div className="flex flex-col min-h-screen">
        <div className="flex-grow">
          <Routes>
            <Route path="/" element={
              isAuthenticated ? 
                <Navigate to={getHomePath(localStorage.getItem('userRole'))} replace /> : 
                <LandingPage />
            } />
            
            <Route 
              path="/login/:type" 
              element={
                isAuthenticated ? 
                  <Navigate to={getHomePath(localStorage.getItem('userRole'))} replace /> :
                  <Login setIsAuthenticated={setIsAuthenticated} setUsername={setUsername} />
              } 
            />
            <Route 
              path="/admin-dashboard" 
              element={
                <PrivateRoute 
                  isAuthenticated={isAuthenticated} 
                  allowedRoles={['admin']}
                >
                  <AdminDashboard />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/teacher-dashboard" 
              element={
                <PrivateRoute 
                  isAuthenticated={isAuthenticated} 
                  allowedRoles={['teacher']}
                >
                  <TeacherDashboard setIsAuthenticated={setIsAuthenticated} />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/sales-dashboard" 
              element={
                <PrivateRoute 
                  isAuthenticated={isAuthenticated} 
                  allowedRoles={['sales']}
                >
                  <SalesDashboard />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/essay-submission" 
              element={
                <PrivateRoute 
                  isAuthenticated={isAuthenticated} 
                  allowedRoles={['student_c']}
                >
                  <EssaySubmission 
                    setIsAuthenticated={setIsAuthenticated} 
                    username={username} 
                  />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/correction-loading" 
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <CorrectionLoading 
                    setIsAuthenticated={setIsAuthenticated} 
                    username={username} 
                  />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/correction-result/:id" 
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <CorrectionResult 
                    setIsAuthenticated={setIsAuthenticated} 
                    username={username} 
                  />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/correction-history" 
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <CorrectionHistory 
                    setIsAuthenticated={setIsAuthenticated} 
                    username={username} 
                  />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/profile-settings" 
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <ProfileSettings 
                    setIsAuthenticated={setIsAuthenticated} 
                    username={username} 
                  />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/student-dashboard" 
              element={
                <PrivateRoute 
                  isAuthenticated={isAuthenticated} 
                  allowedRoles={['student_b']}
                >
                  <StudentDashboard 
                    setIsAuthenticated={setIsAuthenticated} 
                    username={username} 
                  />
                </PrivateRoute>
              } 
            />

            <Route 
              path="/subscription" 
              element={
                <PrivateRoute 
                  isAuthenticated={isAuthenticated}
                  allowedRoles={['student_c']}
                >
                  <SubscriptionPage 
                    setIsAuthenticated={setIsAuthenticated}
                    username={username}
                  />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/assignment/:assignmentId"
              element={
                <PrivateRoute 
                  isAuthenticated={isAuthenticated} 
                  allowedRoles={['student_b']}
                >
                  <AssignmentSubmission 
                    setIsAuthenticated={setIsAuthenticated} 
                    username={username} 
                  />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/correction-result/teacher/:id" 
              element={
                <PrivateRoute 
                  isAuthenticated={isAuthenticated} 
                  allowedRoles={['teacher']}
                >
                  <CorrectionResult 
                    setIsAuthenticated={setIsAuthenticated} 
                    username={username} 
                  />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/student-interface" 
              element={
                <PrivateRoute 
                  isAuthenticated={isAuthenticated} 
                  allowedRoles={['student_c']}
                >
                  <StudentInterface 
                    setIsAuthenticated={setIsAuthenticated} 
                    username={username} 
                  />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/practice/:practiceId" 
              element={
                <PrivateRoute 
                  isAuthenticated={isAuthenticated} 
                  allowedRoles={['student_c']}
                >
                  <PracticePage 
                    setIsAuthenticated={setIsAuthenticated} 
                    username={username} 
                  />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/test-creation" 
              element={
                <PrivateRoute 
                  isAuthenticated={isAuthenticated} 
                  allowedRoles={['student_c']}
                >
                  <NewTestCreation 
                    setIsAuthenticated={setIsAuthenticated} 
                    username={username} 
                  />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/practice-creation" 
              element={<PracticeCreationWrapper />} 
            />
            <Route 
              path="/self-practice" 
              element={
                <PrivateRoute 
                  isAuthenticated={isAuthenticated}
                  allowedRoles={['student_c']}
                >
                  <SelfPracticePage setIsAuthenticated={setIsAuthenticated} username={username} />
                </PrivateRoute>
              } 
            />
            <Route path="/unauthorized" element={
              <div className="flex items-center justify-center h-screen">
                <div className="text-center">
                  <h1 className="text-2xl font-bold mb-4">無訪問權限</h1>
                  <p className="text-gray-600 mb-4">您沒有權限訪問此頁面</p>
                  <button 
                    onClick={() => navigate(getHomePath(localStorage.getItem('userRole')))}
                    className="text-blue-500 hover:text-blue-700"
                  >
                    返回首頁
                  </button>
                </div>
              </div>
            } />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </ToastProvider>
  );
}

export default App;