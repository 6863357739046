import React, { useState, useEffect } from 'react';
import { Card, CardContent } from '../ui/card';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Label } from '../ui/label';
import { Alert } from '../ui/alert';
import { Calendar } from '../ui/calendar';
import { format, addDays } from 'date-fns';
import { zhTW } from 'date-fns/locale';
import { CalendarIcon } from 'lucide-react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../ui/popover';
import { cn } from '../../lib/utils';
import { DayPicker } from 'react-day-picker';

interface DeadlineFormProps {
  onSubmit: (deadline: Date, targetClass: string) => void;
  onBack: () => void;
}

const DeadlineForm: React.FC<DeadlineFormProps> = ({ onSubmit, onBack }) => {
  // 預設日期為7天後
  const defaultDate = addDays(new Date(), 7);
  const [date, setDate] = useState<Date>(defaultDate);
  const [hour, setHour] = useState(defaultDate.getHours().toString().padStart(2, '0'));
  const [minute, setMinute] = useState(defaultDate.getMinutes().toString().padStart(2, '0'));
  const [targetClass, setTargetClass] = useState('');
  const [classes, setClasses] = useState<{ className: string; studentCount: number }[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchTeacherClasses();
  }, []);

  const fetchTeacherClasses = async () => {
    try {
      const response = await fetch('/api/test/teacher-classes', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (!response.ok) throw new Error('Failed to fetch classes');

      const data = await response.json();
      if (data.success) {
        setClasses(data.classes);
      } else {
        throw new Error(data.message || '獲取班級資料失敗');
      }
    } catch (error) {
      console.error('Error fetching classes:', error);
      setError('無法獲取班級資料');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = () => {
    if (!date || !hour || !minute || !targetClass) {
      setError('請填寫所有必要欄位');
      return;
    }
  
    const deadlineDate = new Date(date);
    deadlineDate.setHours(parseInt(hour), parseInt(minute), 0, 0);
  
    if (deadlineDate <= new Date()) {
      setError('截止時間必須晚於當前時間');
      return;
    }
  
    console.log('Sending deadline:', {
      date: deadlineDate,
      class: targetClass
    });
  
    onSubmit(deadlineDate, targetClass);
  };
  
  const hours = Array.from({ length: 24 }, (_, i) => ({
    value: i.toString().padStart(2, '0'),
    label: `${i.toString().padStart(2, '0')}時`
  }));

  const minutes = Array.from({ length: 60 }, (_, i) => ({
    value: i.toString().padStart(2, '0'),
    label: `${i.toString().padStart(2, '0')}分`
  }));

  if (isLoading) {
    return <div className="flex justify-center items-center h-40">載入中...</div>;
  }

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold">截止設定</h2>
      
      {error && (
        <Alert variant="destructive" className="mb-4">
          {error}
        </Alert>
      )}

      <Card>
        <CardContent className="space-y-6 pt-6">
          <div className="space-y-2">
            <Label>繳交日期</Label>
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  className={cn(
                    "w-full justify-start text-left font-normal",
                    !date && "text-muted-foreground"
                  )}
                >
                  <CalendarIcon className="mr-2 h-4 w-4" />
                  {date ? format(date, 'PPP', { locale: zhTW }) : "選擇日期"}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0" align="start">
                <DayPicker
                  mode="single"
                  selected={date}
                  onSelect={(newDate) => setDate(newDate || defaultDate)}
                  disabled={(date) => date < new Date()}
                  locale={zhTW}
                  className="border rounded-md p-3"
                  fromDate={new Date()}
                  captionLayout="dropdown"
                  weekStartsOn={1}
                />
              </PopoverContent>
            </Popover>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <Label>時間（小時）</Label>
              <Select value={hour} onValueChange={setHour}>
                <SelectTrigger>
                  <SelectValue placeholder="選擇小時" />
                </SelectTrigger>
                <SelectContent>
                  {hours.map((hour) => (
                    <SelectItem key={hour.value} value={hour.value}>
                      {hour.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <div className="space-y-2">
              <Label>時間（分鐘）</Label>
              <Select value={minute} onValueChange={setMinute}>
                <SelectTrigger>
                  <SelectValue placeholder="選擇分鐘" />
                </SelectTrigger>
                <SelectContent>
                  {minutes.map((minute) => (
                    <SelectItem key={minute.value} value={minute.value}>
                      {minute.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>

          <div className="space-y-2">
            <Label>選擇班級</Label>
            <Select onValueChange={setTargetClass} value={targetClass}>
              <SelectTrigger className="w-full">
                <SelectValue placeholder="選擇班級" />
              </SelectTrigger>
              <SelectContent>
                {classes.map((classInfo) => (
                  <SelectItem key={classInfo.className} value={classInfo.className}>
                    {classInfo.className} ({classInfo.studentCount} 位學生)
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </CardContent>
      </Card>

      <div className="flex justify-between">
        <Button variant="outline" onClick={onBack}>返回</Button>
        <Button onClick={handleSubmit}>確認送出</Button>
      </div>
    </div>
  );
};

export default DeadlineForm;