// src/pages/AdminDashboard.tsx

import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from '../components/ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../components/ui/tabs';
import { Alert, AlertDescription } from '../components/ui/alert';

// 導入子組件
import UserManagement from '../components/admin/UserManagement';
import QuotaManagement from '../components/admin/QuotaManagement';
import TeacherAssignment from '../components/admin/TeacherAssignment';
import SystemSettings from '../components/admin/SystemSettings';
import EssayAnalytics from '../components/admin/EssayAnalytics';

// 主要的儀表板組件
const AdminDashboard: React.FC = () => {
  const [username, setUsername] = useState<string>('');

  useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    if (storedUsername) {
      setUsername(storedUsername);
    }
  }, []);

  return (
    <div>
      <Header setIsAuthenticated={() => {}} username={username} />
      <div className="container mx-auto p-6">
        <Card className="mb-6">
          <CardHeader>
            <CardTitle>管理員儀表板</CardTitle>
            <CardDescription>管理用戶、系統設置與數據分析</CardDescription>
          </CardHeader>
        </Card>

        <Tabs defaultValue="users" className="space-y-4">
          <TabsList className="grid w-full grid-cols-5">
            <TabsTrigger value="users">用戶管理</TabsTrigger>
            <TabsTrigger value="quotas">額度管理</TabsTrigger>
            <TabsTrigger value="teacher_assign">教師分配</TabsTrigger>
            <TabsTrigger value="analytics">文章分析</TabsTrigger>
            <TabsTrigger value="settings">系統設置</TabsTrigger>
          </TabsList>

          <TabsContent value="users">
            <UserManagement />
          </TabsContent>

          <TabsContent value="quotas">
            <QuotaManagement />
          </TabsContent>

          <TabsContent value="teacher_assign">
            <TeacherAssignment />
          </TabsContent>

          <TabsContent value="analytics">
            <EssayAnalytics />
          </TabsContent>

          <TabsContent value="settings">
            <SystemSettings />
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
};

export default AdminDashboard;