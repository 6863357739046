import React, { useState, useEffect } from 'react';
import { 
  Card, 
  CardContent, 
  CardHeader, 
  CardTitle, 
  CardDescription 
} from '../../components/ui/card';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '../../components/ui/tabs';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../components/ui/select';
import {
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  ResponsiveContainer,
  Bar,
  PieChart,
  Pie
} from 'recharts';
import { Essay ,StudentData } from '../types/essay';
import AnalyticsView from '../correction/AnalyticsView';
import ThemeAnalysis from './ThemeAnalysis';
import ClassAnalysis from './ClassAnalysis';

// Type definitions
interface VocabularyItem {
  word: string;
  cefr?: string;
  translation?: string;
  count: number;
  type: 'basic' | 'advanced' | 'content' | 'suggested' | null;
  level?: string;
}

interface ScoreData {
  date: string;
  score: number;
  totalScore: number;
}

interface ClassPerformance {
  criteria: string;
  [key: string]: number | string;
}

interface ComparisonData {
  submissionCount: {
    [key: string]: number;
  };
  averageScores: Array<{
    class: string;
    score: number;
  }>;
  criteriaScores: Array<{
    criteria: string;
    class1Score: number;
    class2Score: number;
  }>;
  commonVocabulary: Array<{
    word: string;
    cefr?: string;
    translation?: string;
    count: number;
    type: 'basic' | 'advanced' | null;
  }>;
  vocabularyComparison: {
    [className: string]: ClassVocabularyAnalysis;
  };
  vocabularyItems: {
    [className: string]: ClassVocabularyAnalysis;
  };
}
interface VocabularyComparison {
  basic: VocabularyList;
  advanced: VocabularyList;
}
interface VocabularyItem {
  word: string;
  cefr?: string;
  translation?: string;
  count: number;
  type: 'basic' | 'advanced' | 'content' | 'suggested' | null;
}
interface VocabularyList {
  totalWords: number;
  wordList: VocabularyItem[];
}
interface ThemeData {
  minScore: number;
  maxScore: number;
  averageScore: number;
  scoreDistribution: Array<{
    range: string;
    count: number;
  }>;
  classPerformance: ClassPerformance[];
  vocabularyRanking: VocabularyItem[];
}

interface ClassData {
  minScore: number;
  maxScore: number;
  averageScore: number;
  scoresTrend: ScoreData[];
  commonMistakes: VocabularyItem[];
  ranking: Array<{
    name: string;
    score: number;
  }>;
  corpusRichness: number;
  repeatedWordsRatio: number;
  commonWords: VocabularyItem[];
  performanceHistory: ClassPerformance[];
}

interface Student {
  _id: string;
  username: string;
  realName: string;
  className: string;
  totalEssays: number;
  averageScore: number;
  vocabularyCount: number;
  vocabularyToRemember: number;
  submissions?: any[]; 
}
interface Topic {
  title: string;
  testType: string;
  assignedClasses: Array<{
    className: string;
    assignment_id: string;
  }>;
}

interface TeacherStats {
  classes: Array<{
    className: string;
    studentCount: number;
  }>;
  topics: Topic[];
}

interface StatisticalAnalysisProps {
  students: Student[];
}

interface ScoreAccumulator {
  validScores: number[];
  totalScore: number;
  count: number;
}

interface DetailedScores {
  content: { sum: number; count: number };
  grammar: { sum: number; count: number };
  vocabulary: { sum: number; count: number };
  organization: { sum: number; count: number };
}

// 詞彙項目介面
interface VocabularyItem {
  word: string;
  cefr?: string;
  translation?: string;
  count: number;
  type: 'basic' | 'advanced' | 'content' | 'suggested' | null;
  level?: string;
}

// 詞表介面
interface VocabularyList {
  totalWords: number;
  wordList: VocabularyItem[];
}

// 班級詞彙比較介面
interface ClassVocabularyComparison {
  basic: VocabularyList;
  advanced: VocabularyList;
}

// 共同詞彙項目介面
interface CommonVocabularyItem {
  word: string;
  cefr?: string;
  translation?: string;
  count1: number;
  count2: number;
  type: 'basic' | 'advanced' | null;
}

// 共同詞彙數據介面
interface CommonVocabularyData {
  basic: CommonVocabularyItem[];
  advanced: CommonVocabularyItem[];
}

// 詞彙統計介面
interface VocabularyStats {
  totalWords: number;
  byType: {
    basic: number;
    advanced: number;
    content: number;
    suggested: number;
  };
  byLevel: {
    Basic: number;
    Advanced: number;
    Suggested: number;
    Used: number;
  };
}

// 班級詞彙分析介面
interface ClassVocabularyAnalysis {
  items: VocabularyItem[];
  stats: VocabularyStats;
}

// 評分標準分數介面
interface CriteriaScore {
  criteria: string;
  class1Score: number;
  class2Score: number;
}

// 平均分數介面
interface AverageScore {
  class: string;
  score: number;
}

// 完整回應數據介面
interface ComparisonResponseData {
  submissionCount: {
    [className: string]: number;
  };
  averageScores: AverageScore[];
  criteriaScores: CriteriaScore[];
  vocabularyComparison: {
    [className: string]: ClassVocabularyComparison;
  };
  commonVocabulary: CommonVocabularyData;
  vocabularyItems: {
    [className: string]: ClassVocabularyAnalysis;
  };
}

// 添加 ThemeAnalysis 的 props 介面
interface ThemeAnalysisProps {
  topics: string[];
}

const StatisticalAnalysis: React.FC<StatisticalAnalysisProps> = ({ students }) => {
  const [selectedTheme, setSelectedTheme] = useState<string>('');
  const [selectedClass1, setSelectedClass1] = useState<string>('');
  const [selectedClass2, setSelectedClass2] = useState<string>('');
  const [themeData, setThemeData] = useState<ThemeData | null>(null);
  const [classData, setClassData] = useState<ClassData | null>(null);
  const [comparisonData, setComparisonData] = useState<ComparisonData | null>(null);
  const [teacherStats, setTeacherStats] = useState<TeacherStats>({
    classes: [],
    topics: []
  });
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  // 獲取主題數據
  const fetchThemeData = async (theme: string): Promise<void> => {
    try {
      const response = await fetch(`/api/teacher/theme-analysis/${theme}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      const data = await response.json();
      setThemeData(data);
    } catch (error) {
      console.error('Error fetching theme data:', error);
    }
  };

  // 獲取班級數據
  const fetchClassData = async (className: string): Promise<void> => {
    try {
      const response = await fetch(`/api/teacher/class-analysis/${className}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      const data = await response.json();
      setClassData(data);
    } catch (error) {
      console.error('Error fetching class data:', error);
    }
  };

  // 班級比較數據
  const fetchComparisonData = async (class1: string, class2: string, topic: string) => {
    try {
      const response = await fetch('/api/analytics/class-comparison', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ class1, class2, topic })
      });
      
      if (!response.ok) throw new Error('獲取比較數據失敗');
      const { data } = await response.json();
      setComparisonData(data);
    } catch (error) {
      console.error('Error fetching comparison data:', error);
    }
  };

  // 計算��數並過濾無效數據
  const calculateScores = (essays: Essay[]) => {
    return essays.reduce<ScoreAccumulator>((acc, essay) => {
      if (!essay.correctionResult) return acc;
      
      const totalScore = Number(
        essay.correctionResult.Suggestion_lang1?.["Total Score"]?.split("/")[0]
      );
      
      if (!isNaN(totalScore)) {
        acc.validScores.push(totalScore / 10);
        acc.totalScore += totalScore / 10;
        acc.count++;
      }
      return acc;
    }, { validScores: [], totalScore: 0, count: 0 });
  };

  // 過濾並計算詳細分數
  const calculateDetailedScores = (essays: Essay[]) => {
    return essays.reduce<DetailedScores>((acc, essay) => {
      if (!essay.correctionResult) return acc;
      
      const scores = {
        content: Number(essay.correctionResult.ContentScoreAgen_lang1?.ContentScore?.split("/")[0]),
        grammar: Number(essay.correctionResult.GrammarStructureScoreAgent_lang1?.Score?.split("/")[0]),
        vocabulary: Number(essay.correctionResult.VocabularySpellingScoreAgent_lang1?.Score?.split("/")[0]),
        organization: Number(essay.correctionResult.OrganizationScoreAgent_lang1?.Score?.split("/")[0])
      };

      if (Object.values(scores).every(score => !isNaN(score))) {
        type ScoreKey = keyof typeof scores;
        Object.entries(scores).forEach(([key, value]) => {
          const scoreKey = key as ScoreKey;
          acc[scoreKey].sum += value / 10;
          acc[scoreKey].count++;
        });
      }
      return acc;
    }, {
      content: { sum: 0, count: 0 },
      grammar: { sum: 0, count: 0 },
      vocabulary: { sum: 0, count: 0 },
      organization: { sum: 0, count: 0 }
    });
  };

  // 班級比較分析組件
  const ClassComparison: React.FC = () => {
    const [selectedTopic, setSelectedTopic] = useState<string>('');
    const [selectedClass1, setSelectedClass1] = useState<string>('');
    const [selectedClass2, setSelectedClass2] = useState<string>('');
    const [selectedAssignmentId1, setSelectedAssignmentId1] = useState<string>('');
    const [selectedAssignmentId2, setSelectedAssignmentId2] = useState<string>('');
    const [availableClasses, setAvailableClasses] = useState<Array<{
      className: string;
      assignment_id: string;
    }>>([]);

    // 修改主題選擇處理函數
    const handleTopicChange = (value: string) => {
      console.log('Selected topic:', value); // 調試用
      setSelectedTopic(value);
      // 重置班級選擇
      setSelectedClass1('');
      setSelectedClass2('');
      setSelectedAssignmentId1('');
      setSelectedAssignmentId2('');
      
      // 過濾可用班級
      const topicData = teacherStats.topics.find(t => t.title === value);
      console.log('Topic data:', topicData); // 調試用
      
      if (topicData && Array.isArray(topicData.assignedClasses)) {
        console.log('Available classes:', topicData.assignedClasses); // 調試用
        setAvailableClasses(topicData.assignedClasses);
      } else {
        setAvailableClasses([]);
      }
    };

    // 處理班級1選擇
    const handleClass1Change = (className: string) => {
      setSelectedClass1(className);
      const assignmentId = availableClasses.find(c => c.className === className)?.assignment_id;
      if (assignmentId) {
        setSelectedAssignmentId1(assignmentId);
      }
    };

    // 處理班級2選擇
    const handleClass2Change = (className: string) => {
      setSelectedClass2(className);
      const assignmentId = availableClasses.find(c => c.className === className)?.assignment_id;
      if (assignmentId) {
        setSelectedAssignmentId2(assignmentId);
      }
    };

    // 當選擇改變時獲取比較數據
    useEffect(() => {
      if (selectedTopic && selectedClass1 && selectedClass2 && 
          selectedAssignmentId1 && selectedAssignmentId2) {
        fetchComparisonData();
      }
    }, [selectedTopic, selectedClass1, selectedClass2, 
        selectedAssignmentId1, selectedAssignmentId2]);

    const fetchComparisonData = async () => {
      try {
        const response = await fetch('/api/analytics/class-comparison', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          body: JSON.stringify({
            class1: selectedClass1,
            class2: selectedClass2,
            topic: selectedTopic,
            assignment_id1: selectedAssignmentId1,
            assignment_id2: selectedAssignmentId2
          })
        });

        if (!response.ok) throw new Error('獲取比較數據失敗');
        const { data } = await response.json();
        setComparisonData(data);
      } catch (error) {
        console.error('Error fetching comparison data:', error);
      }
    };

    return (
      <div className="space-y-6">
        {/* 選擇區域 */}
        <Card>
          <CardContent className="pt-6">
            <div className="grid grid-cols-3 gap-6">
              <div className="space-y-2">
                <label className="text-sm font-medium text-gray-700">主題選擇</label>
                <Select value={selectedTopic} onValueChange={handleTopicChange}>
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="選擇主題" />
                  </SelectTrigger>
                  <SelectContent>
                    {teacherStats.topics.map((topic) => (
                      <SelectItem key={topic.title} value={topic.title}>
                        {topic.title}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              <div className="space-y-2">
                <label className="text-sm font-medium text-gray-700">班級 1</label>
                <Select
                  value={selectedClass1}
                  onValueChange={handleClass1Change}
                  disabled={!selectedTopic || availableClasses.length === 0}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="選擇班級 1" />
                  </SelectTrigger>
                  <SelectContent>
                    {availableClasses.map((classInfo) => (
                      <SelectItem 
                        key={classInfo.className} 
                        value={classInfo.className}
                        disabled={classInfo.className === selectedClass2}
                      >
                        {classInfo.className}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              <div className="space-y-2">
                <label className="text-sm font-medium text-gray-700">班級 2</label>
                <Select
                  value={selectedClass2}
                  onValueChange={handleClass2Change}
                  disabled={!selectedTopic || availableClasses.length === 0}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="選擇班級 2" />
                  </SelectTrigger>
                  <SelectContent>
                    {availableClasses.map((classInfo) => (
                      <SelectItem 
                        key={classInfo.className} 
                        value={classInfo.className}
                        disabled={classInfo.className === selectedClass1}
                      >
                        {classInfo.className}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </div>
          </CardContent>
        </Card>

        {/* 比較數據顯示區域 */}
        {comparisonData && (
          <div className="grid gap-6">
            {/* 提交數量和平均分數概覽 */}
            <div className="grid grid-cols-2 gap-6">
              <Card>
                <CardHeader>
                  <CardTitle>提交數量比較</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="grid grid-cols-2 gap-4">
                    {Object.entries(comparisonData.submissionCount).map(([className, count]) => (
                      <div key={className} className="bg-gray-50 p-4 rounded-lg">
                        <h4 className="text-sm font-medium text-gray-500">{className}</h4>
                        <p className="text-2xl font-bold mt-1">{count}</p>
                      </div>
                    ))}
                  </div>
                </CardContent>
              </Card>

              <Card>
                <CardHeader>
                  <CardTitle>平均分數比較</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="grid grid-cols-2 gap-4">
                    {comparisonData.averageScores.map((score) => (
                      <div key={score.class} className="bg-gray-50 p-4 rounded-lg">
                        <h4 className="text-sm font-medium text-gray-500">{score.class}</h4>
                        <p className="text-2xl font-bold mt-1">{(Number(score.score) / 10).toFixed(1)}</p>

                      </div>
                    ))}
                  </div>
                </CardContent>
              </Card>
            </div>

            {/* 評分標準比較 */}
            <Card>
              <CardHeader>
                <CardTitle>評分標準比較</CardTitle>
              </CardHeader>
              <CardContent className="h-[400px]">
                <ResponsiveContainer width="100%" height="100%">
                  <RadarChart data={comparisonData.criteriaScores}>
                    <PolarGrid strokeDasharray="3 3" />
                    <PolarAngleAxis dataKey="criteria" />
                    <PolarRadiusAxis />
                    <Radar
                      name={selectedClass1}
                      dataKey="class1Score"
                      stroke="#8884d8"
                      fill="#8884d8"
                      fillOpacity={0.6}
                    />
                    <Radar
                      name={selectedClass2}
                      dataKey="class2Score"
                      stroke="#82ca9d"
                      fill="#82ca9d"
                      fillOpacity={0.6}
                    />
                    <Legend />
                    <Tooltip />
                  </RadarChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>

            {/* 詞彙分析 */}
            
            <div className="grid grid-cols-2 gap-6">
              {comparisonData?.vocabularyItems && Object.entries(comparisonData.vocabularyItems).map(([className, data]) => (
                <Card key={className}>
                  <CardHeader>
                    <CardTitle className="flex items-center justify-between">
                      <span>{className} 詞彙分析</span>
                      <span className="text-sm font-normal text-gray-500">
                        總計: {data.stats?.totalWords || 0} 字
                      </span>
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="space-y-6">
                      {/* 基礎詞彙 */}
                      <div>
                        <h4 className="text-sm font-medium text-gray-700 mb-3">
                          基礎詞彙 (總計: {data.stats?.byType?.basic || 0})
                        </h4>
                        <div className="space-y-2 max-h-[200px] overflow-y-auto">
                          {data.items
                            ?.filter(item => item.type === 'basic')
                            .map((word, index) => (
                              <div key={index} className="p-2 bg-blue-50 rounded">
                                <div className="flex items-center justify-between">
                                  <span className="font-medium">{word.word}</span>
                                  <div className="flex items-center space-x-2">
                                    {word.cefr && (
                                      <span className="text-xs px-2 py-1 bg-blue-100 rounded">
                                        {word.cefr}
                                      </span>
                                    )}
                                    <span className="text-xs text-gray-500">
                                      {word.count}次
                                    </span>
                                  </div>
                                </div>
                                {word.translation && (
                                  <div className="text-gray-600 text-sm mt-1">
                                    {word.translation}
                                  </div>
                                )}
                              </div>
                            ))}
                        </div>
                      </div>

                      {/* 進階詞彙 */}
                      <div>
                        <h4 className="text-sm font-medium text-gray-700 mb-3">
                          進階詞彙 (總計: {data.stats?.byType?.advanced || 0})
                        </h4>
                        <div className="space-y-2 max-h-[200px] overflow-y-auto">
                          {data.items
                            ?.filter(item => item.type === 'advanced')
                            .map((word, index) => (
                              <div key={index} className="p-2 bg-green-50 rounded">
                                <div className="flex items-center justify-between">
                                  <span className="font-medium">{word.word}</span>
                                  <div className="flex items-center space-x-2">
                                    {word.cefr && (
                                      <span className="text-xs px-2 py-1 bg-green-100 rounded">
                                        {word.cefr}
                                      </span>
                                    )}
                                    <span className="text-xs text-gray-500">
                                      {word.count}次
                                    </span>
                                  </div>
                                </div>
                                {word.translation && (
                                  <div className="text-gray-600 text-sm mt-1">
                                    {word.translation}
                                  </div>
                                )}
                              </div>
                            ))}
                        </div>
                      </div>

                      {/* 內容詞彙 */}
                      {data.items?.some(item => item.type === 'content') && (
                        <div>
                          <h4 className="text-sm font-medium text-gray-700 mb-3">
                            內容詞彙 (總計: {data.stats?.byType?.content || 0})
                          </h4>
                          <div className="space-y-2 max-h-[200px] overflow-y-auto">
                            {data.items
                              ?.filter(item => item.type === 'content')
                              .map((word, index) => (
                                <div key={index} className="p-2 bg-yellow-50 rounded">
                                  <div className="flex items-center justify-between">
                                    <span className="font-medium">{word.word}</span>
                                    <div className="flex items-center space-x-2">
                                      {word.cefr && (
                                        <span className="text-xs px-2 py-1 bg-yellow-100 rounded">
                                          {word.cefr}
                                        </span>
                                      )}
                                      <span className="text-xs text-gray-500">
                                        {word.count}次
                                      </span>
                                    </div>
                                  </div>
                                  {word.translation && (
                                    <div className="text-gray-600 text-sm mt-1">
                                      {word.translation}
                                    </div>
                                  )}
                                </div>
                              ))}
                          </div>
                        </div>
                      )}

                      {/* 建議詞彙 */}
                      {data.items?.some(item => item.type === 'suggested') && (
                        <div>
                          <h4 className="text-sm font-medium text-gray-700 mb-3">
                            建議詞彙 (總計: {data.stats?.byType?.suggested || 0})
                          </h4>
                          <div className="space-y-2 max-h-[200px] overflow-y-auto">
                            {data.items
                              ?.filter(item => item.type === 'suggested')
                              .map((word, index) => (
                                <div key={index} className="p-2 bg-purple-50 rounded">
                                  <div className="flex items-center justify-between">
                                    <span className="font-medium">{word.word}</span>
                                    <div className="flex items-center space-x-2">
                                      {word.cefr && (
                                        <span className="text-xs px-2 py-1 bg-purple-100 rounded">
                                          {word.cefr}
                                        </span>
                                      )}
                                      <span className="text-xs text-gray-500">
                                        {word.count}次
                                      </span>
                                    </div>
                                  </div>
                                  {word.translation && (
                                    <div className="text-gray-600 text-sm mt-1">
                                      {word.translation}
                                    </div>
                                  )}
                                </div>
                              ))}
                          </div>
                        </div>
                      )}


                    </div>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };



  useEffect(() => {
    if (selectedTheme && selectedClass1 && selectedClass2) {
      fetchComparisonData(selectedClass1, selectedClass2, selectedTheme);
    }
  }, [selectedTheme, selectedClass1, selectedClass2]);

  useEffect(() => {
    const fetchTeacherStats = async () => {
      try {
        const response = await fetch('/api/analytics/teacher-stats', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        
        if (!response.ok) throw new Error('獲取教師統計資料失敗');
        const { data } = await response.json();
        
        // 檢查並打印接收到的數據
        console.log('Received teacher stats:', data);
        
        // 確保 classes 是正確的格式
        if (data && Array.isArray(data.classes)) {
          setTeacherStats({
            classes: data.classes,  // 這裡應該已經是正確的格式：[{className, studentCount}]
            topics: data.topics || []
          });
        } else {
          console.error('Invalid classes data format:', data.classes);
          setTeacherStats({
            classes: [],
            topics: data.topics || []
          });
        }
      } catch (error) {
        console.error('Error fetching teacher stats:', error);
      }
    };

    fetchTeacherStats();
  }, []);

  return (
    <div className="space-y-4">
      <Tabs defaultValue="class-comparison">
        <TabsList className="grid w-full grid-cols-3">
          <TabsTrigger value="class-comparison">班對班分析</TabsTrigger>
          <TabsTrigger value="theme-analysis">主題分析</TabsTrigger>
          <TabsTrigger value="class-analysis">班內分析</TabsTrigger>
        </TabsList>

        <TabsContent value="class-comparison">
          <ClassComparison />
        </TabsContent>

        <TabsContent value="theme-analysis">
          <ThemeAnalysis topics={teacherStats.topics.map(t => t.title)} />
        </TabsContent>

        <TabsContent value="class-analysis">
          <ClassAnalysis classes={teacherStats.classes.map(c => c.className) || []} />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default StatisticalAnalysis;