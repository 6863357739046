// src/components/teacher/test-creation/SelectTestType.tsx
import React from 'react';
import { Card, CardContent } from '../ui/card';

interface SelectTestTypeProps {
  onSelect: (type: string) => void;
}

const SelectTestType: React.FC<SelectTestTypeProps> = ({ onSelect }) => {
  const types = [
    {
      id: 'translation-writing',
      title: '翻譯+寫作',
      description: '包含翻譯題與作文題'
    },
    {
      id: 'english-writing',
      title: '純英文寫作',
      description: '僅含作文題'
    },
    {
      id: 'history',
      title: '出題紀錄',
      description: '查看已出題目'
    }
  ];

  return (
    <div className="grid grid-cols-3 gap-4">
      {types.map((type) => (
        <Card
          key={type.id}
          className="cursor-pointer hover:shadow-lg transition-all"
          onClick={() => onSelect(type.id)}
        >
          <CardContent className="pt-6">
            <h3 className="text-lg font-semibold mb-2">{type.title}</h3>
            <p className="text-sm text-gray-600">{type.description}</p>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default SelectTestType;