import React, { useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useToast } from '../components/ui/use-toast';

interface SubscriptionPageProps {
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  username: string;
}

interface PlanProps {
  id: string;
  name: string;
  price: number;
  essayCount: number;
  translationCount: number;
  features: string[];
}

const plans: PlanProps[] = [
  {
    id: 'basic',
    name: '基礎方案',
    price: 299,
    essayCount: 5,
    translationCount: 5,
    features: [
      '作文批改 5 次',
      '翻譯練習 5 次',
      '基礎評分報告',
      '有效期 30 天'
    ]
  },
  {
    id: 'standard',
    name: '標準方案',
    price: 499,
    essayCount: 10,
    translationCount: 10,
    features: [
      '作文批改 10 次',
      '翻譯練習 10 次',
      '詳細評分報告',
      '個人化建議',
      '有效期 60 天'
    ]
  },
  {
    id: 'premium',
    name: '進階方案',
    price: 799,
    essayCount: 20,
    translationCount: 20,
    features: [
      '作文批改 20 次',
      '翻譯練習 20 次',
      '詳細評分報告',
      '個人化建議',
      '優先批改',
      '有效期 90 天'
    ]
  }
];

const SubscriptionPage: React.FC<SubscriptionPageProps> = ({ setIsAuthenticated, username }) => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubscribe = async (plan: PlanProps) => {
    setIsProcessing(true);
    try {
      const response = await fetch('/api/subscription/purchase', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          planId: plan.id,
          essayCount: plan.essayCount,
          translationCount: plan.translationCount
        })
      });

      const data = await response.json();

      if (data.success) {
        toast({
          title: "購買成功",
          description: `已成功購買${plan.name}`,
        });
        navigate('/student-interface');
      } else {
        throw new Error(data.message || '購買失敗');
      }
    } catch (error) {
      console.error('Purchase error:', error);
      toast({
        variant: "destructive",
        title: "購買失敗",
        description: error instanceof Error ? error.message : '請稍後再試'
      });
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Header setIsAuthenticated={setIsAuthenticated} username={username} />
      <main className="flex-grow bg-gray-50">
        <div className="max-w-7xl mx-auto p-6">
          <Card className="mb-6">
            <CardHeader>
              <CardTitle className="text-center text-2xl">選擇方案</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {plans.map((plan) => (
                  <Card key={plan.id} className="relative">
                    <CardContent className="p-6">
                      <h3 className="text-xl font-bold mb-2">{plan.name}</h3>
                      <p className="text-3xl font-bold mb-4">
                        NT$ {plan.price}
                        <span className="text-sm font-normal text-gray-600">/方案</span>
                      </p>
                      <ul className="space-y-2 mb-6">
                        {plan.features.map((feature, index) => (
                          <li key={index} className="flex items-center">
                            <svg
                              className="w-4 h-4 mr-2 text-green-500"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                              />
                            </svg>
                            {feature}
                          </li>
                        ))}
                      </ul>
                      <Button
                        className="w-full"
                        onClick={() => handleSubscribe(plan)}
                        disabled={isProcessing}
                      >
                        {isProcessing ? '處理中...' : '立即購買'}
                      </Button>
                    </CardContent>
                  </Card>
                ))}
              </div>
            </CardContent>
          </Card>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default SubscriptionPage; 