import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import { Button } from '../ui/button';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../Header';
import Footer from '../Footer';
import { useToast } from "../ui/use-toast";
import { Label } from '../ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select';

interface NewTestCreationProps {
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  username: string;
}

interface TestState {
  examType: 'gsat' | 'ast' | 'custom';
  sourceType: 'past-exam' | 'custom';
  year?: number;
  testType: 'writing' | 'translation-writing';
}

const NewTestCreation: React.FC<NewTestCreationProps> = ({ setIsAuthenticated, username }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { toast } = useToast();
  const [availableYears, setAvailableYears] = useState<number[]>([]);

  const [formData, setFormData] = useState<TestState>({
    examType: 'custom',
    sourceType: 'custom',
    testType: 'writing'
  });

  useEffect(() => {
    const fetchYears = async () => {
      if (formData.sourceType === 'past-exam') {
        try {
          const response = await fetch(`/api/student/practice/years/${formData.examType}`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
          const data = await response.json();
          if (data.success) {
            setAvailableYears(data.years);
          }
        } catch (error) {
          console.error('Error fetching years:', error);
        }
      }
    };

    fetchYears();
  }, [formData.sourceType, formData.examType]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/student/practice/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(formData)
      });

      const data = await response.json();

      if (data.success) {
        toast({
          title: "成功",
          description: "練習創建成功",
        });
        navigate('/student-interface');
      } else {
        throw new Error(data.message || '創建練習失敗');
      }
    } catch (error) {
      console.error('Error:', error);
      toast({
        variant: "destructive",
        title: "錯誤",
        description: error instanceof Error ? error.message : '創建練習失敗'
      });
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Header setIsAuthenticated={setIsAuthenticated} username={username} />
      <main className="flex-grow bg-gray-50">
        <div className="max-w-4xl mx-auto p-6">
          <Card>
            <CardHeader>
              <CardTitle>創建練習</CardTitle>
            </CardHeader>
            <CardContent>
              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="space-y-2">
                  <Label htmlFor="source-type">題目來源</Label>
                  <Select 
                    value={formData.sourceType} 
                    onValueChange={(value: 'past-exam' | 'custom') => 
                      setFormData(prev => ({ ...prev, sourceType: value }))
                    }
                  >
                    <SelectTrigger id="source-type">
                      <SelectValue placeholder="選擇題目來源" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="past-exam">歷年考題</SelectItem>
                      <SelectItem value="custom">自訂題目</SelectItem>
                    </SelectContent>
                  </Select>
                </div>

                {formData.sourceType === 'past-exam' && (
                  <div className="space-y-2">
                    <Label htmlFor="year">年份</Label>
                    <Select 
                      value={formData.year?.toString()} 
                      onValueChange={(value) => 
                        setFormData(prev => ({ ...prev, year: parseInt(value) }))
                      }
                    >
                      <SelectTrigger id="year">
                        <SelectValue placeholder="選擇年份" />
                      </SelectTrigger>
                      <SelectContent>
                        {availableYears.map(year => (
                          <SelectItem key={year} value={year.toString()}>
                            {year} 年
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                )}

                <div className="flex justify-end space-x-4">
                  <Button
                    type="button"
                    variant="outline"
                    onClick={() => navigate('/student-interface')}
                  >
                    取消
                  </Button>
                  <Button type="submit">
                    創建練習
                  </Button>
                </div>
              </form>
            </CardContent>
          </Card>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default NewTestCreation; 