import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../components/ui/button';
import { Card, CardContent } from '../components/ui/card';
import { Badge } from '../components/ui/badge';

const LandingPage = () => {
  const navigate = useNavigate();

  const handleLogin = (type: 'student_b' | 'student_c' |  'school' | 'admin') => {
    navigate(`/login/${type}`);
  };

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-blue-50 to-white">
      <header className="sticky top-0 z-50 w-full bg-white border-b shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-3">
              <img src="/logo112.svg" alt="KC Online Logo" className="h-7 w-auto" />
            </div>
            <div className="flex space-x-4">
              <Button 
                variant="ghost" 
                onClick={() => handleLogin('student_c')}
              >
                個人登入
              </Button>
              <Button 
                variant="outline"
                onClick={() => handleLogin('school')}
              >
                校園端登入
              </Button>
              <Button 
                variant="default"
                onClick={() => handleLogin('admin')}
              >
                管理者登入
              </Button>
            </div>
          </div>
        </div>
      </header>

      <section className="pt-20 pb-32 px-4">
        <div className="max-w-7xl mx-auto">
          <div className="grid lg:grid-cols-2 gap-12 items-center">
            <div className="space-y-8">
              <div className="space-y-4">
                <Badge variant="info" className="mb-4">全新升級</Badge>
                <h1 className="text-5xl font-bold bg-gradient-to-r from-blue-600 to-blue-800 bg-clip-text text-transparent">
                 建立個人語言模型
                  <br />
                  助益英文寫作
                </h1>
                <p className="text-xl text-gray-600">
                  透過觀察.分析.預測
                  <br />
                  達成有信效度的英文寫作
                </p>
              </div>
              <div className="flex gap-4">
                <Button 
                  onClick={() => handleLogin('student_c')}
                  className="text-lg px-8 h-12"
                >
                  開始使用
                </Button>
                <Button 
                  variant="outline"
                  onClick={() => handleLogin('school')}
                  className="text-lg px-8 h-12"
                >
                  學校合作
                </Button>
              </div>
            </div>
            <div className="lg:block hidden">
              <img 
                src="/write.jpeg"
                alt="/write.jpeg"
                className="w-full rounded-lg shadow-2xl"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">系統特色</h2>
          <div className="grid md:grid-cols-3 gap-8">
            <Card className="relative overflow-hidden border-t-4 border-t-blue-500">
              <CardContent className="pt-6">
                <div className="absolute top-2 right-2 opacity-10">
                  <div className="text-6xl font-bold text-blue-500">01</div>
                </div>
                <h3 className="text-xl font-semibold mb-4">AI 語言建模</h3>
                <p className="text-gray-600">建立模型是唯一對英文寫作有幫助的</p>
                <p className="text-gray-600">我們研究你想寫的，幫你建模並按部就班調整你</p>
              </CardContent>
            </Card>
            <Card className="relative overflow-hidden border-t-4 border-t-green-500">
              <CardContent className="pt-6">
                <div className="absolute top-2 right-2 opacity-10">
                  <div className="text-6xl font-bold text-green-500">02</div>
                </div>
                <h3 className="text-xl font-semibold mb-4">信度與效度兼具的評閱</h3>
                <p className="text-gray-600">詳細的評分解析和建議，幫助學生快速理解並改進寫作技巧。</p>
              </CardContent>
            </Card>
            <Card className="relative overflow-hidden border-t-4 border-t-purple-500">
              <CardContent className="pt-6">
                <div className="absolute top-2 right-2 opacity-10">
                  <div className="text-6xl font-bold text-purple-500">03</div>
                </div>
                <h3 className="text-xl font-semibold mb-4">預測與建立個人語料庫</h3>
                <p className="text-gray-600">完整的學習歷程記錄，清晰掌握進步軌跡和學習成效，並建立你個人專屬的語料庫</p>
              </CardContent>
            </Card>
          </div>
        </div>
      </section>

      <section className="py-20">
        <div className="max-w-7xl mx-auto px-4">
          <div className="grid lg:grid-cols-2 gap-12 items-center">
            <div className="lg:order-2">
              <h2 className="text-3xl font-bold mb-6">學校合作方案</h2>
              <div className="space-y-4 mb-8">
                <p className="text-lg text-gray-600">
                  提供完整的教學管理系統，協助老師更有效率地進行寫作教學。
                </p>
                <ul className="space-y-2">
                  <li className="flex items-center">
                    <div className="h-2 w-2 rounded-full bg-blue-500 mr-2" />
                    班級作業管理
                  </li>
                  <li className="flex items-center">
                    <div className="h-2 w-2 rounded-full bg-blue-500 mr-2" />
                    學生進度追蹤
                  </li>
                  <li className="flex items-center">
                    <div className="h-2 w-2 rounded-full bg-blue-500 mr-2" />
                    數據分析報告
                  </li>
                </ul>
              </div>
              <Button 
                onClick={() => handleLogin('school')}
                className="h-12"
              >
                了解更多
              </Button>
            </div>
            <div className="lg:order-1">
              <img 
                src="/School.jpg"
                className="w-full rounded-lg shadow-xl"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LandingPage;