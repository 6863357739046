// types/correction.ts

export interface BasicVocabulary {
  CEFR: string;
  kk_phonetic: string;
  part_of_speech: string;
  translation: string;
  word: string;
}

export interface VocabularyItem {
  Word: string;
  WordTranslation: string;
  KKPhonetic: string;
  PartofSpeech: string;
  CEFRLevel: string;
  Rating: string;
  Suggestion: string;
  SuggestionTranslation: string;
  SuggestionKKPhonetic: string;
  SuggestionCEFR: string;
}

export interface AdvancedPhrase {
  OriginalExpression: string;
  RecommendedPhrase: string;
  PhraseExample?: string;
  PhraseTranslation?: string;
}

export interface StyleTableItem {
  Probability: string;
  Style: string;
}

export interface CorrectionData {
  scores: {
    [key: string]: number;
  };
  errors: Array<{
    original: string;
    correction: string;
    type: string;
  }>;
  feedback: string;
  article: string;
  Basic_voc: BasicVocabulary[];

  article_FIX: {
    "Before Revision": string;
    "After Revision": string;
  };

  upgrade_essay_lang1?: {
    EnhancedEssay: string;
  };

  lang2aupgrade_essay_lang2?: {
    EnhancedEssayTransla: string;
  };

  ContentScoreAgen_lang1: {
    ContentScore: string;
    Review: string;
  };

  ContentScoreAgen_lang2: {
    ContentScore_trans: string;
    Review_translated: string;
  };

  GrammarStructureScoreAgent_lang1: {
    Review: string;
    Score: string;
  };

  GrammarStructureScoreAgent_lang2: {
    Review: string;
    Score: string;
  };

  OrganizationScoreAgent_lang1: {
    Review: string;
    Score: string;
  };

  OrganizationScoreAgent_lang2: {
    Review: string;
    Score: string;
  };

  VocabularySpellingScoreAgent_lang1: {
    Review: string;
    Score: string;
  };

  VocabularySpellingScoreAgent_lang2: {
    Review: string;
    Score: string;
  };

  Suggestion_lang1: {
    "Total Score": string;
    "Errors and Corrections": Array<{
      "Original Sentence": string;
      "Corrected Sentence": string;
      "Reason for Modification": string;
      type?: "grammar" | "spelling";
    }>;
    "Misspelled Words": string[];
    [key: string]: any;
  };

  Suggestion_lang2: {
    "Errors and Corrections (translated)": Array<{
      "Original Sentence": string;
      "Corrected Sentence": string;
      "Reason for Modification": string;
      type?: "grammar" | "spelling";
    }>;
    "Misspelled Words (translated)": string[];
    "Overall Evaluation (translated)": string;
    "Total Score (translated)": string;
  };

  StructureDivisionAgent_lang1: {
    Introduction: {
      Hook: string;
      "Background Information": string;
      "Thesis Statement": string;
      "Missing Elements"?: string[];
    };
    "Body Paragraph": {
      "Topic Sentence": string;
      "Supporting Details": string;
      "Explanation and Analysis": string;
      Transition: string;
      "Missing Elements"?: string[];
    };
    Conclusion: {
      "Restate the Thesis": string;
      Summary: string;
      "Final Thought": string;
      "Missing Elements"?: string[];
    };
    Summary?: string;
  };

  StructureDivisionAgent_lang2: {
    "Introduction (translated)": {
      Hook: string;
      "Background Information": string;
      "Thesis Statement": string;
      "Missing Elements"?: string[];
    };
    "Body Paragraph (translated)": {
      "Topic Sentence": string;
      "Supporting Details": string;
      "Explanation and Analysis": string;
      Transition: string;
      "Missing Elements"?: string[];
    };
    "Conclusion (translated)": {
      "Restate the Thesis": string;
      Summary: string;
      "Final Thought": string;
      "Missing Elements"?: string[];
    };
    "Summary (translated)"?: string;
  };

  article_style_lang1?: {
    "Recognized Styles": string;
    "Style Odds Table": StyleTableItem[];
  };

  article_style_lang2?: {
    "Recognized Styles": string;
    "Style Odds Table": StyleTableItem[];
  };

  Vocabulary_Upgrade_lang1?: {
    ImproveVocabulary: Array<VocabularyItem>;
    BetterVocabulary?: Array<VocabularyItem>;
    IncorrectVocabulary?: Array<VocabularyItem>;
    AdvancedPhrases: Array<AdvancedPhrase>;
  };

  article_MatchingPoint_lang1?: {
    "Essay Prompt Comparison": string;
    "Essay Theme Analysis": string;
    "Final Conclusion": string;
    "Judgment Result": string;
    "Match Percentage": string;
    "Recognized Styles": string;
  };

  article_MatchingPoint_lang2?: {
    "Essay Prompt Comparison (translated)": string;
    "Essay Theme Analysis (translated)": string;
    "Final Conclusion (translated)": string;
    "Judgment Result (translated)": string;
    "Match Percentage (translated)": string;
    "Recognized Styles (translated)": string;
  };

  article_cerf_lang1?: {
    CEFRLevel: string;
    VocabularyRange: string;
    SyntaxStructure: string;
    ContentTheme: string;
    IntentandExpression: string;
    Conclusion: string;
  };

  article_cerf_lang2?: {
    CEFRLevel: string;
    Conclusion: string;
    ContentTheme: string;
    IntentandExpression: string;
    SyntaxStructure: string;
    VocabularyRange: string;
  };

  article_Accuracy_lang1?: {
    "Language and Grammar": string;
    "Logical Flaws": string;
    "Factual Errors": string;
    "Narrative Clarity": string;
    Summary: string;
  };

  article_Accuracy_lang2?: {
    "Language and Grammar (translated)": string;
    "Logical Flaws (translated)": string;
    "Factual Errors (translated)": string;
    "Narrative Clarity (translated)": string;
    "Summary (translated)": string;
  };

  // 文法錯誤分析
  GrammarAccuracyAnalysis_lang1?: Array<{
    Error: string;
    Correction: string;
    Explanation: string;
  }>;

  GrammarAccuracyAnalysis_lang2?: Array<{
    Error: string;
    Correction: string;
    Explanation: string;
  }>;

  // 標點符號分析
  PunctuationAnalysis_lang1?: Array<{
    Analysis: string;
    Suggestion: string;
  }>;

  PunctuationAnalysis_lang2?: Array<{
    Analysis: string;
    Suggestion: string;
  }>;

  // 結構分析
  ParagraphStructure_lang1?: Array<{
    Content: string;
    Analysis: string;
  }>;
  
  CoherenceAnalysis_lang1?: string;
  
  // 翻譯相關
  translations?: Array<{
    content: string;
    result: {
      output: {
        Faithfulness: {
          Examples: Array<{ ExampleText: string; Translation: string }>;
          Feedback: string;
          FeedbackTranslation: string;
          Score: string;
        };
        Expressiveness: {
          Examples: Array<{ ExampleText: string; Translation: string }>;
          Feedback: string;
          FeedbackTranslation: string;
          Score: string;
        };
        Elegance: {
          Examples: Array<{ ExampleText: string; Translation: string }>;
          Feedback: string;
          FeedbackTranslation: string;
        };
        OverallEvaluation: {
          Explanation: string;
          ExplanTranslation: string;
          Rating: string;
          Suggestions: Array<{ Suggestions: string; SuggestTranslation: string }>;
        };
      };
    };
  }>;
}

export const getDefaultCorrectionData = (): CorrectionData => ({
  scores: {},
  errors: [],
  feedback: "",
  article: "",
  Basic_voc: [],
  article_FIX: {
    "Before Revision": "",
    "After Revision": "",
  },
  ContentScoreAgen_lang1: {
    ContentScore: "0",
    Review: "No review available",
  },
  ContentScoreAgen_lang2: {
    ContentScore_trans: "0",
    Review_translated: "尚無評論",
  },
  GrammarStructureScoreAgent_lang1: {
    Review: "No review available",
    Score: "0",
  },
  GrammarStructureScoreAgent_lang2: {
    Review: "尚無評論",
    Score: "0",
  },
  OrganizationScoreAgent_lang1: {
    Review: "No review available",
    Score: "0",
  },
  OrganizationScoreAgent_lang2: {
    Review: "尚無評論",
    Score: "0",
  },
  VocabularySpellingScoreAgent_lang1: {
    Review: "No review available",
    Score: "0",
  },
  VocabularySpellingScoreAgent_lang2: {
    Review: "尚無評論",
    Score: "0",
  },
  Suggestion_lang1: {
    "Total Score": "0",
    "Errors and Corrections": [],
    "Misspelled Words": [],
  },
  Suggestion_lang2: {
    "Errors and Corrections (translated)": [],
    "Misspelled Words (translated)": [],
    "Overall Evaluation (translated)": "尚無評估",
    "Total Score (translated)": "0",
  },
  StructureDivisionAgent_lang1: {
    Introduction: {
      Hook: "",
      "Background Information": "",
      "Thesis Statement": "",
    },
    "Body Paragraph": {
      "Topic Sentence": "",
      "Supporting Details": "",
      "Explanation and Analysis": "",
      Transition: "",
    },
    Conclusion: {
      "Restate the Thesis": "",
      Summary: "",
      "Final Thought": "",
    },
  },
  StructureDivisionAgent_lang2: {
    "Introduction (translated)": {
      Hook: "",
      "Background Information": "",
      "Thesis Statement": "",
    },
    "Body Paragraph (translated)": {
      "Topic Sentence": "",
      "Supporting Details": "",
      "Explanation and Analysis": "",
      Transition: "",
    },
    "Conclusion (translated)": {
      "Restate the Thesis": "",
      Summary: "",
      "Final Thought": "",
    },
  },
  upgrade_essay_lang1: {
    EnhancedEssay: "",
  },
  lang2aupgrade_essay_lang2: {
    EnhancedEssayTransla: "",
  },
});

export const mergeCorrectionData = (
  apiData: Partial<CorrectionData>
): CorrectionData => {
  const defaultData = getDefaultCorrectionData();
  return {
    ...defaultData,
    ...apiData,
    Basic_voc: apiData.Basic_voc || defaultData.Basic_voc,
    Suggestion_lang1: {
      ...defaultData.Suggestion_lang1,
      ...(apiData.Suggestion_lang1 || {}),
    },
    Suggestion_lang2: {
      ...defaultData.Suggestion_lang2,
      ...(apiData.Suggestion_lang2 || {}),
    },
  };
};

export const safeGet = <T>(obj: any, path: string, defaultValue: T): T => {
  try {
    return (
      path.split(".").reduce((acc, part) => acc && acc[part], obj) ||
      defaultValue
    );
  } catch (e) {
    return defaultValue;
  }
};

export interface translationResults {
  originalQuestion: string;
  questionId: string;
  results: Array<{
    originalQuestion: string;
    studentAnswer: string;
    result: {
      standardAnswer: string;
      studentAnswer: string;
      basic_voc: Array<{
        CEFR: string;
        kk_phonetic: string;
        part_of_speech: string;
        translation: string;
        word: string;
      }>;
      lang1: {
        CEFRLevel: string;
        Conclusion: string;
        ContentTheme: string;
        IntentandExpression: string;
        SyntaxStructure: string;
        VocabularyRange: string;
      };
      lang2: {
        CEFRLevel: string;
        Conclusion: string;
        ContentTheme: string;
        IntentandExpression: string;
        SyntaxStructure: string;
        VocabularyRange: string;
      };
      output: {
        Elegance: {
          Examples: Array<{
            ExampleText: string;
            Translation: string;
          }>;
          Feedback: string;
          FeedbackTranslation: string;
        };
        Expressiveness: {
          Examples: Array<{
            ExampleText: string;
            Translation: string;
          }>;
          Feedback: string;
          FeedbackTranslation: string;
          Score: string;
        };
        Faithfulness: {
          Examples: Array<{
            ExampleText: string;
            Translation: string;
          }>;
          Feedback: string;
          FeedbackTranslation: string;
          Score: string;
        };
        OverallEvaluation: {
          ExplanTranslation: string;
          Explanation: string;
          Rating: string;
          Suggestions: Array<{
            SuggestTranslation: string;
            Suggestions: string;
          }>;
        };
      };
    };
  }>;
}

export interface WritingResultData {
  content: string;
  result: CorrectionData;
}

export interface CombinedSubmissionData {
  id: string;
  title: string;
  createdAt: string;
  type: "translation-writing" | "combined";
  lang: string;
  studentName: string;
  className: string;
  teacherName: string;
  result: {
    writing: WritingResult | null;
    translation: translationResults | null;
  };
}

export interface WritingResult {
  content: string;
  result: CorrectionData;
  contentImages?: Array<{
    data: string;
    contentType: string;
    ocrText: string;
  }>;
  descriptionImages?: Array<{
    data: string;
    contentType: string;
    ocrText: string;
  }>;
}

// 報告生成器的 Props 類型
export interface ReportGeneratorProps {
  data: {
    content: string;
    result: CorrectionData;
    title?: string;
    submissionDate?: string;
    studentName?: string;
    className?: string;
    teacherName?: string;
    contentImages?: Array<{
      data: string;
      contentType: string;
      ocrText: string;
    }>;
    descriptionImages?: Array<{
      data: string;
      contentType: string;
      ocrText: string;
    }>;
    translationResults?: CorrectionData['translations'];
  };
}
