// src/components/admin/QuotaManagement.tsx

import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from '../ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../ui/table';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Label } from '../ui/label';
import { Alert, AlertDescription } from '../ui/alert';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '../ui/dialog';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select';
import { Search } from 'lucide-react';

interface User {
  _id: string;
  username: string;
  realName: string;
  school: string;
  className: string;
  remainingEssays: number;
  remainingTranslations: number;
  remainingReadings: number;
}

interface QuotaHistory {
  amount: number;
  quotaType: 'essay' | 'translation' | 'reading';
  reason: string;
  adjustedAt: string;
  adjustedBy: string;
}

const QuotaManagement: React.FC = () => {
  // State
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [quotaAmount, setQuotaAmount] = useState<number>(0);
  const [quotaType, setQuotaType] = useState<'essay' | 'translation' | 'reading'>('essay');
  const [reason, setReason] = useState<string>('');
  const [showQuotaDialog, setShowQuotaDialog] = useState(false);
  const [showHistoryDialog, setShowHistoryDialog] = useState(false);
  const [quotaHistory, setQuotaHistory] = useState<QuotaHistory[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  // Effects
  useEffect(() => {
    fetchUsers();
  }, []);

  // API Calls
  const fetchUsers = async () => {
    try {
      setIsLoading(true);
      const response = await fetch('/api/admin/users', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch users');
      }

      const data = await response.json();
      if (data.success) {
        setUsers(data.users);
      } else {
        throw new Error(data.message || '獲取用戶列表失敗');
      }
    } catch (error) {
      setError(error instanceof Error ? error.message : '獲取用戶列表失敗');
      console.error('Fetch users error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchQuotaHistory = async (userId: string) => {
    try {
      setIsLoading(true);
      const response = await fetch(`/api/admin/${userId}/history`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch quota history');
      }

      const data = await response.json();
      if (data.success) {
        setQuotaHistory(data.data.history);
        setShowHistoryDialog(true);
      } else {
        throw new Error(data.message || '獲取額度歷史失敗');
      }
    } catch (error) {
      setError(error instanceof Error ? error.message : '獲取額度歷史失敗');
      console.error('Fetch quota history error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateQuota = async () => {
    if (!selectedUser || !quotaAmount) return;
  
    try {
      setIsLoading(true);
      const updateData: {
        userId: string;
        reason?: string;
        essayAmount?: number;
        translationAmount?: number;
        readingAmount?: number;
      } = {
        userId: selectedUser._id,
        reason: reason || '管理員調整'
      };
  
      // 根據選擇的類型設置相應的額度字段
      switch (quotaType) {
        case 'essay':
          updateData.essayAmount = quotaAmount;
          break;
        case 'translation':
          updateData.translationAmount = quotaAmount;
          break;
        case 'reading':
          updateData.readingAmount = quotaAmount;
          break;
      }
  
      const response = await fetch('/api/quota/update-quota', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          updates: [updateData]
        }),
      });
  
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.message || '更新額度失敗');
      }
  
      if (data.success) {
        await fetchUsers();
        setShowQuotaDialog(false);
        setQuotaAmount(0);
        setReason('');
        setQuotaType('essay');
      } else {
        throw new Error(data.message || '更新額度失敗');
      }
    } catch (error) {
      setError(error instanceof Error ? error.message : '更新額度失敗');
      console.error('Update quota error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Handlers
  const handleOpenQuotaDialog = (user: User) => {
    setSelectedUser(user);
    setQuotaAmount(0);
    setReason('');
    setQuotaType('essay');
    setShowQuotaDialog(true);
  };

  const handleCloseQuotaDialog = () => {
    setShowQuotaDialog(false);
    setSelectedUser(null);
    setQuotaAmount(0);
    setReason('');
    setQuotaType('essay');
  };

  const handleOpenHistoryDialog = async (user: User) => {
    setSelectedUser(user);
    await fetchQuotaHistory(user._id);
  };

  const handleCloseHistoryDialog = () => {
    setShowHistoryDialog(false);
    setSelectedUser(null);
    setQuotaHistory([]);
  };

  // Filter users
  const filteredUsers = users.filter(user =>
    user.realName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.school.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.className.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.username.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const getQuotaTypeText = (type: 'essay' | 'translation' | 'reading') => {
    switch (type) {
      case 'essay':
        return '作文額度';
      case 'translation':
        return '翻譯額度';
      case 'reading':
        return '閱讀額度';
      default:
        return '未知類型';
    }
  };

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader>
          <div className="flex justify-between items-center">
            <div>
              <CardTitle>額度管理</CardTitle>
              <CardDescription>管理學生的各項功能額度</CardDescription>
            </div>
            <div className="flex items-center space-x-2">
              <div className="relative">
                <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-500" />
                <Input
                  placeholder="搜索學生..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="pl-8 w-[300px]"
                />
              </div>
            </div>
          </div>
        </CardHeader>
        <CardContent>
          {error && (
            <Alert variant="destructive" className="mb-4">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>學生姓名</TableHead>
                <TableHead>帳號</TableHead>
                <TableHead>學校</TableHead>
                <TableHead>班級</TableHead>
                <TableHead>作文額度</TableHead>
                <TableHead>翻譯額度</TableHead>
                <TableHead>閱讀額度</TableHead>
                <TableHead className="text-right">操作</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={8} className="text-center py-8">
                    <div className="flex justify-center">
                      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
                    </div>
                  </TableCell>
                </TableRow>
              ) : filteredUsers.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={8} className="text-center py-8">
                    沒有找到符合條件的學生
                  </TableCell>
                </TableRow>
              ) : (
                filteredUsers.map((user) => (
                  <TableRow key={user._id}>
                    <TableCell className="font-medium">{user.realName}</TableCell>
                    <TableCell>{user.username}</TableCell>
                    <TableCell>{user.school}</TableCell>
                    <TableCell>{user.className}</TableCell>
                    <TableCell>{user.remainingEssays}</TableCell>
                    <TableCell>{user.remainingTranslations}</TableCell>
                    <TableCell>{user.remainingReadings}</TableCell>
                    <TableCell className="text-right">
                      <div className="space-x-2">
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => handleOpenQuotaDialog(user)}
                          disabled={isLoading}
                        >
                          調整額度
                        </Button>
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => handleOpenHistoryDialog(user)}
                          disabled={isLoading}
                        >
                          查看歷史
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </CardContent>
      </Card>

      {/* 調整額度對話框 */}
      <Dialog open={showQuotaDialog} onOpenChange={handleCloseQuotaDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>調整額度 - {selectedUser?.realName}</DialogTitle>
          </DialogHeader>
          <div className="space-y-4 py-4">
            <div className="space-y-2">
              <Label>額度類型</Label>
              <Select
                value={quotaType}
                onValueChange={(value: 'essay' | 'translation' | 'reading') => setQuotaType(value)}
              >
                <SelectTrigger>
                  <SelectValue placeholder="選擇額度類型" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="essay">作文額度</SelectItem>
                  <SelectItem value="translation">翻譯額度</SelectItem>
                  <SelectItem value="reading">閱讀額度</SelectItem>
                </SelectContent>
              </Select>
            </div>

            <div className="space-y-2">
              <Label htmlFor="quotaAmount">調整數量</Label>
              <Input
                id="quotaAmount"
                type="number"
                value={quotaAmount}
                onChange={(e) => setQuotaAmount(parseInt(e.target.value) || 0)}
                placeholder="輸入正數增加額度，負數減少額度"
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="reason">調整原因</Label>
              <Input
                id="reason"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                placeholder="請輸入調整原因"
              />
            </div>
          </div>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={handleCloseQuotaDialog}
              disabled={isLoading}
            >
              取消
            </Button>
            <Button
              onClick={handleUpdateQuota}
              disabled={isLoading || !quotaAmount}
            >
              {isLoading ? '處理中...' : '確認調整'}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* 額度歷史對話框 */}
      <Dialog open={showHistoryDialog} onOpenChange={handleCloseHistoryDialog}>
        <DialogContent className="max-w-3xl">
          <DialogHeader>
            <DialogTitle>額度歷史 - {selectedUser?.realName}</DialogTitle>
          </DialogHeader>
          <div className="mt-4">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>額度類型</TableHead>
                  <TableHead>調整數量</TableHead>
                  <TableHead>調整原因</TableHead>
                  <TableHead>調整時間</TableHead>
                  <TableHead>操作人</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {quotaHistory.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={5} className="text-center py-4">
                      暫無歷史記錄
                    </TableCell>
                  </TableRow>
                ) : (
                  quotaHistory.map((record, index) => (
                    <TableRow key={index}>
                      <TableCell>{getQuotaTypeText(record.quotaType)}</TableCell>
                      <TableCell className={record.amount > 0 ? 'text-green-600' : 'text-red-600'}>
                        {record.amount > 0 ? `+${record.amount}` : record.amount}
                      </TableCell>
                      <TableCell>{record.reason}</TableCell>
                      <TableCell>{new Date(record.adjustedAt).toLocaleString()}</TableCell>
                      <TableCell>{record.adjustedBy}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default QuotaManagement;