import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../ui/tabs';
import Header from '../../components/Header';
import TranslationResultTabs from './tabs/TranslationResultTabs';
import WritingResultTabs from './tabs/WritingResultTabs';
import ErrorDisplay from '../common/ErrorDisplay';
import LoadingSpinner from '../common/LoadingSpinner';
import { CombinedSubmissionData, WritingResult } from '../types/correction';
import { generateReport } from './ReportGenerator';
import { Button } from '../ui/button';
import { Download } from 'lucide-react';
import { message } from 'antd';

interface CorrectionResultProps {
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  username: string;
}

interface ApiResponse {
  success: boolean;
  submission: {
    id: string;
    title: string;
    createdAt: string;
    type: "translation-writing" | "combined";
    lang: string;
    studentName: string;
    className: string;
    teacherName: string;
    result: {
      writing: {
        content: string;
        result: any;
      } | null;
      translation: any | null;
    };
  };
}

interface TranslationResult {
  content: string;
  result: {
    output: {
      Elegance: {
        Examples: Array<{ ExampleText: string; Translation: string }>;
        Feedback: string;
        FeedbackTranslation: string;
      };
      Expressiveness: {
        Examples: Array<{ ExampleText: string; Translation: string }>;
        Feedback: string;
        FeedbackTranslation: string;
        Score: string;
      };
      Faithfulness: {
        Examples: Array<{ ExampleText: string; Translation: string }>;
        Feedback: string;
        FeedbackTranslation: string;
        Score: string;
      };
      OverallEvaluation: {
        ExplanTranslation: string;
        Explanation: string;
        Rating: string;
        Suggestions: Array<{ SuggestTranslation: string; Suggestions: string }>;
      };
    };
  };
}

const CorrectionResult: React.FC<CorrectionResultProps> = ({
  setIsAuthenticated,
  username
}) => {
  console.log('CorrectionResult data:', { setIsAuthenticated, username });
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [submissionData, setSubmissionData] = useState<CombinedSubmissionData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [generatingReport, setGeneratingReport] = useState(false);

  useEffect(() => {
    const loadCorrectionData = async () => {
      if (!id) return;

      const token = localStorage.getItem('token');
      const userRole = localStorage.getItem('userRole');
      
      if (!token) {
        setIsAuthenticated(false);
        navigate('/', { replace: true });
        return;
      }

      try {
        setIsLoading(true);
        // 根據用戶角色使用不同的 API endpoint
        const endpoint = userRole === 'teacher' 
          ? `/api/teacher/submission/${id}`  // 教師專用的 API
          : `/api/essay/submission/${id}`;   // 學生使用的原有 API
        
        const response = await fetch(endpoint, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (response.status === 401) {
          setIsAuthenticated(false);
          navigate('/', { replace: true });
          return;
        }

        if (!response.ok) {
          throw new Error('Failed to fetch submission data');
        }

        const responseData: ApiResponse = await response.json();
        console.log('API Response:', responseData);

        if (responseData.success && responseData.submission) {
          const submissionItem = responseData.submission;
          console.log('Submission Item:', submissionItem);
          
          // 檢查所需的關鍵字段
          if (!submissionItem.id || !submissionItem.type || !submissionItem.result) {
            console.error('Missing required fields:', {
              hasId: !!submissionItem.id,
              hasType: !!submissionItem.type,
              hasResult: !!submissionItem.result
            });
            throw new Error('數據缺少必要字段');
          }

          const processedData: CombinedSubmissionData = {
            id: submissionItem.id,
            title: submissionItem.title || '',
            createdAt: submissionItem.createdAt || '',
            type: submissionItem.type,
            lang: submissionItem.lang || '',
            studentName: submissionItem.studentName || '未提供',
            className: submissionItem.className || '未提供',
            teacherName: submissionItem.teacherName || '未提供',
            result: {
              writing: submissionItem.result.writing || null,
              translation: submissionItem.result.translation || null
            }
          };

          console.log('Processed Data:', processedData);
          setSubmissionData(processedData);
          
        } else {
          console.error('Invalid response format:', responseData);
          throw new Error('無效的數據格式');
        }
      } catch (err) {
        console.error('Error in loadCorrectionData:', err);
        setError(err instanceof Error ? err.message : '加載批改結果失敗');
      } finally {
        setIsLoading(false);
      }
    };

    loadCorrectionData();
  }, [id, setIsAuthenticated, navigate]);

  const handleDownloadReport = async () => {
    if (!submissionData?.result.writing?.result) {
      console.error('無批改結果');
      return;
    }

    const writingData = submissionData.result.writing;

    try {
      setGeneratingReport(true);
      
      // 處理翻譯結果
      const translationResults = submissionData.result.translation ? 
        (Array.isArray(submissionData.result.translation) ? 
          submissionData.result.translation : [submissionData.result.translation])
          .map(trans => ({
            content: trans.content || trans.question || '',
            answer: trans.answer || trans.english || '',
            vocabularySuggestions: trans.vocabularySuggestions || [],
            vocabularyNotes: trans.vocabularyNotes || [],
            result: trans.result || {}
          })) : undefined;

      await generateReport({
        content: writingData.content,
        result: writingData.result,
        title: submissionData.title,
        submissionDate: submissionData.createdAt,
        contentImages: writingData.contentImages || [],
        descriptionImages: writingData.descriptionImages || [],
        studentName: submissionData.studentName,
        className: submissionData.className,
        teacherName: submissionData.teacherName,
        translationResults
      });
      message.success('報告生成成功');
    } catch (error) {
      console.error('生成報告失敗:', error);
      message.error('生成報告失敗');
    } finally {
      setGeneratingReport(false);
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-50">
        <Header setIsAuthenticated={setIsAuthenticated} username={username} />
        <div className="container mx-auto p-4">
          <LoadingSpinner />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50">
        <Header setIsAuthenticated={setIsAuthenticated} username={username} />
        <div className="container mx-auto p-4">
          <ErrorDisplay message={error} />
          <div className="mt-4 text-sm text-gray-600">
            請刷新頁面重試，如果問題持續存在，請聯繫系統管理員。
          </div>
        </div>
      </div>
    );
  }

  if (!submissionData) {
    return (
      <div className="min-h-screen bg-gray-50">
        <Header setIsAuthenticated={setIsAuthenticated} username={username} />
        <div className="container mx-auto p-4">
          <ErrorDisplay message="無法加載批改結果" />
        </div>
      </div>
    );
  }

  // 根據 submissionData 中的內容決定要顯示哪些標籤頁
  const hasWriting = !!submissionData.result.writing;
  const hasTranslation = !!submissionData.result.translation;
  const initialTab = hasTranslation ? "translation" : "writing";
  
  return (
    <div className="min-h-screen bg-gray-50">
      <Header setIsAuthenticated={setIsAuthenticated} username={username} />
      <div className="container mx-auto p-4 space-y-6">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-bold">{submissionData.title}</h2>
          <Button 
            onClick={handleDownloadReport}
            disabled={generatingReport}
            className="mb-4"
          >
            <Download className="mr-2 h-4 w-4" />
            {generatingReport ? '生成報告中...' : '下載報告'}
          </Button>
        </div>
        {hasWriting || hasTranslation ? (
          <Tabs defaultValue={initialTab} className="space-y-6">
            <TabsList className="bg-white p-1">
              {hasTranslation && <TabsTrigger value="translation">翻譯批改</TabsTrigger>}
              {hasWriting && <TabsTrigger value="writing">作文批改</TabsTrigger>}
            </TabsList>

            {hasTranslation && (
              <TabsContent value="translation">
                <TranslationResultTabs data={submissionData.result.translation!} />
              </TabsContent>
            )}

            {hasWriting && (
              <TabsContent value="writing">
                <WritingResultTabs data={submissionData.result.writing!} />
              </TabsContent>
            )}
          </Tabs>
        ) : (
          <div className="text-center text-gray-500">
            無可用的批改結果
          </div>
        )}
      </div>
    </div>
  );
};

export default CorrectionResult;