// src/components/correction/tabs/FeedbackTab.tsx

import React from 'react';
import { Card, CardContent } from '../../ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../ui/tabs';
import { ArrowRight } from 'lucide-react';
import { CorrectionData } from '../../types/correction';

interface FeedbackTabProps {
  data: CorrectionData;
}

const FeedbackTab: React.FC<FeedbackTabProps> = ({ data }) => {
  // 檢查錯誤是否為拼寫錯誤
  const isSpellingError = (error: string) => {
    return data.Suggestion_lang1['Misspelled Words'].includes(
      error.split(' ')[0] // 簡單檢查第一個單詞
    );
  };

  // 將錯誤按類型分類
  const grammarErrors = data.Suggestion_lang1['Errors and Corrections'].filter(
    error => !isSpellingError(error['Original Sentence'])
  );
  
  const spellingErrors = data.Suggestion_lang1['Errors and Corrections'].filter(
    error => isSpellingError(error['Original Sentence'])
  );

  // 渲染單個錯誤
  const renderError = (error: {
    'Original Sentence': string;
    'Corrected Sentence': string;
    'Reason for Modification': string;
  }, translatedErrors: typeof data.Suggestion_lang2['Errors and Corrections (translated)']) => {
    const translatedError = translatedErrors.find(
      e => e['Original Sentence'] === error['Original Sentence']
    );

    return (
      <div className="border-b border-gray-200 py-4 last:border-0">
        <div className="flex flex-col gap-2">
          <div className="flex items-start gap-2">
            <span className="text-red-500">{error['Original Sentence']}</span>
            <ArrowRight className="h-4 w-4 mt-1 text-gray-400 flex-shrink-0" />
            <span className="text-green-500">{error['Corrected Sentence']}</span>
          </div>
          <div className="space-y-2">
            <p className="text-sm">{error['Reason for Modification']}</p>
            {translatedError && (
              <p className="text-gray-600 text-sm">
                {translatedError['Reason for Modification']}
              </p>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Card>
      <CardContent className="p-6">
        <Tabs defaultValue="grammar" className="w-full">
          <TabsList>
            <TabsTrigger value="grammar">文法錯誤</TabsTrigger>
            <TabsTrigger value="spelling">拼寫錯誤</TabsTrigger>
          </TabsList>
          
          <TabsContent value="grammar" className="space-y-4">
            {grammarErrors.length > 0 ? (
              grammarErrors.map((error, index) => (
                <div key={index}>
                  {renderError(error, data.Suggestion_lang2['Errors and Corrections (translated)'])}
                </div>
              ))
            ) : (
              <div className="text-center py-8 text-gray-500">
                沒有發現文法錯誤
              </div>
            )}
          </TabsContent>
          
          <TabsContent value="spelling" className="space-y-4">
            {spellingErrors.length > 0 ? (
              spellingErrors.map((error, index) => (
                <div key={index}>
                  {renderError(error, data.Suggestion_lang2['Errors and Corrections (translated)'])}
                </div>
              ))
            ) : (
              <div className="text-center py-8 text-gray-500">
                沒有發現拼寫錯誤
              </div>
            )}
          </TabsContent>
        </Tabs>
      </CardContent>
    </Card>
  );
};

export default FeedbackTab;