import React, { useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Card, CardContent } from '../components/ui/card';
import { Input } from '../components/ui/input';
import { Button } from '../components/ui/button';
import { Label } from '../components/ui/label';
import { Alert, AlertDescription } from '../components/ui/alert';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../components/ui/tabs';
import LoginHeader from '../components/LoginHeader';

interface LoginProps {
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  setUsername: React.Dispatch<React.SetStateAction<string>>;
}

const Login: React.FC<LoginProps> = ({ setIsAuthenticated, setUsername }) => {
  const { type } = useParams<{ type: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [loginData, setLoginData] = useState({ username: '', password: '' });
  const [registerData, setRegisterData] = useState({
    username: '',
    password: '',
    confirmPassword: '',
    realName: '',
    school: '',
    className: '',
  });

  const getLoginTitle = () => {
    switch (type) {
      case 'student_c':
        return '個人登入';
      case 'school':
        return '校園端登入';
      case 'admin':
        return '管理者登入';
      default:
        return '登入';
    }
  };

  const getUserType = () => {
    return type || 'student_c';
  };

  const redirectBasedOnRole = (role: string) => {
    switch (role) {
      case 'admin':
        navigate('/admin-dashboard', { replace: true });
        break;
      case 'teacher':
        navigate('/teacher-dashboard', { replace: true });
        break;
      case 'sales':
        navigate('/sales-dashboard', { replace: true });
        break;
      case 'student_c':
          navigate('/essay-submission', { replace: true });
          break;
      case 'student_b':
        navigate('/student-dashboard', { replace: true });
        break;
      default:
        navigate('/', { replace: true });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: loginData.username, password: loginData.password }),
      });

      const data = await response.json();

      if (data.success) {
        localStorage.setItem('token', data.token);
        localStorage.setItem('username', data.username);
        localStorage.setItem('userRole', data.role);
        
        setIsAuthenticated(true);
        setUsername(data.username);

        navigate(data.homePath || '/', { replace: true });
      } else {
        setError(data.message || '登入失敗');
      }
    } catch (error) {
      console.error('Login error:', error);
      setError('登入失敗，請稍後再試');
    }
  };

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();
    if (registerData.password !== registerData.confirmPassword) {
      setError('密碼確認不匹配');
      return;
    }
    
    setIsLoading(true);
    setError('');

    try {
      const response = await fetch('/api/auth/register', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...registerData, userType: getUserType() }),
      });
      
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || '註冊失敗');
      }
      
      localStorage.setItem('token', data.token);
      localStorage.setItem('username', data.username);
      localStorage.setItem('userRole', data.role);
      setIsAuthenticated(true);
      setUsername(data.username);
      navigate('/essay-submission', { replace: true });
    } catch (err) {
      setError(err instanceof Error ? err.message : '註冊失敗');
    } finally {
      setIsLoading(false);
    }
  };

  const renderLoginForm = () => (
    <form onSubmit={handleSubmit} className="space-y-4 pt-4">
      {error && <Alert variant="destructive"><AlertDescription>{error}</AlertDescription></Alert>}
      <div className="space-y-2">
        <Label htmlFor="login-username">帳號</Label>
        <Input
          id="login-username"
          value={loginData.username}
          onChange={(e) => setLoginData(prev => ({ ...prev, username: e.target.value }))}
          placeholder="請輸入帳號"
          required
        />
      </div>
      <div className="space-y-2">
        <Label htmlFor="login-password">密碼</Label>
        <Input
          id="login-password"
          type="password"
          value={loginData.password}
          onChange={(e) => setLoginData(prev => ({ ...prev, password: e.target.value }))}
          placeholder="請輸入密碼"
          required
        />
      </div>
      <Button
        type="submit"
        className="w-full bg-blue-600 hover:bg-blue-700 text-white"
        disabled={isLoading}
      >
        {isLoading ? '登入中...' : '登入'}
      </Button>
      {type === 'school' && (
        <div className="text-center">
          <p className="text-sm text-gray-600">
            如需開通校園端帳號，請聯繫系統管理員
          </p>
        </div>
      )}
      {type === 'admin' && (
        <div className="text-center">
          <p className="text-sm text-gray-600">
            如需開通管理者帳號，請聯繫系統負責人
          </p>
        </div>
      )}
    </form>
  );

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <LoginHeader />
      <div className="flex-1 flex flex-col items-center justify-center p-4">
        <div className="w-full max-w-md">
          <Card>
            <div className="flex justify-center mb-6">
              <img src="/logo112.svg" alt="Logo" className="h-12 w-auto mt-6" />
            </div>
            <CardContent className="pt-6">
              <h2 className="text-2xl font-bold text-center mb-6">
                {getLoginTitle()}
              </h2>
              
              {type === 'student_c' ? (
                <Tabs defaultValue="login" className="w-full">
                  <TabsList className="grid w-full grid-cols-2">
                    <TabsTrigger value="login">登入</TabsTrigger>
                    <TabsTrigger value="register">註冊</TabsTrigger>
                  </TabsList>
                  
                  <TabsContent value="login">
                    {renderLoginForm()}
                  </TabsContent>
                  
                  <TabsContent value="register">
                    <form onSubmit={handleRegister} className="space-y-4 pt-4">
                      {error && <Alert variant="destructive"><AlertDescription>{error}</AlertDescription></Alert>}
                      <div className="space-y-2">
                        <Label htmlFor="register-username">帳號</Label>
                        <Input
                          id="register-username"
                          value={registerData.username}
                          onChange={(e) => setRegisterData(prev => ({ ...prev, username: e.target.value }))}
                          placeholder="請輸入帳號"
                          required
                        />
                      </div>
                      <div className="space-y-2">
                        <Label htmlFor="register-password">密碼</Label>
                        <Input
                          id="register-password"
                          type="password"
                          value={registerData.password}
                          onChange={(e) => setRegisterData(prev => ({ ...prev, password: e.target.value }))}
                          placeholder="請輸入密碼"
                          required
                        />
                      </div>
                      <div className="space-y-2">
                        <Label htmlFor="register-confirm-password">確認密碼</Label>
                        <Input
                          id="register-confirm-password"
                          type="password"
                          value={registerData.confirmPassword}
                          onChange={(e) => setRegisterData(prev => ({ ...prev, confirmPassword: e.target.value }))}
                          placeholder="請再次輸入密碼"
                          required
                        />
                      </div>
                      <div className="space-y-2">
                        <Label htmlFor="register-real-name">真實姓名</Label>
                        <Input
                          id="register-real-name"
                          value={registerData.realName}
                          onChange={(e) => setRegisterData(prev => ({ ...prev, realName: e.target.value }))}
                          placeholder="請輸入真實姓名"
                          required
                        />
                      </div>
                      <div className="space-y-2">
                        <Label htmlFor="register-school">學校</Label>
                        <Input
                          id="register-school"
                          value={registerData.school}
                          onChange={(e) => setRegisterData(prev => ({ ...prev, school: e.target.value }))}
                          placeholder="請輸入學校名稱"
                          required
                        />
                      </div>
                      <div className="space-y-2">
                        <Label htmlFor="register-class">班級</Label>
                        <Input
                          id="register-class"
                          value={registerData.className}
                          onChange={(e) => setRegisterData(prev => ({ ...prev, className: e.target.value }))}
                          placeholder="請輸入班級"
                          required
                        />
                      </div>
                      <Button
                        type="submit"
                        className="w-full bg-blue-600 hover:bg-blue-700 text-white"
                        disabled={isLoading}
                      >
                        {isLoading ? '註冊中...' : '註冊'}
                      </Button>
                    </form>
                  </TabsContent>
                </Tabs>
              ) : (
                renderLoginForm()
              )}
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Login;